import _axios from '../utils/https'

/**
 * 分页查询在线好友
 * @param {*} data 
 * @returns 
 */
export const getOnlineFriend = (data) => {
    return _axios({
        url: `/ml/im/friend/page`,
        method: "post",
        data
    })
}

/**
 * 分页查询[好友信息]列表
 * @param {*} data 
 * @returns 
 */
export const getMyfriends = (data) => {
    return _axios({
        url: `/ml/im/friend/page`,
        method: "post",
        data
    })
}

/**
 * 添加好友
 * @param {*} data 
 * @returns 
 */
export const addFriend = (data) => {
    return _axios({
        url: `/ml/im/friend/apply/save`,
        method: "post",
        data
    })
}

/**
 * 获取好友申请分页
 * @param {*} data 
 * @returns 
 */
export const getFriendPage = (data) => {
    return _axios({
        method: "post",
        url: `/ml/im/friend/apply/page`,
        data
    })
}



/**
 * 获取好友生申请详情
 * @param {*} id 
 * @returns 
 */
export const getFriendApplyInfo = (id) => {
    return _axios({
        url: `/ml/im/friend/apply/info/${id}`
    })
}



/**
 * 修改好友申请状态
 * @param {*} data 
 * @returns 
 */
export const changeApplyStatus = (data) => {
    return _axios({
        url: `/ml/im/friend/apply/update`,
        method: "post",
        data
    })
}

/**
 * 删除好友
 * @param {*} data 
 * @returns 
 */
export const delFriend = (data) => {
    return _axios({
        url: `/ml/im/friend/delete`,
        method: "post",
        data
    })
}

/**
 * 分页查询在线用户
 * @param {*} data 
 * @returns 
 */
export const getOnlineList = (data) => {
    return _axios({
        url: `/ml/im/online/user/page`,
        method: "post",
        data
    })
}



