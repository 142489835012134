import Vue from 'vue'
import ElementUI from 'element-ui'

/**
 * 验证手机号码
 * @param {*} mobile 
 * @returns 
 */
export const isMobile = (mobile) => {
  return /^1[3-9]\d{9}$/.test(mobile)
}

/**
 * 验证是否全部中文
 * @param {*} nickname 
 * @returns 
 */
export const All_Chinese = (nickname) => {
  return /^[\u4e00-\u9fa5]+$/i.test(nickname)
}

/**
 * 验证2-6个中文
 * @param {*} nickname 
 * @returns 
 */
export const Chinese_Rules = (nickname) => {
  return /^[\u4e00-\u9fa5]{2,7}$/.test(nickname)
}

/**
 * 中文、英文、数字但不包括下划线等符号
 * @param {*} nickname 
 * @returns 
 */
export const Group_Rules = (nickname) => {
  return /^[\u4E00-\u9FA5A-Za-z0-9]{4,12}$/.test(nickname)
}

/**
 * 密码正则验证
 */
export const passwdReg = new RegExp(/^[0-9]{4,6}$/)

/**
 * 字符串转对象
 * @param {*} data 
 * @returns 
 */
export const strToObj = (str) => {
  let obj = {}
  let array = str.split('&')
  array.forEach(v => {
    let item = v.split('-')
    console.log(item)
    obj[item[0]] = {}
    obj[item[0]] = {
      need: item[1] === 'true' ? true : false,
      require: item[2] === 'true' ? true : false
    }
  });
  return JSON.stringify(obj)
}

/**
 * 对象转字符串
 * @param {*} data 
 * @returns 
 */
export const objToStr = (data) => {
  let str = ''
  for (let k in data) {
    str += k + '-' + data[k].need + '-' + data[k].require + '&'
  }
  str = str.substring(0, str.length - 1)
  return str
}

/**
 * 调用unity方法
 * @param {*} methodsName 
 * @param {*} data 
 * @returns 
 */
export const callUnity = (methodsName, data) => {
  return Vue.prototype.$App.$refs['IFRAME'].contentWindow.sendMsgToUnity({ methodsName, data })
}

/**
 * utf8数组转字符串
 * @param {*} array 
 * @returns 
 */
export const Utf8ArrayToStr = (array) => {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }
  return out;
}

/* 获取媒体状态 */
export const getMediaState = () => {
  return new Promise((resolve, reject) => {
    if (
      navigator &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((mediastream) => {
          let audioTrack = mediastream.getAudioTracks()[0];
          if (audioTrack.enabled) {
            resolve()
          } else {
            reject()
          }
        })
        .catch(() => {
          ElementUI.Message.error("请优先开启浏览器麦克风权限");
          reject()
        });
    } else {
      ElementUI.Message.error(
        "浏览器不支持获取设备权限，有可能是当前页面不是https，也可能是当前浏览器不支持获取设备权限"
      );
      reject()
    }
  })
}
