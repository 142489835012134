import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from "echarts";

import './registerServiceWorker'
import "normalize.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { size: 'small' });
import md5 from 'js-md5'
Vue.prototype.$md5 = md5
import customAxios from '@/utils/https'
Vue.prototype.$axios = customAxios
Vue.prototype.$echarts = echarts;

import '@/components/index.js'
import '@/filters/index.js'
Vue.config.productionTip = false

import _ from 'lodash'
Vue.prototype.$_ = _

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$App = vm.$children[0]


