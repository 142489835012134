<script>
export default {
  name: "NoSupport",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
<template>
  <div class="support" v-if="value">
    <img src="../assets/images/home/dialog-close.png" @click="$emit('input', false)" alt="" class="off" />
    <img style="border-radius: 4px;" src="@/assets/images/meet/support-audio.png" alt="" />
  </div>
</template>


<style lang="scss" scoped>
.support {
  position: relative;

  .off {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

