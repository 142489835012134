<template>
  <div class="login">
    <div class="form" v-if="!IS_FINISH && !Set_Password && !Select_Sex && !Select_Person">
      <div class="left">
        <img src="@/assets/images/login/login-form-left.png" alt="" />
      </div>
      <div class="right">
        <div class="logo">
          <img src="@/assets/images/login/login-logo.png" alt="" />
        </div>
        <div class="mobile-code">
          <div class="mobile">
            <img style="width: 16px" src="@/assets/images/login/mobile.png" alt="" />
            <input v-model="form.account" type="text" placeholder="手机号" />
          </div>
          <div v-if="IS_FLAG" class="mobile" style="margin-top: 22px">
            <img style="width: 18px" src="@/assets/images/login/code.png" alt="" />
            <input v-model="form.code" type="text" placeholder="验证码" />
            <span @click="getMobileCode" v-if="showcode">获取验证码</span>
            <span v-else>{{ count }}秒重发</span>
          </div>
          <div v-if="!IS_FLAG" class="mobile" style="margin-top: 22px">
            <img style="width: 18px" src="@/assets/images/login/password.png" alt="" />
            <input v-model="form.password" type="password" placeholder="请输入密码" />
          </div>
        </div>
        <div class="check">
          <div class="box" @click="rememberPassword" :style="{
            border: IS_REMEMBER ? 'none' : '1px solid rgba(153,153,153,1)',
          }">
            <img v-if="IS_REMEMBER" src="@/assets/images/login/login-check.png" alt="" />
          </div>
          <div class="text" style="text-align: left; padding-left: 5px">
            记住账号
          </div>
        </div>
        <div class="check">
          <div class="box" @click="form.checked = !form.checked" :style="{
            border: form.checked ? 'none' : '1px solid rgba(153,153,153,1)',
          }">
            <img v-if="form.checked" src="@/assets/images/login/login-check.png" alt="" />
          </div>
          <div class="text" style="text-align: left; padding-left: 5px">
            我同意<a href="javascript:void(0);" @click="Show = true">《服务协议》</a>和<a href="javasript:;">《隐私政策》</a>的所有条款
          </div>
        </div>
        <div class="login-btn" @click="login">
          {{ IS_FLAG ? "登录 / 注册" : "登录" }}
        </div>
        <div class="operation">
          <span @click="changeLogin">{{
            IS_FLAG ? "密码登录" : "验证码登录"
          }}</span>
          <span v-if="!IS_FLAG">忘记密码？</span>
        </div>
      </div>
    </div>

    <div class="set-password" v-if="Set_Password && !Select_Sex">
      <div class="title">
        <img src="@/assets/images/login/login-logo.png" alt="" />
        设置密码
      </div>
      <div class="mobile-code">
        <div class="mobile">
          <img style="width: 16px" src="@/assets/images/login/password.png" alt="" />
          <input v-model="form.password" type="password" placeholder="最少6位字符" />
        </div>
        <div class="mobile" style="margin-top: 28px">
          <img style="width: 16px" src="@/assets/images/login/password.png" alt="" />
          <input v-model="form.confirmPassword" type="password" placeholder="确认密码" />
        </div>
      </div>
      <div class="btn" @click="confirmSetPassword">确定</div>
    </div>

    <div class="set-password" v-if="false">
      <div class="title">
        <img src="@/assets/images/login/login-logo.png" alt="" />
        忘记密码
      </div>
      <div class="mobile-code">
        <div class="mobile">
          <img style="width: 16px" src="@/assets/images/login/mobile.png" alt="" />
          <input type="number" placeholder="手机号" />
        </div>
        <div class="mobile" style="margin-top: 28px">
          <img style="width: 16px" src="@/assets/images/login/code.png" alt="" />
          <input type="number" placeholder="验证码" />
          <span>获取验证码</span>
        </div>
      </div>
      <div class="btn">下一步</div>
      <div class="back">返回</div>
    </div>

    <div class="service-agreement" v-if="Show">
      <div class="title">
        <img src="@/assets/images/login/login-logo.png" alt="" />
        服务协议
      </div>
      <div class="content">
        <div v-for="item in 100" :key="item">占位内容-{{ item }}</div>
      </div>
      <div class="btn" @click="Show = !Show">返回</div>
    </div>

    <div class="select-gender" v-if="Select_Sex && !Select_Person">
      <div class="title">
        <img src="@/assets/images/login/login-logo.png" alt="" />
        选择性别
      </div>
      <div class="nickname">
        <span>昵称</span>
        <input v-model="form.nickname" type="text" placeholder="请输入您的昵称" />
      </div>
      <div class="gender-icon">
        <div class="gender-box" @click="form.sex = 1">
          <img src="@/assets/images/login/gender-1.png" alt="" />
          <img v-if="form.sex === 1" style="position: absolute" src="@/assets/images/login/gender-active.png" alt="" />
        </div>
        <div class="gender-box" @click="form.sex = 0">
          <img src="@/assets/images/login/gender-0.png" alt="" />
          <img v-if="form.sex === 0" style="position: absolute" src="@/assets/images/login/gender-active.png" alt="" />
        </div>
      </div>
      <div class="gender-desc flex-row">
        <div class="text">男</div>
        <div class="text">女</div>
      </div>
      <div class="next cursor" @click="nextHandle">下一步</div>
    </div>

    <!-- Select_Person && !IS_FINISH -->
    <div class="select-protocol" v-if="Select_Person && !IS_FINISH">
      <div class="title">
        <img src="@/assets/images/login/login-logo.png" alt="" />
        选择形象
      </div>
      <div class="protocol-list flex-row">
        <div class="protocol">
          <img @click="form.profile = '1'" :src="form.sex === 1
            ? require('@/assets/images/home/male-1.png')
            : require('@/assets/images/home/female-1.png')
            " alt="" />
          <img v-if="form.profile === '1'" style="position: absolute; left: 0"
            src="@/assets/images/login/fictitious-active.png" alt="" />
        </div>
        <div class="protocol">
          <img @click="form.profile = '2'" :src="form.sex === 1
            ? require('@/assets/images/home/male-2.png')
            : require('@/assets/images/home/female-2.png')
            " alt="" />
          <img v-if="form.profile === '2'" style="position: absolute; left: 0"
            src="@/assets/images/login/fictitious-active.png" alt="" />
        </div>
        <div class="protocol">
          <img @click="form.profile = '3'" :src="form.sex === 1
            ? require('@/assets/images/home/male-3.png')
            : require('@/assets/images/home/female-3.png')
            " alt="" />
          <img v-if="form.profile === '3'" style="position: absolute; left: 0"
            src="@/assets/images/login/fictitious-active.png" alt="" />
        </div>
        <div class="protocol">
          <img @click="form.profile = '4'" :src="form.sex === 1
            ? require('@/assets/images/home/male-4.png')
            : require('@/assets/images/home/female-4.png')
            " alt="" />
          <img v-if="form.profile === '4'" style="position: absolute; left: 0"
            src="@/assets/images/login/fictitious-active.png" alt="" />
        </div>
        <div class="protocol">
          <img @click="form.profile = '5'" :src="form.sex === 1
            ? require('@/assets/images/home/male-5.png')
            : require('@/assets/images/home/female-5.png')
            " alt="" />
          <img v-if="form.profile === '5'" style="position: absolute; left: 0"
            src="@/assets/images/login/fictitious-active.png" alt="" />
        </div>
      </div>
      <div class="protocol-text flex-row">
        <div class="text">形象1</div>
        <div class="text">形象2</div>
        <div class="text">形象3</div>
        <div class="text">形象4</div>
        <div class="text">形象5</div>
      </div>
      <div class="save" @click="registerMobile">保存</div>
      <div class="prev" @click="prevStep">上一步</div>
    </div>

    <div class="finish" v-if="IS_FINISH && LoadingStatus">
      <img src="../assets/images/login/login-big-logo.png" alt="" />
      <p>{{ IS_FIRST ? "恭喜您，设置成功 !" : "登录成功 !" }}</p>
      <el-button class="custom-btn" @click="gotoHomePage">进入M-Land元宇宙</el-button>
      <div class="change" v-if="!IS_FIRST" @click="handleTransfiguration">
        更换虚拟人形象
      </div>
    </div>

    <Layout v-model="transfiguration">
      <ConversionProfile v-model="transfiguration"></ConversionProfile>
    </Layout>

    <LoginMask :show="LoadingStatus" :isFinish="IS_FINISH"></LoginMask>
  </div>
</template>

<script>
import "@/assets/css/login.css";
import {
  callUnity,
  All_Chinese,
  Chinese_Rules,
  Group_Rules,
  isMobile,
  storage,
} from "@/assets/js/index";
import {
  validatePhone,
  register,
  signin,
  sendSms,
  validateSms,
} from "@/apis/auth";
import { mapState } from "vuex";
export default {
  name: "Login",

  data() {
    return {
      form: {
        account: "",
        code: "",
        password: "",
        confirmPassword: "",
        checked: false,
        nickname: "",
        profile: "1",
        sex: 1,
      },
      count: 0,
      showcode: true,
      transfiguration: false,
      Show: false,
      IS_REMEMBER: false,
      codeLogin: false,
      IS_FLAG: true,
      Select_Sex: false,
      Select_Person: false,
      Set_Password: false,
      IS_FINISH: false,
      IS_FIRST: false,
      TIMER: null,
      debounce: null,
      webglMethods: "",
      webglData: {},
      LoadingStatus: false,
    };
  },

  methods: {
    /* 获取手机验证码 */
    getMobileCode() {
      if (this.debounce !== null) clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const { account } = this.form;
        if (!account) return this.$message.error("请填写手机号");
        if (!isMobile(account))
          return this.$message.error("当前手机号不符合规则");
        const response = await validatePhone({ account });
        if (response.msg === "账号已存在,请勿重新注册") this.codeLogin = true;
        const res = await sendSms({ account });
        this.$message.success("发送验证码成功");
        const TIME_OUT = 60;
        if (!this.TIMER) {
          this.count = TIME_OUT;
          this.showcode = false;
          this.TIMER = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_OUT) {
              this.count--;
            } else {
              this.showcode = true;
              clearInterval(this.TIMER);
              this.TIMER = null;
            }
          }, 1000);
        }
        if (process.env.NODE_ENV !== "production") {
          this.form.code = JSON.parse(res.data.msg).code;
        }
      }, 700);
    },

    /* 记住密码 */
    rememberPassword() {
      if (!this.form.account) return this.$message.error("请先输入手机号");
      this.IS_REMEMBER = !this.IS_REMEMBER;
    },

    /* 登录 */
    async login() {
      const { account, checked } = this.form;
      if (!checked) return this.$message.error("请先勾选服务协议");
      if (!isMobile(account))
        return this.$message.error("当前手机号不符合规则");
      window.sessionStorage.clear();
      if (this.IS_FLAG) return this.loginAccount();
      this.loginPassword();
    },

    /* 手机号+验证码 */
    async loginAccount() {
      const { account, code } = this.form;
      if (!account || !code) return this.$message.error("请检查手机号或验证码");
      if (this.codeLogin) {
        // 验证码登录
        const res = await signin({ account, code });
        window.sessionStorage.setItem("token", JSON.stringify(res.data));
        this.IS_FINISH = true;
        this.IS_FIRST = res.data.first;
        if (this.IS_REMEMBER) {
          window.localStorage.setItem("account", account);
        } else {
          window.localStorage.removeItem("account");
        }
      } else {
        // 验证码注册登陆
        await validateSms({ account, code });
        if (this.IS_REMEMBER) {
          window.localStorage.setItem("account", account);
        } else {
          window.localStorage.removeItem("account");
        }
        this.Set_Password = true;
      }
    },

    /* 手机号+密码 */
    async loginPassword() {
      const { account, password } = this.form;
      if (!password || !account)
        return this.$message.error("请检查手机号或密码");
      const res = await signin({ account, password: this.$md5(password) });
      window.sessionStorage.setItem("token", JSON.stringify(res.data));
      if (this.IS_REMEMBER) {
        window.localStorage.setItem("account", account);
      } else {
        window.localStorage.removeItem("account");
      }
      this.IS_FINISH = true;
    },

    /* 修改登录方式 */
    changeLogin() {
      this.IS_FLAG = !this.IS_FLAG;
      this.$nextTick(() => {
        this.form.account = "";
        this.form.code = "";
        this.form.password = "";
        this.form.confirmPassword = "";
        this.form.checked = "";
        this.form.nickname = "";
        this.form.sex = 1;
        this.form.profile = "1";
      });
    },

    /* 修改虚拟形象 */
    handleTransfiguration() {
      this.$store.dispatch("home/getUserInfoAction").then(() => {
        this.transfiguration = true;
      });
    },

    /* 设置密码 */
    confirmSetPassword() {
      const { password, confirmPassword } = this.form;
      if (!password || !confirmPassword)
        return this.$message.error("请输入密码和确认密码");
      if (password !== confirmPassword)
        return this.$message.error("两次输入的密码不一致,请重新输入");
      this.Select_Sex = true;
    },

    /* 设置昵称下一步 */
    nextHandle() {
      if (!this.form.nickname) return this.$message.error("请输入昵称");
      if (
        (All_Chinese(this.form.nickname) &&
          Chinese_Rules(this.form.nickname)) ||
        (!All_Chinese(this.form.nickname) && Group_Rules(this.form.nickname))
      ) {
        this.Select_Person = true;
      } else {
        this.$message.error("当前昵称不符合规则");
      }
    },

    /* 设置形象上一步 */
    prevStep() {
      this.Select_Sex = true;
      this.Select_Person = false;
    },

    /* 注册 */
    async registerMobile() {
      const { account, password, profile, sex, nickname } = this.form;
      let reaParams = {
        account,
        nickname,
        password: this.$md5(password),
        profile,
        sex,
      };
      const res = await register(reaParams);
      window.sessionStorage.setItem("token", JSON.stringify(res.data));
      this.IS_FINISH = true;
      this.IS_FIRST = res.data.first;
    },

    /* 进入首页 */
    async gotoHomePage() {
      const redirect = this.$route.query.redirect;
      let DirSpaceId = ""
      if (redirect != null) {
        console.log(redirect, '===========redirect')
        // 使用正则表达式匹配宝鸡市后面的字符
        let matchResult = redirect.match(/\/home\/([^/]+)/);
        // 如果有匹配结果，获取第一个捕获组的值
        if (matchResult && matchResult[1]) {
          DirSpaceId = matchResult[1];
          console.log("matched spaceid" + DirSpaceId); // 输出: 宝鸡市
        } else {
          console.log("spaceid not match")
        }
      }

      // let urls = [];
      // if (redirect) urls = redirect.split("/");
      // const url = urls.length > 0 ? `/home/${urls[2]}/${urls[3]}` : "/home";
      const url = redirect ? redirect : "/home";
      this.$store.dispatch("home/getUserInfoAction").then((res) => {
        // console.log("Login.vue Get DirSpaceId Login " + DirSpaceId)
        let params = {
          job: "teacher",
          id: res.userId,
          nickname: res.nickname,
          vrProfile: res.vrProfile,
          sex: res.sex === 1 ? "boy" : "girl",
          loginToken: storage.get("token").token,
          products: res.products,
          per: res.per,
          veModels: res.veModels,
          chains: res.chains,
          DirSpaceId: DirSpaceId
        }
        callUnity("creatAvatar", params)
        this.$store.dispatch("home/getJrysToken").then(() => {
          this.$store.commit("meet/globalLoading", true);
          this.$router.push(url);
        });
      });
    },
  },

  computed: {
    ...mapState("unity", ["METHODS"]),
  },

  mounted() {
    this.$store.commit("home/RESET_STATE");
    if (window.localStorage.getItem("account")) {
      this.IS_REMEMBER = true;
      this.form.account = window.localStorage.getItem("account");
    }
  },

  watch: {
    METHODS: {
      handler(n) {
        if (n == "unityLanuched") this.LoadingStatus = true;
      },
    },
  },
};
</script>
