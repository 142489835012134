<template>
  <div id="app">
    <router-view />
    <iframe
      class="basic-iframe"
      v-show="routes.includes($route.name)"
      ref="IFRAME"
      src="/unity3D/index.html"
      frameborder="0"
    ></iframe>

    <!-- 帮助 -->
    <Layout v-model="GUIDE_STATUS" :z-index="1002">
      <Assistance v-model="GUIDE_STATUS" />
    </Layout>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { storage, mixins, callUnity } from "@/assets/js";
export default {
  name: "App",
  mixins: [mixins],
  data() {
    return {
      routes: ["HomeView", "Meet"],
      t: Date.now(),
    };
  },

  computed: {
    ...mapState("home", ["USERINFO", "GUIDE_STATUS"]),
    ...mapState("unity", ["METHODS"]),
  },

  methods: {
    /* 初始化人物 */
    async initialize_character() {
      if (this.USERINFO.userId) {
        var DirSpaceId = window.sessionStorage.getItem("DirSpaceId")
        let params = {
          job: "teacher",
          id: this.USERINFO.userId,
          nickname: this.USERINFO.nickname,
          vrProfile: this.USERINFO.vrProfile,
          sex: this.USERINFO.sex === 1 ? "boy" : "girl",
          loginToken: storage.get("token").token,
          products: this.USERINFO.products,
          veModels: this.USERINFO.veModels,
          per: this.USERINFO.per,
          chains: this.USERINFO.chains,
          DirSpaceId : DirSpaceId
        }
        // console.log("App.vue Get DirSpaceId Login " + DirSpaceId)
        callUnity("creatAvatar", params);
        //callUnity("switchSpace", { scene: "CompanyHall", stype: "" });
      }
    },

    unityWatch(param) {
      const data =
        param &&
        param.detail &&
        param.detail.str &&
        JSON.parse(param.detail.str);
      this.$store.dispatch("unity/SET_ACTION_METHODS", data.MethodName);
      this.$store.dispatch("unity/SET_ACTION_DATA", data.Data || {});
    },
  },

  watch: {
    METHODS: {
      handler(n) {
        if (n === "unityLanuched") {
          this.initialize_character();
        }
      },
    },
  },

  mounted() {
    window.addEventListener("getUnityData", this.unityWatch);

    var OssUrl =
      "https://suoxun-expert.oss-cn-shanghai.aliyuncs.com/mlandUnity/";
    var jsonPath = OssUrl + "VersionDev_Lin.json?_r=" + Math.random();
    var IsPro = window.location.origin === "https://mland.mtheworld.com";
    var IsTest = window.location.origin === "https://meet.mtheworld.com";
    if (IsPro) {
      jsonPath = OssUrl + "VersionPro.json?_r=" + Math.random();
    } else if (IsTest) {
      jsonPath = OssUrl + "VersionTest.json?_r=" + Math.random();
    }
    fetch(jsonPath)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let ele = document.querySelector(".basic-iframe");
        ele.src = ele.src + "?t=" + data.buildVer;
        console.log(ele.src);
      })
      .catch((error) => console.error(error));
  },

  beforeDestroy() {
    window.removeEventListener("getUnityData", this.unityWatch);
  },
};
</script>

<style>
iframe {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  font-family: "Helvetica Neue", PingFangSC-regular, "Hiragino Sans GB",
    "Microsoft YaHei", "WenQuanYi Micro Hei", " sans-serif";
}

input {
  line-height: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cursor {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

hr {
  width: 100%;
  height: 1px;
  border-top: 0;
  background: #e9ecf1;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
</style>
