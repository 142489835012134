<script>
import { createBusinessCollaboration } from '@/apis/user'
export default {
  name: "ContactUs",
  props: {
    value: {
      default: false,
      required: true
    }
  },

  data() {
    return {
      form: {
        companyName: "",
        contactName: "",
        contactPhone: "",
        contactWx: "",
        content: ""
      }
    }
  },

  methods: {
    handle(idx) {
      if (idx === 0) {
        this.currentIdx = idx
      } else {
        this.currentIdx = idx || null
      }
      this.show = !this.show
    },

    /* 提交表单 */
    async dataFormSubmit() {
      if (!this.form.companyName || !this.form.contactName || !this.form.contactPhone || !this.form.contactWx || !this.form.content) return this.$message.error("此表单各项为必填项")
      await createBusinessCollaboration(this.form)
      this.$message.success("提交成功")
      this.$emit("input", false)
    },
  }
}
</script>

<template>
  <div class="contact-us" v-if="value">
    <div class="content">
      <div class="main-header flex-row">
        <img src="@/assets/images/meet/close-white.png" alt="" style="opacity: 0;" />
        <span>商业合作</span>
        <img @click="$emit('input', false)" src="@/assets/images/meet/close-white.png" alt="" />
      </div>

      <div class="form">
        <div class="form-item flex-row">
          <div class="form-label">公司名称</div>
          <input type="text" v-model="form.companyName">
        </div>
        <div class="form-item flex-row">
          <div class="form-label">联系人姓名</div>
          <input type="text" v-model="form.contactName">
        </div>
        <div class="form-item flex-row">
          <div class="form-label">联系人电话</div>
          <input type="text" v-model="form.contactPhone">
        </div>
        <div class="form-item flex-row">
          <div class="form-label">微信号</div>
          <input type="text" v-model="form.contactWx">
        </div>
        <div class="form-item flex-row">
          <div class="form-label">需求/意见</div>
          <textarea name="content" id="content" v-model="form.content"></textarea>
        </div>
        <div class="submit" @click="dataFormSubmit">提交</div>
      </div>
    </div>
  </div>
</template>


<style lang='scss' scoped>
.contact-us {
  .content {
    background: rgba(0, 31, 74, 0.50);
    border: 1px solid rgba(190, 218, 255, 1);
    border-radius: 8px;
    width: 800px;
    height: 660px;
    backdrop-filter: blur(10px);

    .main-header {
      background-image: linear-gradient(90deg, #005BD4 0%, rgba(0, 91, 212, 0.00) 93%);
      border-radius: 8px 0px 0px 0px;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;

        &:hover {
          cursor: pointer;
        }
      }

      span {
        font-size: 24px;
        color: #F9F9F9;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
      }
    }

    .form {
      margin-top: 40px;

      .form-item {
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        .form-label {
          width: 100px;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: right;
          font-weight: 500;
          margin-right: 20px;
        }

        input[type='text'] {
          padding-left: 8px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(190, 218, 255, 1);
          border-radius: 4px;
          color: #fff;
          width: 440px;
          height: 44px;
          outline: none;
        }

        textarea {
          padding: 8px;
          color: #fff;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(190, 218, 255, 1);
          border-radius: 4px;
          width: 440px;
          height: 154px;
          outline: none;
          resize: none;
        }
      }

      .submit {
        margin: 0 auto;
        width: 140px;
        height: 40px;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 36px;
        font-weight: 400;
        background: rgba(0, 90, 213, 0.50);
        border: 1px solid rgba(190, 218, 255, 1);
        border-radius: 30px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>


