<template>
  <div
    class="file"
    v-if="value"
    v-loading="loading"
    element-loading-text="正在上传中..."
  >
    <el-upload
      ref="upload"
      style="display: none"
      :action="action"
      :data="dataObj"
      :multiple="false"
      :before-upload="beforeUpload"
      :on-error="handleError"
      :on-success="handleUploadSuccess"
      :accept="'.pdf,.doc,.xlsx,.ppt'"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>

    <div class="title flex-row">
      <span>文件</span>
      <img
        src="../assets/images/home/dialog-close.png"
        alt=""
        @click="$emit('input', false)"
      />
    </div>
    <div class="file-list flex-row">
      <div class="upload flex-col" @click="uploadFile">
        <img src="@/assets/images/meet/gray-uplaod.png" alt="" />
        <span>本地上传</span>
      </div>
      <div
        class="file-item flex-col"
        v-for="(item, index) in files"
        :key="index"
      >
        <img
          class="file-suffix"
          v-if="fileSuffixHandle(item) === '.doc'"
          src="@/assets/images/meet/Word@1x.png"
          alt=""
        />
        <img
          class="file-suffix"
          v-if="fileSuffixHandle(item) === '.xlsx'"
          src="@/assets/images/meet/Excel@1x.png"
          alt=""
        />
        <img
          class="file-suffix"
          v-if="fileSuffixHandle(item) === '.ppt'"
          src="@/assets/images/meet/ppt@1x.png"
          alt=""
        />
        <img
          class="file-suffix"
          v-if="fileSuffixHandle(item) === '.pdf'"
          src="@/assets/images/meet/PDF@1x.png"
          alt=""
        />
        <div class="text">{{ item.fileOriginalName }}</div>
        <div class="flex-row footer">
          <span>{{ item.creator }}</span>
          <div class="btns-dd flex-row">
            <img
              @click="remove(item)"
              style="width: 24px; height: 24px"
              src="@/assets/images/meet/del.png"
              alt=""
            />
            <img
              @click="download(item)"
              style="width: 22px; height: 22px"
              src="@/assets/images/meet/download.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/file.css";
import { getMeetFileList, uploadFiles, deleteFile } from "@/apis/meet";
import { getFileSha1 } from "@/assets/js/Sha1";
import { policy2 } from "@/apis/upload";
export default {
  name: "FileManageMent",
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    meetId: {
      type: String,
      required: true,
    },

    asstEnabled: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      current: 1,
      total: 0,
      files: [],
      action: "https://sxmland.oss-cn-beijing.aliyuncs.com",
      /* 上传附件 */
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
      },
      meetForm: {
        fileId: "",
        fileName: "",
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getFiles();
    });
  },

  computed: {
    /* 附件 */
    fileList() {
      return [
        {
          id: this.meetForm.fileId,
          fileName: this.meetForm.fileName,
        },
      ];
    },
  },

  methods: {
    /* 获取文件 */
    async getFiles() {
      const res = await getMeetFileList({
        meetId: this.meetId,
        pageNo: 1,
        pageSize: 50,
        type: this.asstEnabled === 1 ? "PPT" : "DOC",
      });
      this.files = res.data.list;
      this.total = res.data.total;
    },

    /* 上传本地文件 */
    uploadFile() {
      this.$refs["upload"].$children[0].handleClick();
    },

    /* 删除文件 */
    async remove(data) {
      await deleteFile({ fileId: data.fileId, meetId: this.meetId });
      this.$message.success("删除附件成功");
      this.getFiles();
    },

    /* 下载文件 */
    download(data) {
      const x = new window.XMLHttpRequest();
      x.open("GET", data.fileLink, true);
      x.responseType = "blob";
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = data.fileOriginalName;
        a.style.display = "none";
        document.body.append(a);
        a.click();
      };
      x.send();
    },

    async beforeUpload(file) {
      let _self = this;
      _self.loading = true;
      _self.fileSuffix = file.name.substring(file.name.lastIndexOf("."));
      const fileSha1 = await getFileSha1(file);
      return new Promise((resolve, reject) => {
        policy2({
          fileName: file.name,
          fileSize: file.size,
          fileSha1: fileSha1,
          fileSuffix: _self.fileSuffix,
        })
          .then((response) => {
            _self.dataObj.policy = response.data.policy;
            _self.dataObj.signature = response.data.signature;
            _self.dataObj.ossaccessKeyId = response.data.accessid;
            _self.dataObj.id = response.data.id;
            _self.dataObj.key = response.data.dir + response.data.name;
            _self.dataObj.dir = response.data.dir;
            _self.dataObj.host = response.data.host;
            resolve(true);
          })
          .catch((err) => {
            _self.loading = false;
            console.log("出错了...", err);
            reject(new Error(false));
          });
      });
    },

    async handleUploadSuccess() {
      await uploadFiles({
        fileId: this.dataObj.id,
        meetId: this.meetId,
        type: "DOC",
      });
      this.loading = false;
      this.getFiles();
    },

    /* 处理错误 */
    handleError(err) {
      console.log(err, "error");
      this.loading = false;
    },

    /* 处理文件后缀 */
    fileSuffixHandle(item) {
      if (item.fileOriginalName) {
        return item.fileOriginalName.substring(
          item.fileOriginalName.lastIndexOf(".")
        );
      }
    },
  },
};
</script>

