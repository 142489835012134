<script>
import "@/assets/css/online.css";
import { getOnlineFriend } from '@/apis/friend'

export default {
  name: "OnlineFriend",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showFriend: false,
      nickName: "",
      friends: [],
      current: 1,
      total: 0,
    };
  },

  methods: {
    /* 查询在线好友 */
    async queryOnlineFriend() {
      const res = await getOnlineFriend({ nickname: this.nickName, pageNo: this.current, pageSize: 5 })
      this.friends = res.data.list
      this.total = res.data.total
    },

    /* 手动搜索 */
    queryHandle() {
      this.current = 1
      this.queryOnlineFriend()
    },

    /* 切换分页 */
    handleCurrentChange(current) {
      this.current = current;
      this.queryOnlineFriend();
    },
  },

  watch: {
    /* 初始化操作 */
    "value"(newVal) {
      if (newVal) {
        this.current = 1
        this.queryOnlineFriend()
      }
    }
  }
};
</script>

<template>
  <div class="online-friend" v-if="value">
    <div class="content">
      <div class="title flex-row">
        <span>在线好友</span>
        <img @click="$emit('input', false)" src="../assets/images/home/dialog-close.png" alt="" />
      </div>
      <div class="search flex-row">
        <div class="search-input flex-row">
          <input v-model="nickName" type="text" placeholder="搜索在线的好友" />
          <img src="../assets/images/home/search.png" alt="" @click="queryHandle" />
        </div>
        <div class="mail-list flex-row" @click="showFriend = true">
          <img src="../assets/images/home/mail-list.png" alt="" />
          <span>管理通讯录</span>
        </div>
      </div>
      <div class="qs" v-if="friends.length === 0">暂无在线好友</div>
      <div class="table-list">
        <div class="item flex-row" v-for="(item, index) in friends" :key="index">
          <div class="avatar">
            <img :src="item.avatar ? item.avatar : require(`../assets/images/home/avatar.png`)" alt="" />
          </div>
          <span>{{ item.nickname }}</span>
          <div class="go-here flex-row">
            <img src="../assets/images/home/go-here.png" alt="" />
            到TA身边
          </div>
        </div>
      </div>
      <el-pagination v-if="friends.length !== 0" style="text-align: right" @current-change="handleCurrentChange"
        :current-page.sync="current" :page-size="5" background layout="total, prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <!-- 管理通讯录 -->
    <Layout v-model="showFriend">
      <Friend v-model="showFriend"></Friend>
    </Layout>
  </div>
</template>

