export default {
  namespaced: true,

  state: {
    METHODS: "",
    DATA: null
  },

  mutations: {
    SET_UNITY_METHODS(state, methodName) {
      state.METHODS = methodName
    },

    SET_UNITY_DATA(state, data) {
      state.DATA = data
    }
  },

  actions: {
    SET_ACTION_METHODS({ commit }, data) {
      return new Promise((resolve) => {
        commit("SET_UNITY_METHODS", data)
        resolve()
      })
    },

    SET_ACTION_DATA({ commit }, data) {
      return new Promise((resolve) => {
        commit("SET_UNITY_DATA", data)
        resolve()
      })
    }
  },
};
