var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member"},[_c('div',{staticClass:"cotainer"},[_c('div',{staticClass:"top flex-row"},[_c('img',{attrs:{"src":require("../assets/images/login/login-logo.png"),"alt":""}}),_c('img',{staticStyle:{"margin":"0 10px 0 20px"},attrs:{"src":require("../assets/images/home/arrow-back.png"),"alt":""}}),_c('span',{on:{"click":function($event){return _vm.$router.push({ name: 'HomeView' })}}},[_vm._v("返回")])]),_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"content-list flex-row"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"item",style:({ background: _vm.active === 'manage' ? `url(${_vm.img})` : `` }),on:{"click":function($event){return _vm.changeActive('manage')}}},[_vm._v(" 成员管理 ")]),_c('div',{staticClass:"item",style:({ background: _vm.active === 'add' ? `url(${_vm.img})` : `` }),on:{"click":function($event){return _vm.changeActive('add')}}},[_vm._v(" 添加成员 ")])]),(_vm.active === 'manage')?_c('div',{staticClass:"middle"},[_c('div',{staticClass:"search flex-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nickName),expression:"nickName"}],attrs:{"type":"text","placeholder":"搜索好友"},domProps:{"value":(_vm.nickName)},on:{"input":function($event){if($event.target.composing)return;_vm.nickName=$event.target.value}}}),_c('img',{attrs:{"src":require("../assets/images/home/search.png"),"alt":""}})]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"middle-container"},[_vm._l((_vm.friends),function(item,index){return _c('div',{key:index,staticClass:"middle-item",class:{ active: item.id === _vm.currentInfo.id },on:{"click":function($event){return _vm.handleCurrnet(item)}}},[_c('div',{staticClass:"sub-container flex-row",style:({
                'border-bottom':
                  item.id !== _vm.currentInfo.id
                    ? '1px dashed rgba(153,153,153,1)'
                    : '',
              })},[_c('img',{attrs:{"src":require("../assets/images/home/avatar.png"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])])}),_c('div',{staticClass:"total"},[_vm._v("共有"+_vm._s(_vm.friends.length)+"条记录")])],2)]):_vm._e(),(_vm.active === 'manage')?_c('div',{staticClass:"right flex-row"},[_c('div',{staticClass:"describle"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.currentInfo.name))]),_c('div',{staticClass:"real-name flex-row"},[_c('span',[_vm._v("姓名：")]),_c('div',[_vm._v(_vm._s(_vm.currentInfo.name))])]),_vm._m(1)]),_vm._m(2)]):_vm._e(),(_vm.active === 'add')?_c('div',{staticClass:"member-form"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"btn"},[_vm._v("保存")])]):_vm._e(),(_vm.active === 'add')?_c('div',{staticClass:"member-code flex-col"},[_c('div',{staticClass:"share"},[_vm._v("分享二维码通知加入")]),_c('div',{staticClass:"subtitle"},[_vm._v("成员扫码后快速加入展厅")]),_vm._m(7),_c('div',{staticClass:"url"},[_vm._v("复制邀请链接")])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title flex-row"},[_c('span',[_vm._v("展厅成员管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name flex-row"},[_c('span',[_vm._v("职位：")]),_c('div',[_vm._v("常州天宁经济技术开发区管委会招商中心主任")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vr-box flex-col"},[_c('div',{staticClass:"vr"},[_c('img',{attrs:{"src":require("../assets/images/home/member-vr.png"),"alt":""}})]),_c('div',{staticClass:"handle-name flex-row"},[_c('div',{staticClass:"handle"},[_vm._v("设为负责人")]),_c('div',{staticClass:"handle"},[_vm._v("删除成员")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item flex-row"},[_c('span',{staticClass:"label"},[_vm._v("账号")]),_c('input',{attrs:{"type":"text","placeholder":"请输入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item flex-row"},[_c('span',{staticClass:"label"},[_vm._v("姓名")]),_c('input',{attrs:{"type":"text","placeholder":"请输入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-item flex-row"},[_c('span',{staticClass:"label"},[_vm._v("职位")]),_c('input',{attrs:{"type":"text","placeholder":"请输入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agree flex-row"},[_c('input',{attrs:{"id":"agree","type":"checkbox"}}),_c('label',{attrs:{"for":"agree"}},[_vm._v("通过短信发送邀请信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('img',{attrs:{"src":require("../assets/images/home/code.png"),"alt":""}})])
}]

export { render, staticRenderFns }