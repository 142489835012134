<template>
  <div class="create-meet" v-if="value" v-loading="loading" element-loading-text="正在上传中...">
    <div class="main-header">
      <span>创建会议</span>
      <img class="cursor" src="@/assets/images/meet/close.png" alt="" @click="$emit('input', false)" />
    </div>
    <div class="container flex-row">
      <!-- <div class="invite-friend">
          <div class="search flex-row">
            <input type="text" placeholder="搜索好友" />
            <img src="../assets/images/home/search.png" alt="" @click="queryHandle" />
          </div>
          <div class="my-friend">我的好友(0)</div>
          <div class="friend-list">
            <div class="friend-item flex-row" v-for="(item, index) in 5">
              <div class="avatar"><img src="@/assets/images/home/avatar.png" alt=""></div>
              <span>{{ `中产${index + 1}` }}</span>
              <input type="checkbox">
            </div>
          </div>
        </div> -->
      <div class="form">
        <div class="form-item flex-row">
          <span class="form-label">会议主题</span>
          <input class="enter" type="text" placeholder="限30个字符" v-model="form.topic" />
        </div>
        <div class="form-item flex-row">
          <span class="form-label">开始时间</span>
          <el-date-picker class="form-date" v-model="form.beginDateTime" value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime" placeholder="选择日期时间" />
        </div>
        <div class="form-item flex-row" v-if="form.asstEnabled !== 'TRUE'">
          <span class="form-label">时长</span>
          <el-select class="form-date" v-model="form.duration" placeholder="请选择">
            <el-option v-for="item in DURATION_TIMES" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="form-item flex-row">
          <span class="form-label">选择会议室</span>
          <el-select class="form-date" v-model="form.roomNo" placeholder="请选择">
            <el-option v-for="item in ROOM_LIST" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="form-item flex-row">
          <span class="form-label">虚拟主持人</span>
          <el-select class="form-date" v-model="form.asstEnabled" placeholder="请选择">
            <el-option v-for="item in HOST_LIST" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </div>
        <div class="form-item flex-row" v-if="form.asstEnabled === 'TRUE'">
          <span class="form-label">性别</span>
          <el-select class="form-date" v-model="form.asstSex" placeholder="请选择">
            <el-option v-for="item in SEX_LIST" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </div>
        <div class="form-item flex-row" v-if="form.asstEnabled === 'TRUE'">
          <span class="form-label">自动播放</span>
          <el-select class="form-date" v-model="form.asstAuto" placeholder="请选择">
            <el-option v-for="item in AUTO_LIST" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </div>
        <div class="form-item flex-row" v-if="form.asstEnabled === 'TRUE'">
          <span class="form-label">公司名称</span>
          <input class="enter" type="text" placeholder="限30个字符" v-model="form.companyName" />
        </div>
        <div class="form-item flex-row">
          <span class="form-label"></span>
          <div class="meet-item">
            <img src="@/assets/images/meet/big-hall.jpg" alt="" v-if="form.roomNo === 'Big'" />
            <img src="@/assets/images/meet/small-hall.jpg" alt="" v-else />
          </div>
          <!-- <div class="desc">
              适用于小型会议，共有2个区域：演讲区，观众区，同时用于会议投屏幕，我要发言，会场装修，人员管理等功能。
            </div> -->
        </div>
        <div class="form-item flex-row">
          <span class="form-label">安全设置</span>
          <input id="password" type="checkbox" v-model="needPwd" @change="changePasswdNeed" />
          <label for="password">入会密码</label>
          <input type="password" style="width: 208px" class="enter" v-model="form.passwd" placeholder="请输入4-6位数字" />
        </div>
        <div class="form-item flex-row">
          <span class="form-label"></span>
          <input id="need" type="checkbox" v-model="form.needSign" />
          <label for="need">参会人需要报名入会</label>
        </div>
        <div class="form-item flex-row" style="margin-bottom: 28px">
          <span class="form-label"></span>
          <input id="allow" type="checkbox" v-model="form.open" />
          <label for="allow">闭门会议</label>
        </div>
        <div class="form-item flex-row" style="margin-bottom: 8px">
          <span class="form-label">会议附件</span>
          <!-- 上传文件组 -->
          <div class="upload-group flex-row" style="flex: 1; align-items: center;" v-if="form.asstEnabled === 'TRUE'">
            <div class="upload-item" style="margin-right: 20px;">
              <div class="upload" @click="handleInvent('Presentation')" v-if="Presentation_Files.length < 1">
                <img src="@/assets/images/meet/预定会议-上传文件@1x.png" alt="" />
                <span>上传演示文件</span>
              </div>
              <div class="file-list flex-row" v-else>
                <img class="file-suffix" v-if="Presentation_Files[0].fileSuffix === '.doc'"
                  src="@/assets/images/meet/Word@1x.png" alt="" />
                <img class="file-suffix" v-if="Presentation_Files[0].fileSuffix === '.xlsx'"
                  src="@/assets/images/meet/Excel@1x.png" alt="" />
                <img class="file-suffix" v-if="Presentation_Files[0].fileSuffix === '.ppt'"
                  src="@/assets/images/meet/ppt@1x.png" alt="" />
                <img class="file-suffix" v-if="Presentation_Files[0].fileSuffix === '.pdf'"
                  src="@/assets/images/meet/PDF@1x.png" alt="" />
                <span>{{ Presentation_Files[0].fileName }}</span>
                <img class="remove" src="@/assets/images/meet/del.png" alt="" @click="clearFile('presentation')" />
              </div>
              <span class="file-desc" style="margin: 0;">请上传 PDF 路演文件</span>
            </div>
            <div class="upload-item">
              <div class="upload" @click="handleInvent('Explanation')" v-if="Explanation_Files.length < 1">
                <img src="@/assets/images/meet/预定会议-上传文件@1x.png" alt="" />
                <span>上传讲解文件</span>
              </div>
              <div class="file-list flex-row" v-else>
                <img class="file-suffix" v-if="Explanation_Files[0].fileSuffix === '.doc'"
                  src="@/assets/images/meet/Word@1x.png" alt="" />
                <img class="file-suffix" v-if="Explanation_Files[0].fileSuffix === '.xlsx'"
                  src="@/assets/images/meet/Excel@1x.png" alt="" />
                <img class="file-suffix" v-if="Explanation_Files[0].fileSuffix === '.ppt'"
                  src="@/assets/images/meet/ppt@1x.png" alt="" />
                <img class="file-suffix" v-if="Explanation_Files[0].fileSuffix === '.pdf'"
                  src="@/assets/images/meet/PDF@1x.png" alt="" />
                <span>{{ Explanation_Files[0].fileName }}</span>
                <img class="remove" src="@/assets/images/meet/del.png" alt="" @click="clearFile('explanation')" />
              </div>
              <span class="file-desc" style="margin: 0;">请上传EXCEL,一行文字对应一页PPT，无内容页对应的行留空。</span>
            </div>
          </div>
          <!-- 本地文件 -->
          <div class="flex-row" style="flex: 1; align-items: center;" v-else>
            <div class="upload" @click="$refs['el-upload'].$children[0].handleClick()" v-if="Local_Files.length < 1">
              <img src="@/assets/images/meet/预定会议-上传文件@1x.png" alt="" />
              <span>上传本地文件</span>
            </div>
            <div class="file-list flex-row" v-else>
              <img class="file-suffix" v-if="Local_Files[0].fileSuffix === '.doc'" src="@/assets/images/meet/Word@1x.png"
                alt="" />
              <img class="file-suffix" v-if="Local_Files[0].fileSuffix === '.xlsx'"
                src="@/assets/images/meet/Excel@1x.png" alt="" />
              <img class="file-suffix" v-if="Local_Files[0].fileSuffix === '.ppt'" src="@/assets/images/meet/ppt@1x.png"
                alt="" />
              <img class="file-suffix" v-if="Local_Files[0].fileSuffix === '.pdf'" src="@/assets/images/meet/PDF@1x.png"
                alt="" />
              <span>{{ Local_Files[0].fileName }}</span>
              <img class="remove" src="@/assets/images/meet/del.png" alt="" @click="clearFile('local')" />
            </div>
            <span class="file-desc">格式为doc、xlsx、ppt、pdf，文件小于10M</span>
          </div>

          <!-- :file-list="fileList" -->
          <el-upload ref="el-upload" style="display: none" :action="action" :data="dataObj" :multiple="false"
            :headers="headers" :before-upload="beforeUpload" :on-success="handleUploadSuccess"
            :on-error="handelUploadError" :accept="accept">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="handle flex-row">
      <div class="cursor" style="border-color: #999; color: #999" @click="$emit('input', false)">
        取消
      </div>
      <div class="cursor" style="margin-left: 50px" @click="createMeet">
        创建会议
      </div>
    </div>

    <!-- <div class="generate-modal" v-if="IS_Publish">
            <div class="successful">
                <div class="title">创建成功</div>
                <h4>会议创建成功，是否发布PPT</h4>
                <div class="successful-desc">
                    <img src="@/assets/images/meet/tip.png" alt="" />
                    发布成功后虚拟人可自动讲解PPT
                </div>
                <div class="btns flex-row">
                    <div class="btn" style="color: #999; border-color: #999">
                        暂不发布
                    </div>
                    <div class="btn">发布</div>
                </div>
            </div>
        </div> -->

    <!-- 内容预览 -->
    <div class="content-preview-modal" v-if="IS_Preview">
      <div class="content-preview" v-loading='reloading'>
        <div class="main-header">
          <span>会议创建成功</span>
          <img class="cursor" src="@/assets/images/meet/close.png" alt="" @click="closeReUpload" />
        </div>
        <h4>内容预览</h4>
        <div class="preview-area">
          <!-- <pdf ref='mypdf' :src="Preview_Info.fileLink" :page="pageNum" @num-pages="numPages = $event"></pdf> -->
          <VuePdfEmbed ref="pdfRef" :source="Preview_Info.fileLink" :page="pageNum"
            @rendered="handleDocumentRender" />
        </div>
        <div class="preview-text">{{ (PdfNoteList && PdfNoteList[pageNum - 1] && PdfNoteList[pageNum - 1].note) ||
          '' }}</div>
        <div class="flex-row btns-group">
          <div class="flex-row btns-items">
            <div style="width: 160px;height: 34px;margin-right: 20px;" @click="handleReDemonstrate">重新上传演示文件
            </div>
            <div style="width: 160px;height: 34px;" @click='handleReExplain'>重新上传讲解文件</div>
          </div>
          <div class="flex-row btns-items">
            <div style="width: 100px;height: 34px;margin-right: 20px;" @click='handlePrev'>上一页</div>
            <div style="width: 100px;height: 34px;" @click='handleNext'>下一页</div>
          </div>
        </div>
        <div class="publish-preview-btns flex-row">
          <div style="border-color: #999; color: #999" @click='noRelease'>暂不发布
          </div>
          <div @click='release'>发布</div>
        </div>
        <div class="preview-tip flex-row">
          <img src="@/assets/images/meet/tip.png" alt="">
          发布成功后虚拟人可语音讲解路演内容
        </div>
      </div>
    </div>
  </div>
</template>



<script>
// import pdf from 'vue-pdf'
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { createMeet, bookMeet, getPdfNoteList, getMeetFileList, convertVoice, updateMeetFile } from "@/apis/meet";
import {
  DURATION_TIMES,
  HOST_LIST,
  // ROOM_LIST,
  SEX_LIST,
  AUTO_LIST,
} from "@/assets/js/constants";
import { passwdReg, callUnity } from "@/assets/js/util";
import { getFileSha1 } from "@/assets/js/Sha1";
import { policy2 } from "@/apis/upload";
import "@/assets/css/create-conference.css";

export default {
  name: "CreateConference",
  components: {
    // pdf
    VuePdfEmbed
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        fileId: "",
        fileName: "",
        beginDateTime: "",
        passwd: "",
        topic: "",
        type: "DOC",
        roomNo: "",
        open: false,
        needSign: false,
        duration: 30,
        asstAuto: "FALSE",
        asstEnabled: "FALSE",
        asstSex: 0, // 0 男 1 女
      },

      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
      },

      DURATION_TIMES: DURATION_TIMES,
      HOST_LIST: HOST_LIST,
      ROOM_LIST: [
        { label: "小会议室", value: "small" },
        { label: "大会议室", value: "Big" }
      ],
      SEX_LIST: SEX_LIST,
      AUTO_LIST: AUTO_LIST,
      loading: false,
      reloading: false,
      show: false,
      needPwd: false,
      IS_Publish: false,
      IS_Preview: false,
      fileSuffix: "",
      Preview_Info: {},
      fileType: "",
      meetId: "",
      pageNum: 1,
      numPages: 0,
      PdfNoteList: [],
      Local_Files: [], // 本地文件
      Presentation_Files: [], // 演示文件
      Explanation_Files: [] // 讲解文件
    };
  },

  computed: {
    accept() {
      if (this.form.asstEnabled === "TRUE") {
        let newAccept = ".pdf"
        if (this.fileType === 'Explanation') newAccept = ".xlsx"
        return newAccept
      } else {
        return ".pdf,.doc,.xlsx,.ppt,.pptx"
      }
    },

    action() {
      if (this.form.asstEnabled === "TRUE") {
        let newUrl = "https://sxmland.oss-cn-beijing.aliyuncs.com"
        if (this.fileType === 'Explanation') newUrl = `${process.env.VUE_APP_SERVER_URL}/ml/ppt/upload/note`
        return newUrl
      } else {
        return "https://sxmland.oss-cn-beijing.aliyuncs.com"
      }
    },

    headers() {
      return this.form.asstEnabled == "TRUE"
        ? { Authorization: JSON.parse(sessionStorage.getItem("token")).token }
        : {};
    },
  },

  mounted() {
    // this.form.asstEnabled = "TRUE"
    // this.handlePreview()
  },

  methods: {
    /* 入会密码 */
    changePasswdNeed() {
      if (!this.needPwd) this.form.passwd = "";
    },

    /* 创建会议 */
    async createMeet() {
      const { topic, needSign, beginDateTime, open, roomNo, passwd, asstEnabled, } = this.form;
      if (!topic) return this.$message.error("请输入会议主题");
      if (!beginDateTime) return this.$message.error("请选择开始时间");
      if (!roomNo) return this.$message.error("请选择会议室");
      if (asstEnabled === "TRUE") {
        if (this.Presentation_Files.length < 1 || this.Explanation_Files.length < 1) {
          return this.$message.error("开启虚拟助手必须上传路演文件和讲解文件");
        } else if (!this.form.companyName) {
          return this.$message.error("请输入公司名称");
        }
      }
      if (this.needPwd && !passwdReg.test(passwd.trim())) return this.$message.error("入会密码必须是4-6位数字");

      let fileIds = []
      if (asstEnabled === "TRUE") {
        fileIds = [this.Presentation_Files[0].id]
      } else {
        this.Local_Files.length > 0 ? fileIds = [this.Local_Files[0].id] : []
      }

      let reqParams = {
        ...this.form,
        type: asstEnabled === "TRUE" ? "PPT" : "DOC",
        needSign: needSign ? "TRUE" : "FALSE",
        open: open ? "PRIVATE" : "PUBLIC",
        beginDateTime: beginDateTime + "",
        fileIds: fileIds,
      };
      const res = new Date(beginDateTime).getTime() > Date.now() ? await bookMeet(reqParams) : await createMeet(reqParams);
      this.$store.dispatch("meet/getMeetingPage");
      callUnity("RefreshAllUI", {})
      this.meetId = res.data.id
      asstEnabled === 'TRUE' ? this.handlePreview(res.data.id) : this.$emit("After_successful_creation", res.data.id);
    },

    /* 虚拟会议上传文件区分类型 */
    handleInvent(fileType) {
      if (this.Presentation_Files.length < 1 && fileType === 'Explanation') return this.$message.error("请先上传路演文件然后上传讲解文件")
      this.fileType = fileType
      this.$nextTick(() => {
        this.$refs['el-upload'].$children[0].handleClick()
      })
    },

    handleDocumentRender() {
      this.numPages = this.$refs.pdfRef.pageCount
    },

    /* 预览 */
    handlePreview(meetId) {
      getMeetFileList({ meetId, pageNo: 1, pageSize: 50, type: "PPT" }).then(async res => {
        this.Preview_Info = res.data.list[0]
        const response = await getPdfNoteList({ page: 1, fileId: this.Preview_Info.fileId })
        this.PdfNoteList = response.data
        this.IS_Preview = true
        // this.$nextTick(() => {
        //   let loadingTask = pdf.createLoadingTask(this.Preview_Info.fileLink)
        //   console.log(loadingTask)
        //   loadingTask.promise.then(pdf => {
        //     this.numPages = pdf.numPages
        //     console.log(this.numPages)
        //   }).catch((err) => {
        //     this.$message.error('pdf加载失败')
        //     console.error(err, 'pdf加载失败')
        //   })
        // })
      })
    },

    handlePrev() {
      if (this.pageNum > 1) {
        this.pageNum--
      }
    },

    handleNext() {
      if (this.pageNum >= this.numPages) return
      this.pageNum++
    },

    /* 重新上传演示文件 */
    handleReDemonstrate() {
      this.fileType = 'Presentation'
      this.$nextTick(() => {
        this.$refs['el-upload'].$children[0].handleClick()
      })
    },

    /* 重新上传讲解文件 */
    handleReExplain() {
      this.fileType = 'Explanation'
      this.$nextTick(() => {
        this.$refs['el-upload'].$children[0].handleClick()
      })
    },

    /* 重新处理上传文件 */
    async handleReUpload() {
      if (this.fileType === 'Presentation') {
        await updateMeetFile({ fileId: this.dataObj.id, type: "PPT", meetId: this.meetId, id: this.Preview_Info.id })
        this.$message.success("重新上传讲解文件成功")
        this.PdfNoteList = []
        this.pageNum = 1
        this.numPages = 0
        getMeetFileList({ meetId: this.meetId, pageNo: 1, pageSize: 50, type: "PPT" }).then(async res => {
          this.Preview_Info = res.data.list[0]
          this.IS_Preview = true
          // this.$nextTick(() => {
          //   let loadingTask = pdf.createLoadingTask(this.Preview_Info.fileLink)
          //   console.log(loadingTask)
          //   loadingTask.promise.then(pdf => {
          //     this.numPages = pdf.numPages
          //     console.log(this.numPages)
          //   }).catch((err) => {
          //     this.$message.error('pdf加载失败')
          //     console.error(err, 'pdf加载失败')
          //   })
          // })
        })
      } else if (this.fileType === 'Explanation') {
        const response = await getPdfNoteList({ page: 1, fileId: this.Preview_Info.fileId })
        this.PdfNoteList = response.data
      }
    },

    release() {
      if (this.PdfNoteList.length < 1) return this.$message.error("请上传讲解文件")
      convertVoice(this.meetId, this.Preview_Info.fileId).then(() => {
        this.$message.success("发布成功")
        this.$emit("After_successful_creation", this.meetId)
      })
    },

    noRelease() {
      if (this.PdfNoteList.length < 1) return this.$message.error("请上传讲解文件")
      this.$emit("After_successful_creation", this.meetId)
    },

    closeReUpload() {
      if (this.PdfNoteList.length < 1) return this.$message.error("请上传讲解文件")
      this.$emit('input', false)
    },

    /* 删除 */
    clearFile(text) {
      text === 'local' ? this.Local_Files.pop() : text === 'presentation' ? this.Presentation_Files.pop() : text === 'explanation' ?
        this.Explanation_Files.pop() : console.error("不想上班行不行")
    },

    /* 上传前操作 */
    async beforeUpload(file) {
      let _self = this;
      _self.IS_Preview ? _self.reloading = true : _self.loading = true;
      _self.fileSuffix = file.name.substring(file.name.lastIndexOf("."));
      const fileSha1 = await getFileSha1(file);
      return new Promise((resolve, reject) => {
        policy2({
          fileName: file.name,
          fileSize: file.size,
          fileSha1: fileSha1,
          fileSuffix: file.name.substring(file.name.lastIndexOf(".")),
        })
          .then((response) => {
            _self.dataObj.policy = response.data.policy;
            _self.dataObj.signature = response.data.signature;
            _self.dataObj.ossaccessKeyId = response.data.accessid;
            _self.dataObj.id = response.data.id;
            // _self.dataObj.fileId = response.data.id;
            _self.dataObj.key = response.data.dir + response.data.name;
            _self.dataObj.dir = response.data.dir;
            _self.dataObj.host = response.data.host;
            resolve(true);
          })
          .catch((err) => {
            this.loading = false;
            console.log("出错了...", err);
            reject(new Error(false));
          });
      });
    },

    /* 文件上传失败 */
    handelUploadError() {
      this.$message.error("文件上传失败，请重试")
      this.IS_Preview ? this.reloading = false : this.loading = false
      // this.loading = false
      if (this.form.asstEnabled === "TRUE") {
        this.fileType === 'Presentation' ? this.Presentation_Files.pop() : this.Explanation_Files.pop()
      } else {
        this.Local_Files.pop()
      }
    },

    /* 文件上传成功 */
    handleUploadSuccess(res, file) {
      console.log("successful", res, file);
      let fileSuffix = file.name.substring(file.name.lastIndexOf("."))

      if (this.form.asstEnabled === "TRUE") {
        if (this.fileType === 'Presentation') {
          this.Presentation_Files.pop()
          this.dataObj.fileId = this.dataObj.id
          this.Presentation_Files.push({ fileName: file.name, id: this.dataObj.id, fileSuffix })
        } else {
          this.Explanation_Files.pop()
          this.Explanation_Files.push({ fileName: file.name, id: '', fileSuffix })
        }
      } else {
        this.Local_Files.pop()
        this.Local_Files.push({ fileName: file.name, id: this.dataObj.id, fileSuffix })
      }

      if (this.IS_Preview) this.handleReUpload(res, file)

      this.IS_Preview ? this.reloading = false : this.loading = false

      // this.loading = false;
    },
  },

  watch: {
    "form.passwd": {
      handler(n) {
        if (!n) return (this.needPwd = false);
        this.needPwd = true;
      },
    },
  },
};
</script>

