export const DURATION_TIMES = [
  { label: "30分钟", value: 30 },
  { label: "60分钟", value: 60 },
  { label: "90分钟", value: 90 },
  { label: "120分钟", value: 120 },
  { label: "150分钟", value: 150 },
  { label: "180分钟", value: 180 },
]

export const HOST_LIST = [
  { name: "否", value: "FALSE" },
  { name: "是", value: "TRUE" },
]

export const ROOM_LIST = [
  { name: "小会议室", no: "small" },
  { name: "大会议室", no: "Big" }
]

export const SEX_LIST = [
  { name: "男", value: 0 },
  { name: "女", value: 1 }
]

export const AUTO_LIST = [
  { name: "是", value: "TRUE" },
  { name: "否", value: "FALSE" }
]
