import _axios from '../utils/https'

/**
 * 创建展厅
 * @param {*} data 
 * @returns 
 */
export const createHall = (data) => {
    return _axios({
        method: "post",
        url: `/ml/hall/exhibition/save`,
        data
    })
}

/**
 * 获取当前用户已加入展厅
 * @param {*} data 
 * @returns 
 */
export const getHalls = (data) => {
    return _axios({
        url: `/ml/hall/exhibition/joined-page`,
        method: "post",
        data
    })
}

/**
 * 获取展厅Info
 * @param {*} code 
 * @returns 
 */
export const getHallInfo = (code) => {
    return _axios({
        url: `/ml/hall/exhibition/get/${code}`
    })
}

/**
 * 修改展厅状态
 * @param {*} data 
 * @returns 
 */
export const updateStatus = (data) => {
    return _axios({
        url: `/ml/hall/exhibition/update/status`,
        method: "post",
        data
    })
}

/**
 * 查询[展厅内容]列表
 * @param {*} data 
 * @returns 
 */
export const getExhibitionList = (data) => {
    return _axios({
        url: `/ml/hall/exhibition/content/list`,
        method: "post",
        data
    })
}

/**
 * 新增展厅内容
 * @param {*} data 
 * @returns 
 */
export const createExhibition = (data) => {
    return _axios({
        url: `/ml/hall/exhibition/content/save`,
        method: "post",
        data
    })
}

/**
 * 修改展厅内容
 * @param {*} data 
 * @returns 
 */
export const updateExhibition = (data) => {
    return _axios({
        url: `/ml/hall/exhibition/content/update`,
        method: "post",
        data
    })
}









/**
 * 检索展厅
 * @param {*} data 
 * @returns 
 */
export const pageSerach = (data) => {
    return _axios({
        url: `/ml/exhibition/search-page`,
        method: "post",
        data
    })
}

/**
 * 获取展厅详情
 * @param {*} data 
 * @returns 
 */
export const getRoomBycode = (data) => {
    return _axios({
        url: `/ml/exhibition/queryDetailByCode`,
        method: "post",
        data
    })
}

/**
 * 更新展厅信息 
 * @param {*} data 
 * @returns 
 */
export const updateRoom = (data) => {
    return _axios({
        url: `/ml/exhibition/update`,
        method: "post",
        data
    })
}

