<script>
export default {
  name: "Assistance",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
<template>
  <div class="guide" v-if="value">
    <div class="content">
      <div class="title">
        <span>操作指南</span>
        <img src="../assets/images/home/dialog-close.png" @click="$store.commit('home/SET_GUIDE_STATUS')" alt="" />
      </div>
      <div class="detail flex-row">
        <div class="detail_left">
          <div class="top_list flex-row">
            <div class="top_item" style="width: 120px">
              <div class="detail_title">人物移动</div>
              <div class="detail_subtitle">WASD或方向键</div>
              <div class="detail_subtitle"></div>

            </div>
            <!-- <div class="top_item" style="width: 80px; ">
              <div class="detail_title">同步场景</div>
              <div class="detail_subtitle">M键</div>
              <div class="detail_subtitle"></div>

            </div>
            <div class="top_item" style="width: 100px; ">
              <div class="detail_title">麦克风开关</div>
              <div class="detail_subtitle">空格键</div>
              <div class="detail_subtitle"></div>

            </div>
            <div class="top_item" style="width: 80px; ">
              <div class="detail_title">静音开关</div>
              <div class="detail_subtitle">M键</div>
              <div class="detail_subtitle"></div>

            </div>
            <div class="top_item" style="width: 120px; ">
              <div class="detail_title">聚焦共享屏幕</div>
              <div class="detail_subtitle">F键</div>
              <div class="detail_subtitle"></div>
            </div> -->
          </div>
          <div class="bottom">
            <img src="../assets/images/home/keyboard.png" alt="" />
          </div>
        </div>
        <div class="detail_right">
          <div class="detail_right_top">
            <div class="detail_title">视角调整</div>
            <div class="detail_subtitle">按住并拖动鼠标左键</div>
            <div class="detail_subtitle">双击地面行走</div>
          </div>
          <div class="bottom">
            <img src="../assets/images/home/mouse.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.guide {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.3);

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1000px;
    padding: 21px;
    height: 560px;
    background: #fff;
    border-radius: 8px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;

      span {
        font-size: 28px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 600;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .detail {
    margin: 83px auto 0;
    padding: 0 72px;

    .detail_title {
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 600;
    }

    .detail_subtitle {
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 400;
    }

    .detail_left {
      width: 620px;

      .top_list {
        justify-content: space-between;
        width: 620px;
      }

      .bottom {
        margin-top: 40px;
      }
    }

    .detail_right {
      flex: 1;
      padding-left: 40px;

      .bottom {
        margin-top: 10px;
      }
    }
  }
}
</style>

