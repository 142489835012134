<style lang='scss' scoped>
.product-description {
  .content {
    background: rgba(0, 31, 74, 0.50);
    border: 1px solid rgba(190, 218, 255, 1);
    border-radius: 8px;
    width: 800px;
    padding-bottom: 30px;
    backdrop-filter: blur(10px);

    .main-header {
      background-image: linear-gradient(90deg, #005BD4 0%, rgba(0, 91, 212, 0.00) 93%);
      border-radius: 8px 0px 0px 0px;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;

        &:hover {
          cursor: pointer;
        }
      }

      span {
        font-size: 24px;
        color: #F9F9F9;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
      }
    }

    .main {
      margin-top: 20px;

      .question {
        margin: 0px auto;
        padding-left: 54px;
        width: 740px;
        height: 50px;
        border-radius: 4px;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 50px;
        font-weight: 400;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 25px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #C0DBFF;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #5d6979;
        }

        &:nth-child(odd) {
          background-color: rgba($color: #EBF2FA, $alpha: .1);
        }

        &:hover {
          cursor: pointer;
          background: #005BD4;
        }
      }

      .ask-question {
        align-items: center;
        justify-content: center;
        width: 185px;
        height: 50px;
        background: #005BD4;
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 35px;
        font-size: 18px;
        color: #F9F9F9;
        letter-spacing: 0;
        font-weight: 600;
        margin-top: 21px;
        margin-right: 29px;
        margin-left: auto;

        &:hover {
          cursor: pointer;
        }
      }

      .answer {
        padding: 0 30px;

        .return {
          align-self: flex-end;
          width: 160px;
          margin-top: 20px;
          height: 40px;
          background: rgba(0, 90, 213, 0.20);
          border: 1px solid rgba(190, 218, 255, 0.5);
          border-radius: 20px;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 36px;
          font-weight: 400;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .admin {
      align-self: flex-end;
      padding: 10px 20px;
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 30px;
      background-color: #005AD6;
      color: #FFFFFF;
      letter-spacing: 0;
      border-radius: 10px;
      font-weight: 400;
      max-width: 666px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 8px solid;
        border-color: transparent;
        border-left-color: #005AD6;
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .robot {
      align-self: flex-start;
      padding: 10px 20px;
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 30px;
      background-color: #fff;
      color: #333;
      letter-spacing: 0;
      border-radius: 10px;
      font-weight: 400;
      max-width: 666px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 8px solid;
        border-color: transparent;
        border-right-color: #fff;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .chat {
      height: 610px;

      .chat-input {
        width: 740px;
        margin: 0 auto;
        height: 50px;
        background: rgba(255, 255, 255, 0.10);
        border: 1px solid rgba(190, 218, 255, 0.5);
        border-radius: 4px;

        input[type='text'] {
          background: transparent;
          outline: none;
          border: none;
          color: #fff;
          padding: 0 10px;
          flex: 1;
          font-size: 20px;
        }

        .send-btn {
          width: 140px;
          height: 48px;
          background: #005AD6;
          border-radius: 4px;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 48px;
          font-weight: 400;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .chat-content {
        flex: 1;
        padding-top: 15px;
        width: 740px;
        margin: 0 auto;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .chat-list {
          padding: 0 16px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="product-description" v-if="value">
    <div class="content">
      <div class="main-header flex-row">
        <img src="@/assets/images/meet/close-white.png" alt="" style="opacity: 0;" />
        <span>热门问题</span>
        <img @click="$emit('input', false)" src="@/assets/images/meet/close-white.png" alt="" />
      </div>

      <div class="main" v-if="!more">
        <div v-if="!show">
          <div class="question" v-for="(item, index) in questions" :key="index" @click="handle(index)">{{ item }}
          </div>
          <div class="ask-question flex-row" @click="more = true">
            <img src="@/assets/images/meet/meet.png" alt="">
            <span>对话提问</span>
          </div>
        </div>
        <div class="answer flex-col" v-else>
          <div class="admin">{{ questions[currentIdx] }}</div>
          <div class="robot">{{ answers[currentIdx] }}</div>
          <div class="return" @click="handle">返回</div>
        </div>
      </div>

      <div class="chat flex-col" v-else>
        <div class="chat-content">
          <div v-for="item in 10" :key="item" class="flex-col chat-list">
            <div class="admin">{{ questions[0] }}</div>
            <div class="robot">{{ answers[1] }}</div>
          </div>
        </div>
        <div class="chat-input flex-row">
          <input type="text" v-model="chat_message">
          <div class="send-btn">发送</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDescription", // 产品说明
  props: {
    value: {
      default: false,
      required: true
    }
  },
  data() {
    return {
      questions: [
        "M-Land中有哪几个区域？",
        "公共大厅的主要作用？",
        "政府展厅里有什么？",
        "企业展厅里面有什么？ ",
        "如何前往其他场景？ ",
        "如何移动？",
        "怎么坐下？",
        "不能与其他人语音聊天?",
        "如何在VR设备中体验？"
      ],
      answers: [
        "程序中分为了公共大厅、政府展厅和企业展厅三个大的场景区域，其中政府展厅和企业展厅包含了会议室和720全景地图两个子场景。",
        "大会场可以参加各类大型会议，举办各种活动，发布会等，期间可以和他人交流，浏览相关的介绍和宣传片。\n 在公共大厅中可以查看M - Land中正在或即将举办的会议信息并快速报名参会，也可以查看M - Land中的产业链图谱信息。",
        "政府展厅里主要是园区介绍，如园区的土地规划、产业布局、主要企业、宣传视频等，可查看园区的720全景地图和政府组织的各种会议信息。",
        "企业展厅里主要是企业的介绍和产品展示，可查看企业组织的各种会议信息。",
        "点击左下角切换空间，在弹出的地图中选择目标场景名称点击即可跳转进入。",
        "电脑端：使用键盘的WASD键移动，按住鼠标左键控制方向。\n VR端：使用左摇杆控制移动，转动头部控制视角。",
        "靠近座椅后，点击座位上的坐下按钮即可入座，站起只需要移动即可。",
        "为了保证系统的流畅，我们限定游客和普通用户间是无法相互语音聊天的，在大家都进入同一个会议室的时候可以互相语音聊天。",
        "请联系我们的客服经理沟通需求。",
      ],
      chat_message: "",
      currentIdx: 0,
      show: false,
      more: false
    }
  },

  methods: {
    handle(idx) {
      if (idx === 0) {
        this.currentIdx = idx
      } else {
        this.currentIdx = idx || null
      }
      this.show = !this.show
    }
  }
}
</script>

