<template>
  <div class="show">
    <div class="cotainer">
      <div class="top flex-row">
        <img src="../assets/images/login/login-logo.png" alt="" />
        <img style="margin: 0 10px 0 20px" src="../assets/images/home/arrow-back.png" alt="" />
        <span @click="$router.push({ name: 'HomeView' })">返回</span>
      </div>
      <div class="content">
        <div class="title flex-row">
          <span>编辑展厅</span>
        </div>
        <div class="content-list flex-row">
          <div class="left">
            <div class="item" @click="changeActive('1')" :style="{ background: active === '1' ? `url(${img})` : `` }">
              墙面一
            </div>
            <div class="item" @click="changeActive('2')" :style="{ background: active === '2' ? `url(${img})` : `` }">
              墙面二
            </div>
            <div class="item" @click="changeActive('3')" :style="{ background: active === '3' ? `url(${img})` : `` }">
              墙面三
            </div>
            <div class="item" @click="changeActive('4')" :style="{ background: active === '4' ? `url(${img})` : `` }">
              视频
            </div>
          </div>
          <div class="middle">
            <div class="title flex-row">
              <span>标题</span>
              <input type="text" placeholder="请输入" v-model="form.title" />
            </div>
            <textarea class="enter" v-model="form.content" placeholder="请输入内容"></textarea>
            <div class="handle-btns flex-row">
              <div class="save" @click="createOrUpdateExhibition">保存</div>
              <div class="save" @click="$message.error('取消我又改去哪里呢~')" style="
                                  margin-left: 20px;
                                  border: 2px solid rgba(153, 153, 153, 1);
                                  color: #999;
                                ">
                取消
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title">位置图示（俯视图）</div>
            <div class="img1">
              <img :src="examples[active - 1].img1" alt="" />
            </div>
            <div class="title">参考画面</div>
            <div class="img2">
              <img :src="examples[active - 1].img2" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/show.css";
import {
  getExhibitionList,
  getHallInfo,
  createExhibition,
  updateExhibition,
} from "@/apis/show";
export default {
  name: "Show",
  props: ["id", "code"],
  data() {
    return {
      active: "1",
      img: require("../assets/images/home/item-active.png"),
      examples: [
        {
          img1: require("@/assets/images/home/编辑新展厅-位置-墙面一.png"),
          img2: require("@/assets/images/home/编辑新展厅-参考画面-墙面一.png"),
        },
        {
          img1: require("@/assets/images/home/编辑新展厅-位置-墙面二.png"),
          img2: require("@/assets/images/home/编辑新展厅-参考画面-墙面二.png"),
        },
        {
          img1: require("@/assets/images/home/编辑新展厅-位置-墙面三.png"),
          img2: require("@/assets/images/home/编辑新展厅-参考画面-墙面三.png"),
        },
        {
          img1: require("@/assets/images/home/编辑新展厅-位置-视频.png"),
          img2: require("@/assets/images/home/编辑新展厅-参考画面-视频.png"),
        },
        {
          img1: require("@/assets/images/home/编辑新展厅-位置-VR.png"),
          img2: require("@/assets/images/home/编辑新展厅-参考画面-VR.png"),
        },
      ],
      hallInfo: {},
      contentList: [],
      form: {
        title: "",
        content: "",
        position: "",
      },
    };
  },

  created() {
    this.getHallInfo();
    this.getExhibitionContent();
  },

  methods: {
    /* 切换tab */
    changeActive(active) {
      this.active = active;
      this.form = this.contentList.find((v) => v.position === active) || {
        title: "",
        content: "",
      };
    },

    /* 获取展厅Info */
    async getHallInfo() {
      const res = await getHallInfo(this.code);
      this.hallInfo = res.data;
    },

    /* 获取展厅四面墙的内容 */
    async getExhibitionContent() {
      const res = await getExhibitionList({ code: this.code });
      this.contentList = res.data.contents;
      this.form = this.contentList.find((v) => v.position === this.active) || {
        title: "",
        content: "",
      };
    },

    /* 创建 or 编辑 展厅内容 */
    async createOrUpdateExhibition() {
      if (!this.form.title) return this.$message.error("请填写展厅标题")
      let reqParams = {
        content: this.form.content,
        enabled: 0,
        exhibitionCode: this.code,
        id: this.form.id || "",
        position: this.active,
        title: this.form.title,
        type: this.active === "4" ? "0" : "1",
      };
      this.form.id
        ? await updateExhibition(reqParams)
        : await createExhibition(reqParams);
      this.$message.success("操作成功");
      this.getExhibitionContent();
    },
  },
};
</script>
