<script>
import "@/assets/css/user.css";
import { changeAvatarOrNickName } from "@/apis/user";
import { callUnity, All_Chinese, Chinese_Rules, Group_Rules } from "@/assets/js/util";
export default {
  name: "ConversionProfile",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      userInfo: { ...this.$store.state.home.USERINFO },
      newPwd: '',
      oldPwd: '',
      IS_EDIT: false,
      image1: [
        require('@/assets/images/home/male-1-bg.png'),
        require('@/assets/images/home/male-2-bg.png'),
        require('@/assets/images/home/male-3-bg.png'),
        require('@/assets/images/home/male-4-bg.png'),
        require('@/assets/images/home/male-5-bg.png'),
      ],
      image0: [
        require('@/assets/images/home/female-1-bg.png'),
        require('@/assets/images/home/female-2-bg.png'),
        require('@/assets/images/home/female-3-bg.png'),
        require('@/assets/images/home/female-4-bg.png'),
        require('@/assets/images/home/female-5-bg.png'),
      ]
    }
  },

  computed: {
    /* 是否登录页 */
    isLoginPage() {
      return this.$route.name === 'Login'
    },

    /* 账号 */
    account() {
      const str = window.sessionStorage.getItem("token") && JSON.parse(window.sessionStorage.getItem('token')).account
      return str.slice(0, 3) + '*'.repeat(str.length - 7) + str.slice(-4)
    },

    /* 修改基本信息对象 */
    reqParams() {
      return {
        avatar: "",
        vrProfile: this.userInfo.vrProfile,
        sex: this.userInfo.sex,
        nickname: this.userInfo.nickname,
        pwd: {
          oldPwd: this.oldPwd ? this.$md5(this.oldPwd) : "",
          newPwd: this.newPwd ? this.$md5(this.newPwd) : "",
          copyNewPwd: this.newPwd ? this.$md5(this.newPwd) : "",
        }
      }
    }
  },

  methods: {
    /* 转变形象 */
    transformProfile() {
      this.IS_EDIT = true
      this.oldPwd = ''
      this.newPwd = ''
    },

    /* 选择形象 */
    async use() {
      if (!this.userInfo.vrProfile) return this.$message.error("请选择形象");
      await changeAvatarOrNickName(this.reqParams);
      callUnity("changeAvatarOrNickName", {
        job: "teacher",
        id: this.userInfo.userId,
        vrProfile: this.userInfo.vrProfile,
        nickname: this.userInfo.nickname,
        sex: this.userInfo.sex === 1 ? 'boy' : 'girl'
      });
      this.$message.success("修改成功");
      this.$store.dispatch('home/getUserInfoAction');
      this.isLoginPage ? this.$emit('input', false) : this.IS_EDIT = false
    },

    /* 验证nickname */
    validateNickNamePass() {
      return (All_Chinese(this.userInfo.nickname) && Chinese_Rules(this.userInfo.nickname)) || (!All_Chinese(this.userInfo.nickname) && Group_Rules(this.userInfo.nickname))
    },

    /* 修改昵称密码 */
    async changeNickNameOrPassword() {
      if (!this.userInfo.nickname) return this.$message.error("请检查未填写项");
      if (!this.validateNickNamePass()) return this.$message.error("当前昵称不符合规则")
      await changeAvatarOrNickName(this.reqParams);
      callUnity("changeAvatarOrNickName", {
        job: "teacher",
        id: this.userInfo.userId,
        vrProfile: this.userInfo.vrProfile,
        nickname: this.userInfo.nickname,
        sex: this.userInfo.sex === 1 ? 'boy' : 'girl',
      })
      this.$store.dispatch('home/getUserInfoAction')
      if (this.oldPwd || this.newPwd) {
        this.$message.success("修改成功, 请重新登陆");
        this.$router.push("/");
        window.sessionStorage.clear();
        window.location.reload()
      } else {
        this.$message.success("修改昵称成功");
      }
    },
  },

  // mounted() {
  //   this.$nextTick(() => {
  //     if (!this.userInfo.userId) this.$store.dispatch("home/getUserInfoAction")
  //   })
  // }
};
</script>
<template>
  <div class="user" v-if='value'>
    <div class="cotainer">
      <!-- 编辑个人资料 -->
      <div class="profile" v-if="!IS_EDIT && !isLoginPage">
        <div class="title flex-row">
          <span>个人资料</span>
          <img @click="$emit('input', false)" src="@/assets/images/home/dialog-close.png" alt="" />
        </div>
        <div class="info flex-row">
          <div class="left">
            <div class="show-person">
              <img :src="image1[userInfo.vrProfile - 1]" alt="" v-if="userInfo.sex === 1" />
              <img :src="image0[userInfo.vrProfile - 1]" alt="" v-else />
            </div>
            <div class="change-info flex-row" @click="transformProfile">
              <img src="@/assets/images/home/edit.png" alt="" />
              <span>修改形象</span>
            </div>
          </div>
          <div class="right flex-col">
            <div class="form-item">
              <span class="item-name">账号</span>
              <span class="mobile">{{ account }}</span>
            </div>
            <div class="form-item" style="margin-bottom: 30px; opacity: 0;">
              <span class="item-name">等级时间</span>
              <span class="time">2022.12.02～2023.12.03</span>
            </div>
            <div class="form-item flex-row">
              <span class="item-name">昵称</span>
              <div class="box">
                <input type="text" placeholder="请输入" name="" id="" v-model="userInfo.nickname" />
              </div>
            </div>
            <div style="color: #999; font-size: 16px;">
              <span style="width: 90px; display: inline-block;"></span>
              请设置2~7个汉字或是4~12个字符
            </div>
            <div class="form-item flex-row">
              <span class="item-name">原密码</span>
              <div class="box">
                <input type="password" placeholder="请输入" name="" id="" v-model.trim="oldPwd" />
              </div>
            </div>
            <div class="form-item flex-row">
              <span class="item-name">新密码</span>
              <div class="box">
                <input type="password" placeholder="请输入" name="" id="" v-model.trim="newPwd" />
              </div>
            </div>
            <div class="save" @click="changeNickNameOrPassword">保存</div>
          </div>
        </div>
      </div>
      <!-- 修改形象 -->
      <div class="profile-edit" v-else>
        <div class="title">修改形象</div>
        <div class="content flex-row">
          <div class="left">
            <div class="top flex-row">
              <div class="number">1.</div>
              <div class="gender flex-row">
                <div class="gender-item" style="margin-left: 40px">
                  <div class="image">
                    <img @click="userInfo.sex = 1" src="@/assets/images/login/gender-1.png" alt="" />
                    <img v-if="userInfo.sex === 1" style="position: absolute"
                      src="@/assets/images/login/gender-active.png" alt="" />
                  </div>
                  <div class="gender-text">男</div>
                </div>
                <div class="gender-item" style="margin-left: 50px">
                  <div class="image">
                    <img @click="userInfo.sex = 0" src="@/assets/images/login/gender-0.png" alt="" />
                    <img v-if="userInfo.sex === 0" style="position: absolute"
                      src="@/assets/images/login/gender-active.png" alt="" />
                  </div>
                  <div class="gender-text">女</div>
                </div>
              </div>
            </div>
            <div class="bottom flex-row">
              <div class="number">2.</div>
              <div class="vr-list">
                <div class="vr-item">
                  <div class="vr-image">
                    <img :src="userInfo.sex === 1
                      ? require('@/assets/images/home/male-1.png')
                      : require('@/assets/images/home/female-1.png')
                      " alt="" @click="userInfo.vrProfile = '1'" />
                    <img style="position: absolute; left: 0" v-if="userInfo.vrProfile === '1'"
                      src="@/assets/images/user/vr-active.png" alt="" />
                  </div>
                  <div class="vr-text">形象1</div>
                </div>
                <div class="vr-item"> 
                  <div class="vr-image">
                    <img :src="userInfo.sex === 1
                      ? require('@/assets/images/home/male-2.png')
                      : require('@/assets/images/home/female-2.png')
                      " alt="" @click="userInfo.vrProfile = '2'" />
                    <img style="position: absolute; left: 0" v-if="userInfo.vrProfile === '2'"
                      src="@/assets/images/user/vr-active.png" alt="" />
                  </div>
                  <div class="vr-text">形象2</div>
                </div>
                <div class="vr-item">
                  <div class="vr-image">
                    <img :src="userInfo.sex === 1
                      ? require('@/assets/images/home/male-3.png')
                      : require('@/assets/images/home/female-3.png')
                      " alt="" @click="userInfo.vrProfile = '3'" />
                    <img style="position: absolute; left: 0" v-if="userInfo.vrProfile === '3'"
                      src="@/assets/images/user/vr-active.png" alt="" />
                  </div>
                  <div class="vr-text">形象3</div>
                </div>
                <div class="vr-item">
                  <div class="vr-image">
                    <img :src="userInfo.sex === 1
                      ? require('@/assets/images/home/male-4.png')
                      : require('@/assets/images/home/female-4.png')
                      " alt="" @click="userInfo.vrProfile = '4'" />
                    <img style="position: absolute; left: 0" v-if="userInfo.vrProfile === '4'"
                      src="@/assets/images/user/vr-active.png" alt="" />
                  </div>
                  <div class="vr-text">形象4</div>
                </div>
                <div class="vr-item">
                  <div class="vr-image">
                    <img :src="userInfo.sex === 1
                      ? require('@/assets/images/home/male-5.png')
                      : require('@/assets/images/home/female-5.png')
                      " alt="" @click="userInfo.vrProfile = '5'" />
                    <img style="position: absolute; left: 0" v-if="userInfo.vrProfile === '5'"
                      src="@/assets/images/user/vr-active.png" alt="" />
                  </div>
                  <div class="vr-text">形象5</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="img">
              <img :src="image1[userInfo.vrProfile - 1]" alt="" v-if="userInfo.sex === 1" />
              <img :src="image0[userInfo.vrProfile - 1]" alt="" v-else />
            </div>
            <div class="btn" @click="use">使用此形象</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
