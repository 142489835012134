<script>
import { getMeetDetail } from "@/apis/meet";
export default {
  name: "InviteParticipants",
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    /* 会议Id */
    meetId: {
      type: String,
      default: "",
    },

    /* 是否需要报名设置 */
    Registration_Set: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      enrollSetting: false,
      meetDataForm: {},
      link: "",
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getMeetDetail();
    });
  },

  methods: {
    /* 获取会议详情 */
    async getMeetDetail() {
      const res = await getMeetDetail(this.meetId);
      this.meetDataForm = res.data;
      this.link =
        window.location.origin +
        "/home/" +
        this.meetDataForm.id +
        "/" +
        this.meetDataForm.no;
    },

    /* 前往报名设置 */
    handleRegistration_Set() {
      this.$emit("updateRegistration_Set", false);
      this.enrollSetting = true;
    },

    /* 复制链接信息 */
    copy() {
      var input = document.createElement("textarea");
      let str = "";
      if (!this.meetDataForm.passwd && !this.meetDataForm.needSign) {
        str = `${this.meetDataForm.creator} 邀请您参加会议\n会议主题：${this.meetDataForm.topic}\n会议时间: ${this.meetDataForm.meetingDate} ${this.meetDataForm.beginTime}\n点击链接入会或添加至会议列表：${this.link}`;
      } else if (this.meetDataForm.passwd && !this.meetDataForm.needSign) {
        str = `${this.meetDataForm.creator} 邀请您参加会议\n会议主题：${this.meetDataForm.topic}\n会议时间: ${this.meetDataForm.meetingDate} ${this.meetDataForm.beginTime}\n会议密码：${this.meetDataForm.passwd}\n点击链接入会或添加至会议列表：${this.link}`;
      } else if (!this.meetDataForm.passwd && this.meetDataForm.needSign) {
        str = `${this.meetDataForm.creator} 邀请您参加会议\n会议主题：${this.meetDataForm.topic}\n会议时间: ${this.meetDataForm.meetingDate} ${this.meetDataForm.beginTime}\n该会议开启了报名功能，请点击链接报名后入会：\n点击链接入会或添加至会议列表：${this.link}`;
      } else if (this.meetDataForm.passwd && this.meetDataForm.needSign) {
        str = `${this.meetDataForm.creator} 邀请您参加会议\n会议主题：${this.meetDataForm.topic}\n会议时间: ${this.meetDataForm.meetingDate} ${this.meetDataForm.beginTime}\n会议密码：${this.meetDataForm.passwd}\n该会议开启了报名功能，请点击链接报名后入会：\n点击链接入会或添加至会议列表：${this.link}`;
      }
      input.value = str;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message.success("复制成功");
    },
  },
};
</script>
<template>
  <div class="invite-participants" v-if="value">
    <!-- 标题 -->
    <div class="title flex-row">
      <span>邀请参会者</span>
      <img
        @click="$emit('input', false)"
        src="../assets/images/home/dialog-close.png"
        alt=""
      />
    </div>

    <!-- 表单内容 -->
    <div class="meet-detail">
      <div class="invite-meet">{{ meetDataForm.creator }} 邀请您参加会议</div>
      <div class="subtitle">会议号: {{ meetDataForm.no }}</div>
      <div class="subtitle">会议主题：{{ meetDataForm.topic }}</div>
      <div class="subtitle">
        会议时间: {{ `${meetDataForm.meetingDate} ${meetDataForm.beginTime}` }}
      </div>
      <div
        class="subtitle"
        style="margin-bottom: 36px"
        :style="{ opacity: meetDataForm.passwd ? '1' : '0' }"
      >
        会议密码: {{ meetDataForm.passwd }}
      </div>
      <div class="default" v-if="meetDataForm.needSign">
        该会议开启了报名功能，请点击链接报名后入会：
      </div>
      <div class="default">
        点击链接入会或添加至会议列表：<a
          :href="link"
          target="_blank"
          class="url"
          style="text-decoration: none"
          >{{ link }}</a
        >
      </div>
    </div>

    <!-- 底部操作按钮 -->
    <div class="handles flex-row">
      <div class="copy" @click="copy">复制链接信息</div>
    </div>

    <!-- 会议创建成功 -->
    <div
      class="success-container"
      v-if="meetDataForm.needSign && Registration_Set"
    >
      <div class="create-success">
        <div class="success-tip">会议创建成功</div>
        <div class="desc-tip">
          您为本场会议开启了报名功能，请前往完成报名设置。
        </div>
        <div class="btns flex-row">
          <div class="btn" @click="handleRegistration_Set">前往设置</div>
          <div
            class="btn"
            style="margin-left: 20px; color: #999; border-color: #999"
            @click="$emit('updateRegistration_Set', false)"
          >
            稍后设置
          </div>
        </div>
      </div>
    </div>

    <!-- 报名设置 -->
    <Layout v-model="enrollSetting" :z-index="999">
      <enroll-setting
        v-model="enrollSetting"
        :signSettingId="meetDataForm.signSettingId"
        :meeting-id="meetDataForm.id"
      >
      </enroll-setting>
    </Layout>
  </div>
</template>


<style lang="scss" scoped>
/* 邀请参会者 */
@import url("@/assets/css/invite-participants.css");

.success-container {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;

  .create-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 600px;
    height: 260px;
    padding: 15px 20px;

    .success-tip {
      font-size: 28px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
    }

    .desc-tip {
      margin-top: 50px;
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
    }

    .btns {
      margin-top: 46px;
      justify-content: center;

      .btn {
        width: 140px;
        height: 40px;
        border: 2px solid #005ad5;
        border-radius: 30px;
        font-size: 20px;
        color: #005ad5;
        letter-spacing: 0;
        text-align: center;
        line-height: 36px;
        font-weight: 400;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
