<template>
  <div class="meet-details" v-if="value">
    <!-- 报名设置 -->
    <Layout v-model="enrollStatus">
      <enroll-setting v-model="enrollStatus" :sign-setting-id="info.signSettingId" :meetingId="info.id" />
    </Layout>

    <!-- 邀请参会 -->
    <Layout v-model="inviteStatus">
      <inviteParticipants v-model="inviteStatus" :meet-id="info.id" :Registration_Set="Registration_Set"
        @updateRegistration_Set="(data) => (Registration_Set = data)" />
    </Layout>

    <div class="title flex-row">
      <span>会议详情</span>
      <img @click="$emit('input', false)" src="../assets/images/home/dialog-close.png" alt="" />
    </div>
    <div class="title-info flex-row">
      <div class="main-title flex-row">
        <span>{{ info.topic }}</span>
        <div class="start">
          {{
            info.state === 10
            ? "未开始"
            : info.state === 20
              ? "进行中"
              : info.state === 30
                ? "已结束"
                : info.state === 40
                  ? "已取消"
                  : "默认"
          }}
        </div>
        <div class="fix" @click="revise = true" v-if="!revise && isLeader && info.state !== 30">
          <img src="@/assets/images/meet/meet-edit.png" alt="" />
          {{ "修改" }}
        </div>
        <div class="fix" style="
            border: 2px solid rgba(0, 90, 213, 1);
            border-radius: 4px;
            color: #005ad5;
          " @click="reviseMeetDetail" v-if="revise && isLeader">
          确定
        </div>
      </div>
      <div class="inviter" @click="inviteStatus = true" v-if="info.state !== 30">
        <img src="@/assets/images/meet/invite-icon.png" alt="" />
        邀请参会者
      </div>
    </div>
    <div class="form-item flex-row">
      <span class="form-label">会议号：<i></i></span>
      <span class="form-content">{{ info.no }}</span>
    </div>
    <div class="form-item flex-row">
      <span class="form-label">会议日期：<i></i></span>
      <span class="form-content" v-if="!revise">{{
        `${info.meetingDate} ${info.beginTime}`
      }}</span>
      <el-date-picker v-else class="form-date" v-model="c_meetingDate" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
        placeholder="选择日期时间" />
    </div>
    <div class="form-item flex-row" v-if="info.asstEnabled">
      <span class="form-label">主持人性别：<i></i></span>
      <span class="form-content">{{ info.asstSex === 1 ? '男' : '女' }}</span>
    </div>
    <div class="form-item flex-row" v-if="info.asstEnabled">
      <span class="form-label">自动播放：<i></i></span>
      <span class="form-content">{{ info.asstAuto === 1 ? '是' : '否' }}</span>
    </div>
    <div class="form-item flex-row">
      <span class="form-label">会议室：<i></i></span>
      <span class="form-content" v-if="!revise">{{ info.meetRoom }}</span>
      <el-select class="form-date" v-else placeholder="请选择" v-model="c_roomNo">
        <el-option v-for="item in roomList" :key="item.no" :label="item.name" :value="item.no" />
      </el-select>
    </div>
    <div class="form-item flex-row">
      <span class="form-label">入会密码：<i></i></span>
      <span class="form-content" v-if="!revise">{{
        info.passwd ? info.passwd : "无密码"
      }}</span>
      <input type="password" v-model="c_passwd" v-else style="width: 208px" class="enter" placeholder="请输入4-6位数字" />
    </div>
    <div class="form-item flex-row">
      <span class="form-label">安全设置：<i></i></span>
      <span class="form-content">{{
        info.needSign ? "需要报名" : "无需报名"
      }}</span>
      <div class="btn-fix" @click="enrollmentSetting" v-if="info.needSign && isLeader && info.state !== 30">
        <img src="@/assets/images/meet/meet-edit.png" alt="" />
        报名设置
      </div>
    </div>
    <div class="form-item flex-row" v-if="info.asstEnabled">
      <span class="form-label">发布PPT：<i></i></span>
      <div class="btn-fix" style="margin: 0; color: #006edc; border-color: #006edc;" @click="publishNow"
        v-if="isLeader && info.state !== 30">
        立即发布
      </div>
    </div>

    <div class="line"></div>
    <div class="sponsor flex-row">
      <span class="label"> 发起人： </span>
      <div class="avatar-list flex-row">
        <div class="avatar-item">
          <div class="avatar">
            <img :src="avatar1[originator.vrNo - 1]" alt="" v-if="originator.sex === 1" />
            <img :src="avatar0[originator.vrNo - 1]" alt="" v-else />
          </div>
          <span class="name">{{ info.creator }}</span>
        </div>
      </div>
    </div>
    <div class="sponsor flex-row" style="margin-top: 20px; align-items: flex-start">
      <span class="label"> 参会人： </span>
      <div class="avatar-list flex-row">
        <div class="avatar-item" style="margin-bottom: 20px" v-for="(item, index) in allUserPage" :key="index">
          <div class="avatar">
            <img :src="avatar1[item.vrNo - 1]" alt="" v-if="item.sex === 1" />
            <img :src="avatar0[item.vrNo - 1]" alt="" v-else />
          </div>
          <span class="name">{{ item.userName }}</span>
        </div>
      </div>

      <div style="padding-top: 16px">
        <img src="@/assets/images/meet/arrow-bottom.png" alt="" />
      </div>
    </div>
    <div class="handle-group flex-row">
      <div @click="$emit('input', false)" class="btn" style="color: #999; border-color: #999; margin-right: 20px"
        v-if="isLeader">
        取消
      </div>
      <div class="btn" @click="$parent.$parent.enterTheConferenceRoom(info)" v-if="info.state !== 30">
        进入会议
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/meet-details.css";
import {
  getMeetDetail,
  getRoomList,
  updateMeetDetail,
  getAllUserPage,
  getMeetFileList,
  convertVoice
} from "@/apis/meet";
import { passwdReg } from "@/assets/js/util";
export default {
  name: "meetDetails",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isLeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      avatar1: [
        require("@/assets/images/home/male-1.png"),
        require("@/assets/images/home/male-2.png"),
        require("@/assets/images/home/male-3.png"),
        require("@/assets/images/home/male-4.png"),
      ],
      avatar0: [
        require("@/assets/images/home/female-1.png"),
        require("@/assets/images/home/female-2.png"),
        require("@/assets/images/home/female-3.png"),
        require("@/assets/images/home/female-4.png"),
      ],

      originator: {
        vrNo: this.$store.state.home.USERINFO.vrProfile,
        sex: this.$store.state.home.USERINFO.sex,
      },
      enrollStatus: false,
      inviteStatus: false,
      revise: false,
      Registration_Set: false,
      roomList: [],
      allUserPage: [],
      info: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getMeetDetail();
      this.getRoomList();
      this.getAllUserPage();
    });
  },
  computed: {
    /* 会议日期 */
    c_meetingDate: {
      get() {
        return new Date(this.info.beginDateTime);
      },

      set(value) {
        this.$set(this.info, "beginDateTime", value);
      },
    },

    /* 会议室 */
    c_roomNo: {
      get() {
        return this.info.meetRoom;
      },

      set(value) {
        this.$set(this.info, "meetRoom", value);
      },
    },

    /* 密码 */
    c_passwd: {
      get() {
        return this.info.passwd;
      },

      set(value) {
        this.$set(this.info, "passwd", value);
      },
    },
  },
  methods: {
    /* 获取所有参会人员列表 */
    async getAllUserPage() {
      const res = await getAllUserPage({
        meetId: this.id,
        name: "",
        pageNo: 1,
        pageSize: 50,
      });
      this.allUserPage = res.data.list;
    },

    /* 获取会议详情 */
    async getMeetDetail() {
      const res = await getMeetDetail(this.id);
      this.info = res.data;
      this.$set(
        this.info,
        "beginDateTime",
        this.info.meetingDate + " " + this.info.beginTime
      );
    },

    /* 获取会议室列表 */
    async getRoomList() {
      const res = await getRoomList();
      this.roomList = res.data;
    },

    /* 报名设置 */
    enrollmentSetting() {
      if (!this.info.needSign) return this.$message.error("当前会议无需报名");
      if (this.revise) return this.$message.error("正在修改会议信息中");
      this.enrollStatus = true;
    },

    /* 修改 */
    async reviseMeetDetail() {
      if (this.info.passwd && !passwdReg.test(this.info.passwd.trim()))
        return this.$message.error("入会密码必须是4-6位数字");
      try {
        await updateMeetDetail({
          duration: 30,
          id: this.info.id,
          topic: this.info.topic,
          passwd: this.info.passwd,
          roomNo: this.info.meetRoom,
          beginDateTime: this.info.beginDateTime,
          needSign: this.info.needSign ? "TRUE" : "FALSE",
          open: this.info.open ? "PUBLIC" : "PRIVATE",
          asstAuto: this.info.asstAuto ? 'TRUE' : 'FALSE',
          asstEnabled: this.info.asstEnabled ? 'TRUE' : 'FALSE',
          asstSex: this.info.asstSex
        });
        this.$message.success("修改成功");
      } catch (e) {
        return false;
      }
      this.revise = false;
      this.getMeetDetail();
    },

    /* 立即发布 */
    publishNow() {
      getMeetFileList({ meetId: this.id, pageNo: 1, pageSize: 50, type: "PPT" }).then(res => {
        const list = res.data.list
        convertVoice(this.id, list[0].fileId).then(() => {
          this.$message.success("发布成功")
        })
      })
    }
  },
};
</script>
