export const storage = {
  //存储
  set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  //取出数据
  get(key) {
    try {
      const value = sessionStorage.getItem(key);
      if (value === null || value === undefined || value === "") return null;
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return null
    }
  },

  // 删除数据
  clear(key) {
    sessionStorage.removeItem(key);
  }
}

