<script>
import { callUnity } from "@/assets/js/util";
export default {
  name: "SwitchSpace",

  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      show: false,
      scene: "",
      size: "",
    };
  },

  methods: {
    switchSpace(data) {
      this.scene = data.scene;
      this.size = data.size;
      this.show = true;
    },

    async clickJump() {
      if (this.scene === "720") {
        this.$emit("switchSpace", true);
      } else {
        callUnity("switchSpace", {
          scene: this.scene,
          stype: this.size === "Big" ? "Big" : "small",
        });
        this.$emit("switchSpace", false);
      }
    },
  },

  computed: {
    VISIT_SCENE() {
      let scene =
        this.scene === "720"
          ? "720°全景"
          : this.scene === "CommonHall"
            ? "公共大厅"
            : this.scene === "Meeting"
              ? "会议室"
              : this.scene === "SmallHall"
                ? "园区展厅"
                : this.scene === "CompanyHall"
                  ? "企业展厅"
                  : "";
      return scene;
    },
  },
};
</script>

<template>
  <div class="switch-space" v-if="value">
    <div class="main-header flex-row">
      <span>M-Land地图</span>
      <img src="@/assets/images/meet/close.png" alt="" @click="$emit('input', false)" />
    </div>
    <div class="box flex-col" style="left: 46px; top: 104px" @click="switchSpace({ scene: '720', size: '' })">
      <div class="title">720°全景</div>
      <img src="@/assets/images/home/sanjiao.png" alt="" />
    </div>
    <div class="box flex-col" style="left: 446px; top: 148px" @click="switchSpace({ scene: 'CommonHall', size: '' })">
      <div class="title">公共大厅</div>
      <img src="@/assets/images/home/sanjiao.png" alt="" />
    </div>
    <div class="box flex-col" style="left: 726px; top: 194px" @click="switchSpace({ scene: 'Meeting', size: '' })">
      <div class="title">会议室</div>
      <img src="@/assets/images/home/sanjiao.png" alt="" />
    </div>
    <div class="box flex-col" style="left: 962px; top: 282px" @click="switchSpace({ scene: 'SmallHall', size: '' })">
      <div class="title">园区展厅</div>
      <img src="@/assets/images/home/sanjiao.png" alt="" />
    </div>
    <div class="box flex-col" style="left: 234px; top: 160px" @click="switchSpace({ scene: 'Meeting', size: 'Big' })">
      <div class="title">大会议室</div>
      <img src="@/assets/images/home/sanjiao.png" alt="" />
    </div>
    <div class="box flex-col" style="left: 994px; top: 132px" @click="switchSpace({ scene: 'CompanyHall', size: '' })">
      <div class="title">企业展厅</div>
      <img src="@/assets/images/home/sanjiao.png" alt="" />
    </div>
    <Layout v-model="show">
      <div class="visit">
        <div style="text-align: right">
          <img src="@/assets/images/meet/close.png" style="cursor: pointer" alt="" @click="show = false" />
        </div>
        <div class="visite-introduce">
          <img src="@/assets/images/meet/visit.png" alt="" />
          <span v-if="VISIT_SCENE === '会议室'">{{
            `您即将参观${size === "Big" ? "大会议室" : "会议室"}`
          }}</span>
          <span v-else>{{ `您即将参观${VISIT_SCENE}` }}</span>
        </div>
        <div class="d-btns flex-row">
          <div class="btn" style="background: #999" @click="show = false">
            取消
          </div>
          <div class="btn" style="background: #265bcd" @click="clickJump">
            确定
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<style lang="scss" scoped>
.switch-space {
  position: relative;
  width: 1200px;
  height: 680px;
  background-image: url("@/assets/images/home/switch-space.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  .main-header {
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 25px;
    background-image: linear-gradient(270deg,
        rgba(0, 90, 211, 0) 0%,
        rgba(2, 69, 160, 0.8) 98%);

    span {
      font-size: 20px;
      color: #f9f9f9;
      letter-spacing: 0;
      text-align: center;
      font-weight: 700;
    }

    img {
      width: 30px;
      height: 30px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    &:hover {
      cursor: pointer;
    }

    .title {
      width: 170px;
      height: 50px;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 46px;
      text-align: center;
      font-weight: 500;
      background: rgba(0, 35, 82, 0.5);
      border: 2px solid rgba(255, 255, 255, 1);
    }

    img {
      margin-top: 8px;
      width: 38.75px;
      height: 40px;
      object-fit: contain;
    }
  }

  .visit {
    width: 600px;
    height: 260px;
    background: #ffffff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    position: relative;

    .visite-introduce {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 45px;
        height: 45px;
        object-fit: cover;
      }

      span {
        margin-left: 10px;
        font-size: 28px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
      }
    }

    .d-btns {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);

      .btn {
        margin: 0 20px;
        font-size: 20px;
        color: #fff;
        letter-spacing: 0;
        text-align: center;
        line-height: 40px;
        font-weight: 400;
        width: 140px;
        height: 40px;
        border-radius: 30px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

