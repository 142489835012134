<template>
  <div class="home">
    <!-- 内嵌720 -->
    <iframe v-if="show" class="iframe" :src="get720Src()" frameborder="0"></iframe>

    <!-- Loading -->
    <Loading v-model="$store.state.meet.globalLoading"></Loading>

    <!-- Chain map -->
    <Layout v-model="CHAIN_STATUS" :z-index="1002">
      <chain v-model="CHAIN_STATUS" :industryName="industryName" :isChainUser="isChainUser" @handleCompanyDetails="handleCompanyDetails" />
    </Layout>

    <!-- 公司详情 -->
    <Layout v-model="showModal" :z-index="1002">
      <company-details v-model="showModal" :companyCode="companyCode"></company-details>
    </Layout>

    <!-- 加入会议 -->
    <Layout v-model="JOIN_STATUS" :z-index="1002">
      <join-meeting v-model="JOIN_STATUS" :meetNo="meetNo" :meetId="meetId" :NEED_PWD_INPUT="NEED_PWD_INPUT" />
    </Layout>

    <!-- 切换空间 -->
    <Layout v-model="swithcSpaceStatus" :z-index="1002">
      <switch-space v-model="swithcSpaceStatus" @switchSpace="(data) => {
        show = data;
        swithcSpaceStatus = false;
      }
        " />
    </Layout>

    <!-- 产品说明 -->
    <Layout v-model="descriptionStatus" :z-index="1002">
      <product-description v-model="descriptionStatus" />
    </Layout>

    <!-- 联系我们 -->
    <Layout v-model="contactUsStatus" :z-index="1002">
      <contact-us v-model="contactUsStatus" />
    </Layout>

    <!-- 会议管理 -->
    <Layout v-model="Manage_Meeting" :z-index="1002">
      <management-meeting v-model="Manage_Meeting" />
    </Layout>

    <!-- 邀请参加会议 -->
    <Layout v-model="inviteParticipantStatus" :z-index="1002">
      <invite-participants v-model="inviteParticipantStatus" :meetId="meetId" :Registration_Set="Registration_Set"
        @updateRegistration_Set="(data) => (Registration_Set = data)" />
    </Layout>

    <!-- 文件管理 -->
    <Layout v-model="managementFileStatus" :z-index="1002">
      <file-management v-model="managementFileStatus" :meetId="meetId" :asstEnabled="asstEnabled" />
    </Layout>

    <!-- 个人设置 -->
    <Layout v-model="SHOW_USER" :z-index="1002">
      <conversion-profile v-model="SHOW_USER"></conversion-profile>
    </Layout>

    <Layout v-model="CREATE_MEET_STATUS" :z-index="1002">
      <create-conference v-model="CREATE_MEET_STATUS"
        @After_successful_creation="After_successful_creation"></create-conference>
    </Layout>

    <!-- 切换空间 -->
    <div class="change-space" style="z-index: 1002" v-if="show" @click="closeSingle720">
      <img src="@/assets/images/meet/change-space.png" alt="" />
      <span>返回</span>
    </div>

    <!-- 返回会议室按钮 -->
    <div v-if="Panoramic_Map_Status && msg720data.IAmHost" class="go-meet" @click="closePanoramicMap"
      style="z-index: 9999999">
      <img src="@/assets/images/meet/change-space.png" alt="" />
      <span>返回</span>
    </div>

    <!-- 内嵌720 -->
    <iframe v-if="Panoramic_Map_Status" class="iframe" :src="get720Src()" frameborder="0"></iframe>

    <div class="siderbar" v-if="Panoramic_Map_Status">
      <div class="container">
        <div class="side-item flex-col" @click="setMicrophone">
          <img src="@/assets/images/meet/mkf.png" alt="" v-if="micStatus === true" />
          <img src="@/assets/images/meet/right-mkf-close.png" alt="" v-else />
          麦克风
        </div>
      </div>
    </div>

    <div>
      <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" accept="image/*" />
      <button @click="uploadFile">上传文件</button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { callUnity, mixins, storage } from "@/assets/js/index";
import { signout } from "@/apis/auth";
import { getMeetingPage } from "@/apis/meet";
import "@/assets/css/home.css";
export default {
  name: "Home",
  mixins: [mixins],
  data() {
    return {
      CHAIN_STATUS: false,
      Manage_Meeting: false,
      JOIN_STATUS: false,
      NEED_PWD_INPUT: true,
      SHOW_USER: false,
      Panoramic_Map_Status: false,
      Panoramic_Map_Url: "https://tianning.chinaindustria.com",
      CREATE_MEET_STATUS: false,
      INVITE_STATUS: false,
      Registration_Set: true,
      swithcSpaceStatus: false,
      contactUsStatus: false,
      descriptionStatus: false,
      inviteParticipantStatus: false,
      managementFileStatus: false,
      micStatus: true,
      show: false,
      showModal: false,
      companyCode: '',
      once: true,
      krpano: null,
      msg720data: {},
      webglData: {},
      WHETHER_TO_INVITE: {
        status: false,
        haveCloseIcon: true,
      },
      industryName: "",
      isChainUser: "",
      webglMethods: "",
      meetNo: "",
      meetId: "",
      asstEnabled: "",
    };
  },

  provide() {
    return {
      WHETHER_TO_INVITE: this.WHETHER_TO_INVITE,
    };
  },

  methods: {
    /* 关闭获取全景地图地址 */
    get720Src() {
      if(this.Panoramic_Map_Status)
      {
        return this.msg720data.IAmHost ? `${this.Panoramic_Map_Url}/#/new?meet&ok` : `${this.Panoramic_Map_Url}/#/new?meet`;
      }
      else
      {
        return `${this.Panoramic_Map_Url}/#/new?meet&ok`;
      }
    },

    closeSingle720() {
      this.show = false;
      // console.log("closeSingle720");
    },

    /* 关闭会议室全景地图 */
    closePanoramicMap() {
      this.Panoramic_Map_Status = false;
      callUnity("msg720", "closePanoramicMap");
    },

    // 展示会议详情
    handleCompanyDetails(companyCode) {
      this.companyCode = companyCode;
      this.showModal = true;
    },

    /* 设置麦克风 */
    setMicrophone() {
      callUnity("OpenCloseMic", {
        mic: this.micStatus === true ? "false" : "true",
      });
    },

    /* 跳转场景 */
    go(scene) {
      this.krpano = document.querySelector("iframe");
      this.krpano.contentWindow.postMessage(
        scene,
        this.Panoramic_Map_Url
      );
      console.log("go " + scene + " url " + this.Panoramic_Map_Url);
    },

    /* 退出登陆 */
    logout() {
      const token = storage.get("token");
      signout({ account: token.account }).then(() => {
        this.$message.success("退出成功");
        window.sessionStorage.clear();
        this.$router.push("/");
        window.location.reload();
      });
    },

    /* 完成创建 */
    After_successful_creation(meetId) {
      this.meetId = meetId;
      this.inviteParticipantStatus = true;
      this.CREATE_MEET_STATUS = false;
    },

    /* 预处理 */
    async handlePretreatment() {
      const response = await getMeetingPage({
        meetTopic: "",
        pageNo: 1,
        pageSize: 5,
      });
      // const res = await getVrMeetingPage({ meetTopic: '', pageNo: 1, pageSize: 5, })
      const total = response.data.total;
      // const vrtotal = res.data.total
      if (total) return;
      this.WHETHER_TO_INVITE.haveCloseIcon = false;
      this.JOIN_STATUS = false;
      this.NEED_PWD_INPUT = false;
    },

    /* 上传文件 */
    uploadFile() {
      // 执行文件上传的逻辑，这里可以使用axios或其他HTTP库
      if (this.selectedFile) {
        const formData = new FormData();
        formData.append("spaceId", this.spaceId);
        formData.append("posName", this.posName);
        formData.append("image", this.selectedFile);
        // let config = {
        //   headers: {'Content-Type': 'multipart/form-data'}
        // }
        // this.$axios.post('http://192.168.31.109:7171/UploadRecord', formData, config).then((response) => {})
        var xhr = new XMLHttpRequest();
        // xhr.open("POST", "http://192.168.31.109:8700/UploadRecord", true)
        // xhr.open("POST", "http://47.94.0.238:8700/UploadRecord", true)
        xhr.open(
          "POST",
          "https://meet.mtheworld.com/api/ccenter/UploadRecord",
          true
        );
        var spaceId = this.spaceId;
        var posName = this.posName;
        xhr.onload = function () {
          if (xhr.status === 200) {
            console.log("File uploaded successfully");
            callUnity("ReloadWallTex", { spaceId: spaceId, posName: posName });
          } else {
            console.error("Failed to upload file");
          }
        };
        console.log("uploadFile");
        xhr.send(formData);
        this.selectedFile = null;
        this.$refs.fileInput.value = "";
      }
    },

    handleFileChange() {
      const fileInput = this.$refs.fileInput;
      this.selectedFile = fileInput.files[0];
      this.uploadFile();
    },
  },

  mounted() {
    if (
      this.USERINFO.per &&
      this.USERINFO.per.length > 0 &&
      this.USERINFO.per.indexOf("mland:meet:create") !== -1
    )
      return;
    this.handlePretreatment();
  },

  computed: {
    ...mapState("home", ["USERINFO"]),
    ...mapState("meet", ["MEETING_LIST"]),
    ...mapState("unity", ["METHODS", "DATA"]),
  },

  watch: {
    $route: {
      async handler(n) {
        if (n.params.id && !n.params.no && n.name === "HomeView") {
          // 直接跳转某个场景
          this.$router.replace("/home");
          const spaceId = n.params.id; // 场景id
          // console.log("Set sessionStorage DirSpaceId" + spaceId)
          window.sessionStorage.setItem("DirSpaceId", spaceId)
        } else if (n.params.id && n.params.no && n.name === "HomeView") {
          this.meetId = n.params.id;
          this.meetNo = n.params.no;
          this.JOIN_STATUS = true;
          this.NEED_PWD_INPUT = false;
          this.WHETHER_TO_INVITE.status = true;
          this.WHETHER_TO_INVITE.haveCloseIcon = true;
        }
      },
      immediate: true,
    },

    Panoramic_Map_Status() {
      window.addEventListener("message", (e) => {
        if (this.once && e.data.msg) {
          // console.log(e.data);
          callUnity("msg720", e.data.msg);
          this.go(e.data.msg);
          this.once = false;
          setTimeout(() => {
            this.once = true;
          }, 1000);
        }
      });
    },

    METHODS: {
      handler(n) {
        if (this.METHODS === "") return;
        switch (n) {
          case "MovePanoramic":
            this.show = true;
            break;
          case "CloseglobalLoading":
            this.closeglobalLoading();
            break;
          case "LoadedScene":
            this.closeglobalLoading();
            this.Manage_Meeting = false
            break;
          case "changeRoom":
            this.$store.commit("meet/globalLoading", true);
            break;
          case "Description":
            this.descriptionStatus = true;
            break;
          case "MeetingManage":
            this.Manage_Meeting = true;
            break;
          case "ContactUs":
            this.contactUsStatus = true;
            break;
          case "IndustryChainInfo":
            this.industryName = this.DATA.chainName;
            this.isChainUser = this.DATA.isChainUser;
            this.CHAIN_STATUS = true;
            break;
          case "handleCompanyDetails":
            this.companyCode = this.DATA// 返回来的companyCode
            this.showModal = true
            break;
          case "Help":
            this.$store.commit("home/SET_GUIDE_STATUS");
            break;
          case "File":
            this.meetId = this.DATA.id;
            this.asstEnabled = this.DATA.asstEnabled;
            this.managementFileStatus = true;
            break;
          case "MeetInvite":
            this.meetId = this.DATA;
            this.inviteParticipantStatus = true;
            break;
          case "Unity2H5_Msg720":
            console.log(this.DATA);
            this.msg720data = this.DATA;
            this.Panoramic_Map_Url = this.DATA.Panoramic_Map_Url;
            // this.Panoramic_Map_Url = "http://127.0.0.1:10001"
            this.Panoramic_Map_Status = this.DATA.msg === "closePanoramicMap" ? false : true;
            if (!this.DATA.IAmHost) this.go(this.DATA.msg);
            break;
          case "Single720":
            this.show = true;
            if(this.DATA){
              this.Panoramic_Map_Url = this.DATA
              // this.Panoramic_Map_Url = "http://127.0.0.1:10001"
            }
            break;
          case "Mic":
            this.micStatus = this.DATA;
            break;
          case "Map":
            /* 参观导览 */
            this.swithcSpaceStatus = true;
            break;
          case "CreateConference":
            /* 创建会议 */
            this.CREATE_MEET_STATUS = true;
            this.Registration_Set = true;
            break;
          case "JoinMeeting":
            /* 加入会议 */
            this.meetId = "";
            this.meetNo = "";
            this.JOIN_STATUS = true;
            this.WHETHER_TO_INVITE.status = false;
            this.WHETHER_TO_INVITE.haveCloseIcon = true;
            this.NEED_PWD_INPUT = false;
            break;
          case "PersonalSettings":
            /* 个人设置 */
            this.SHOW_USER = true;
            break;
          case "LogOutOfLogin":
            /* 退出登陆 */
            this.logout();
            break;
          case "MsgSuccess":
            this.$message.success(this.DATA);
            break;
          case "H5Refresh":
            window.location.reload();
            break;
          case "MsgError":
            this.$message.error(this.DATA);
            break;
          case "ReturnToLogin":
            this.$message.error("您的账号在其他地方登录");
            this.returnToLogin();
            break;
          case "HttpMsgError":
            this.$message.error(unescape(this.DATA.msg.replace(/\\u/g, "%u")));
            if (this.DATA.code === 401) {
              this.returnToLogin();
            }
            break;
          case "UploadSpaceTex":
            this.spaceId = this.DATA.spaceId;
            this.posName = this.DATA.posName;
            console.log(this.spaceId);
            console.log(this.posName);
            const fileInput = this.$refs.fileInput;
            fileInput.click();
            break;
        }
        this.$store.commit("unity/SET_UNITY_METHODS", "");
      }
    },
  },
};
</script>
