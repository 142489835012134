<script>
import '@/assets/css/registration-approval.css'
import { getSignCount, signPage, signPass, signReject } from '@/apis/meet'
export default {
  name: "registrationApproval",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    meetingId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      current: 1,
      total: 0,
      mark: null,
      census: {},
      memberData: []
    }
  },

  computed: {
    allSelect: {
      get() {
        return this.memberData.every(v => v.flag)
      },

      set(value) {
        this.memberData.forEach(v => {
          this.$set(v, 'flag', value)
        })
      }
    }
  },

  methods: {
    /* 获取申请报名列表 */
    async getSignPage() {
      const res = await signPage({ pageSize: 5, pageNo: this.current, meetingId: this.meetingId, })
      this.memberData = res.data.list.map(v => {
        return { ...v, flag: false }
      })
      this.total = res.data.total
    },

    /* 获取报名统计人数 */
    async getSignCount() {
      const res = await getSignCount(this.meetingId)
      this.census = res.data
    },

    /* 切换分页 */
    handlePage(current) {
      this.current = current
      this.getSignPage()
    },

    /* 是否选中 */
    changeSelect(data) {
      let target = this.memberData.find(v => v.id === data.id)
      this.$set(target, 'flag', !data.flag)
    },

    /* 批准 */
    async ratify(data) {
      await signPass({ ids: [data.id], meetingId: this.meetingId })
      this.$message.success("已批准通过")
      this.getSignPage()
      this.getSignCount()
    },

    /* 拒绝 */
    async refuse(data) {
      await signReject({ ids: [data.id], meetingId: this.meetingId })
      this.$message.success("已拒绝通过")
      this.getSignPage()
      this.getSignCount()
    },

    /* 批量批准 */
    async batchratify() {
      let ids = []
      this.memberData.forEach(v => {
        if (v.flag) ids.push(v.id)
      })
      if (ids.length < 1) return this.$message.error("请勾选编辑项")
      await signPass({ ids, meetingId: this.meetingId })
      this.$message.success("操作成功")
      this.getSignPage()
      this.getSignCount()
    },

    /* 批量拒绝 */
    async batchrefuse() {
      let ids = []
      this.memberData.forEach(v => {
        if (v.flag) ids.push(v.id)
      })
      if (ids.length < 1) return this.$message.error("请勾选编辑项")
      await signReject({ ids, meetingId: this.meetingId })
      this.$message.success("操作成功")
      this.getSignPage()
      this.getSignCount()
    }
  },

  mounted() {
    this.getSignPage()
    this.getSignCount()
  },
}
</script>

<template>
  <div class="registration-approval" v-if="value">
    <div class="title flex-row">
      <span>报名设置</span>
      <img @click="$emit('input', false)" src="../assets/images/home/dialog-close.png" alt="" />
    </div>
    <div class="top-tip flex-row">
      <div class="left">
        已报名：<span>{{ census.fail + census.pass + census.pending }}</span>人 &emsp; 已批准：<span>{{ census.pass }}</span>人
        &emsp; 待批准：<span>{{ census.pending
        }}</span>人 &emsp;
        已拒绝：<span>{{ census.fail }}</span>人
      </div>
      <div class="right flex-row">
        <div class="handles flex-row" @click="batchratify">
          <img src="@/assets/images/meet/pass.png" alt="">
          <span>批量批准</span>
        </div>
        <div class="handles flex-row" style="margin-left: 20px;" @click="batchrefuse">
          <img src="@/assets/images/meet/pass.png" alt="">
          <span>批量拒绝</span>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="member-table-list">
      <div class="table-title flex-row">
        <div class="check">
          <input type="checkbox" name="" id="" v-model="allSelect">
        </div>
        <div class="name">成员姓名</div>
        <div class="role">当前状态</div>
        <div class="area">报名时间</div>
        <div class="mobile">问卷回复</div>
        <div class="status">审核人</div>
        <div class="user-group">操作</div>
      </div>
      <div class="table-col flex-row" v-for="(item, index) in memberData" :key="index" v-if="memberData.length > 0">
        <div class="check">
          <input type="checkbox" name="" id="" v-model="item.flag" @click="changeSelect(item)">
        </div>
        <div class="name">{{ item.realName ? item.realName : '-' }}</div>
        <div class="role"
          :style="{ 'color': item.auditStatus === 1 ? '#13BD45' : item.auditStatus === 2 ? '#F63F3D' : '#333333' }">{{
            item.auditStatus === 0 ? '待批准' : item.auditStatus === 1 ? '已批准' : item.auditStatus === 2 ?
              '已拒绝' : '-' }}</div>
        <div class="area">{{ item.createTime }}</div>
        <div class="mobile" @mouseenter="mark = index" @mouseleave="mark = null">查看详情
          <div class="info flex-col" @mouseleave="mark = null" :style="{ 'opacity': mark === index ? '1' : '0' }">
            <div class="label">手机号：<span>{{ item.phone ? item.phone : '-' }}</span></div>
            <div class="label">组织名称：<span>{{ item.orgName ? item.orgName : '-' }}</span></div>
          </div>
        </div>
        <div class="status">{{ item.auditor ? item.auditor : '-' }}</div>
        <div class="user-group">
          <el-popconfirm confirm-button-text='是的' cancel-button-text='取消' icon="el-icon-info" icon-color="red"
            title="是否确认通过" @confirm='ratify(item)'>
            <template #reference>
              <span class="pass">批准</span>
            </template>
          </el-popconfirm>
          <el-popconfirm confirm-button-text='是的' cancel-button-text='取消' icon="el-icon-info" icon-color="red"
            title="是否确认拒绝" @confirm='refuse(item)'>
            <template #reference>
              <span class="refuse">拒绝</span>
            </template>
          </el-popconfirm>
        </div>
      </div>
      <div style="text-align: center; margin-top: 80px;" v-if="memberData.length < 1">
        <img src="@/assets/images/meet/qs.png" alt="">
      </div>
    </div>
    <el-pagination style="text-align: right; position: absolute; bottom: 14px; right: 20px;" @current-change="handlePage"
      :current-page.sync="current" :page-size="5" background layout="total, prev, pager, next" :total="total">
    </el-pagination>
  </div>
</template>
