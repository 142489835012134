<script>
export default {
  name: "VenueDecoration",
  props: {
    value: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<template>
  <div class="venue-decoration" v-if="value">
    <div class="title flex-row">
      <span>在线好友</span>
      <img @click="$emit('input', false)" src="../assets/images/home/dialog-close.png" alt="" />
    </div>
    <div class="venue-container">
      <div class="venue-screen flex-row">
        <div class="upload">
          <div class="title">会场屏幕</div>
          <div class="upload-compontent"></div>
          <div class="introduce">参会者进场后会看到会场默认屏幕，你可以上传会场主题海报，固定尺寸：1920*720</div>
        </div>
        <div class="default-pic">
          <img src="@/assets/images/meet/default-pic.png" alt="">
          <div class="btn">使用默认图片</div>
        </div>
      </div>
      <div class="venue-screen flex-row" style="margin-top: 21px;">
        <div class="upload">
          <div class="title">会场屏幕</div>
          <div class="upload-compontent"></div>
          <div class="introduce">参会者进场后会看到会场默认屏幕，你可以上传会场主题海报，固定尺寸：1920*720</div>
        </div>
        <div class="default-pic">
          <img src="@/assets/images/meet/default-pic.png" alt="">
          <div class="btn">使用默认图片</div>
        </div>
      </div>
      <div class="venue-screen flex-row" style="margin-top: 21px;">
        <div class="upload">
          <div class="title">会场屏幕</div>
          <div class="upload-compontent"></div>
          <div class="introduce">参会者进场后会看到会场默认屏幕，你可以上传会场主题海报，固定尺寸：1920*720</div>
        </div>
        <div class="default-pic">
          <img src="@/assets/images/meet/default-pic.png" alt="">
          <div class="btn">使用默认图片</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.venue-decoration {
  width: 1000px;
  height: 800px;
  background-image: linear-gradient(180deg, #DEECFF 0%, #FFFFFF 13%);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px;

  .title {
    font-size: 28px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 25px;
    justify-content: space-between;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .venue-container {
    height: 710px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .venue-screen {
      padding: 17px 24px 18px 20px;
      height: 309px;
      background: #FFFFFF;
      border: 1px solid rgba(195, 212, 230, 1);
      width: 960px;

      .upload {
        flex: 1;

        .title {
          font-size: 20px;
          color: #333333;
          letter-spacing: 0;
          line-height: 30px;
          font-weight: 600;
          margin-bottom: 32px;
        }

        .upload-compontent {
          margin: 0 auto 35px;
          border: 1px solid rgba(151, 151, 151, 1);
          width: 236px;
          height: 120px;
          background-color: #333333;
        }

        .introduce {
          width: 594px;
          font-size: 20px;
          line-height: 30px;
          color: #666666;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      .default-pic {
        width: 290px;

        img {
          width: 290px;
          height: 220px;
        }

        .btn {
          margin: 15px auto 0;
          border: 2px solid rgba(153, 153, 153, 1);
          border-radius: 30px;
          width: 140px;
          height: 36px;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0;
          text-align: center;
          line-height: 30px;
          font-weight: 400;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
