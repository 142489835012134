<template>
  <div class="loading flex-col" v-if="value">
    <div class="mland"></div>
  </div>
</template>
  
<script>
export default {
  name: "Loading",
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
}
</script>
  
<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  background-color: rgba(35, 31, 32, 1);

  .mland {
    width: 411px;
    height: 297px;
    overflow: hidden;
    background-image: url('@/assets/images/login/sprite-map.png');
    animation: run 2s steps(60) 0s infinite both;

    @keyframes run {
      to {
        background-position: -6000% 0
      }
    }
  }
}
</style>
  