<script>
import "@/assets/css/showroom.css";
import { createHall, getHalls, updateStatus } from "@/apis/show";
export default {
  name: "ShowRoom",
  props: {
    value: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      name: "",
      current: 1,
      total: 0,
      flag: false,
      isEdit: false,
      halls: [],
      form: {
        name: "",
        remarkText: "",
      }
    };
  },

  watch: {
    /* 弹窗初始化 */
    value(n) {
      if (n) {
        this.current = 1;
        this.getHalls();
      }
    },
  },

  methods: {
    /* 获取展厅列表 */
    async getHalls() {
      const res = await getHalls({
        pageNo: this.current,
        pageSize: 5,
        name: this.name,
      });
      this.halls = res.data.list;
      this.total = res.data.total;
    },

    /* 分页查询展厅列表 */
    handleCurrentChange(current) {
      this.current = current;
      this.getHalls();
    },

    /* 创建新展厅 */
    createHall() {
      this.isEdit = true;
      this.form = {
        name: "",
        remarkText: "",
      };
    },

    /* 确认创建展厅 */
    async preservation() {
      if (!this.form.name) return this.$message.error("请填写展厅名称");
      await createHall(this.form);
      this.isEdit = false;
      this.getHalls();
    },

    /* 切换展厅的状态 */
    async changeMeetStatus({ status, code, id }) {
      await updateStatus({
        id,
        code,
        status: status ? "FALSE" : "TRUE",
      });
      this.getHalls();
    },
  },
};
</script>

<template>
  <div class="show-room" v-if="value">
    <div class="content" v-if="!isEdit">
      <div class="title flex-row">
        <span>展厅管理</span>
        <img src="../assets/images/home/dialog-close.png" alt="" @click="$emit('input', false)" />
      </div>
      <div class="search flex-row">
        <div class="search-input flex-row">
          <input v-model="name" type="text" placeholder="搜索展厅" />
          <img src="../assets/images/home/search.png" alt="" @click="getHalls()" />
        </div>
        <!-- <div class="mail-list flex-row" @click="createHall">
            <img src="../assets/images/home/increment-card.png" alt="" />
            <span>创建新展厅</span>
          </div> -->
      </div>
      <div class="qs" v-if="halls.length === 0">你还没有创建或加入展厅</div>
      <div class="room-list" v-else>
        <div class="room-item flex-row" v-for="(item, index) in halls" :key="index">
          <div class="logo">
            <img :src="require('@/assets/images/home/room-logo.png')" alt="" />
          </div>
          <div class="room-title">{{ item.name }}</div>
          <div class="handle-group flex-row">
            <div class="handle-item flex-col" @click="changeMeetStatus(item)">
              <img src="../assets/images/home/close-room-active.png" alt="" style="width: 30px; height: 30px" />
              <!-- <img src="../assets/images/home/close-room.png" alt="" style="width: 30px; height: 30px"> -->
              <span>{{ item.status ? "关闭展厅" : "开启展厅" }}</span>
            </div>
            <!-- <div
                    class="handle-item flex-col"
                    @click="$router.push({ name: 'Member' })"
                  >
                    <img
                      src="../assets/images/home/room-user-active.png"
                      alt=""
                      style="width: 25px; height: 30px"
                    /> -->
            <!-- <img src="../assets/images/home/room-user.png" alt="" style="width: 25px; height: 30px"> -->
            <!-- <span>管理成员</span>
                  </div> -->
            <div class="handle-item flex-col" @click="$router.push(`/Show/${item.id}/${item.code}`)">
              <img src="../assets/images/home/room-edit-active.png" alt="" style="width: 30px; height: 30px" />
              <!-- <img src="../assets/images/home/room-edit.png" alt="" style="width: 30px; height: 30px"> -->
              <span>编辑展厅</span>
            </div>
            <div class="handle-item flex-col" @click="$router.push({ name: 'Hall' })">
              <img src="../assets/images/home/join-active.png" alt="" style="width: 30px; height: 30px" />
              <!-- <img src="../assets/images/home/join.png" alt="" style="width: 30px; height: 30px"> -->
              <span>进入展厅</span>
            </div>
          </div>
        </div>
      </div>
      <el-pagination v-if="halls.length > 0" style="text-align: right" @current-change="handleCurrentChange"
        :current-page.sync="current" :page-size="5" background layout="total, prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <div class="content" v-if="isEdit" style="height: 500px">
      <div class="title flex-row">
        <span>展厅管理</span>
        <img @click="isEdit = false" src="../assets/images/home/dialog-close.png" alt="" />
      </div>
      <div class="increment-card flex-row">
        <div class="left-form flex-col">
          <div class="form-item flex-row">
            <span>展厅名称</span>
            <input type="text" v-model="form.name" placeholder="限30个字符" />
          </div>
          <div class="form-item flex-row">
            <span>展厅简介</span>
            <textarea name="" id="" cols="30" rows="10" v-model="form.remarkText" placeholder="限300个字符"></textarea>
          </div>
          <div class="form-item flex-row">
            <span></span>
            <div class="save-cancle flex-row">
              <div class="handle" @click="preservation">保存</div>
              <div class="cancel" @click="isEdit = false">取消</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

