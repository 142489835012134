import { getUserInfo } from '@/apis/user'
import ElementUI from 'element-ui';
import customAxios from 'axios'
export default {
  namespaced: true,
  state: {
    USERINFO: {},
    JRYS_TOKEN: "",
    GUIDE_STATUS: false
  },
  mutations: {
    /* 设置用户信息 */
    SET_USERINFO(state, payload) {
      state.USERINFO = { ...payload }
    },

    /* 设置帮助展示状态 */
    SET_GUIDE_STATUS(state) {
      state.GUIDE_STATUS = !state.GUIDE_STATUS
    },

    /* 设置今日园商Token */
    SET_JRYS_TOKEN(state, TOKEN) {
      state.JRYS_TOKEN = TOKEN
    },

    /* 重置home数据 */
    RESET_STATE(state) {
      state.USERINFO = {}
      state.JRYS_TOKEN = ''
      state.GUIDE_STATUS = false
    }
  },
  actions: {
    /* 获取个人信息 */
    getUserInfoAction({ commit }) {
      return new Promise((resolve) => {
        getUserInfo().then(res => {
          commit('SET_USERINFO', res.data)
          resolve(res.data)
        })
      })
    },

    /* 获取今日园商token */
    getJrysToken({ commit }) {
      return new Promise((resolve, reject) => {
        customAxios.post(`https://zcjrys.chinaindustria.com/api/auth/union/login/v2`, {
          account: "13888880201",
          code: "",
          password: "52f43befdbd99c3d865534a3a588b34e",
          type: "JRYS"
        }).then(res => {
          commit("SET_JRYS_TOKEN", res.data.data.token)
          resolve(res.data.data.token)
        }).catch(err => {
          ElementUI.Message.error("请稍后再试")
          reject(err)
        })
      })
    }
  }
};
