export const mixins = {
  methods: {
    closeglobalLoading() {
      setTimeout(() => {
        this.$store.commit('meet/globalLoading', false)
      }, 2000)
    },

    returnToLogin() {
      window.sessionStorage.clear()
      setTimeout(() => {
        window.location = "/";
      }, 1000);
    }
  }
}
