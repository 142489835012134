
<template>
  <div class="header flex-row">
    <div class="logo">
      <img src="@/assets/images/meet/m-land-logo.png" alt="" />
    </div>
    <div class="navbar flex-row">
      <div class="nav-list flex-row">
        <div v-if="!hide" class="nav-item flex-row" @click="() => {
          CREATE_MEET_STATUS = true;
          Registration_Set = true;
        }
          ">
          <img src="@/assets/images/meet/create-meet.png" alt="" />
          创建会议
        </div>
        <div v-if="!hide" class="nav-item flex-row" @click="() => {
          $parent.JOIN_STATUS = true;
          $parent.WHETHER_TO_INVITE = false;
          $parent.NEED_PWD_INPUT = false;
          $parent.meetId = '';
          $parent.meetNo = '';
        }
          ">
          <img src="@/assets/images/meet/invite.png" alt="" />
          加入会议
        </div>
        <!-- <div class="nav-item flex-row" @click="SHOW_FRIEND = true">
            <img src="@/assets/images/meet/friend.png" alt="">
            好友
          </div> -->
        <!-- <div class="nav-item flex-row" @click="SHOW_MESSAGE = true">
            <img src="@/assets/images/meet/message.png" alt="">
            消息
          </div> -->
        <!-- <div class="message" v-if="count">
          {{ count }}
        </div> -->
        <div class="avatar" @mouseenter="show = true">
          <img :src="avatar1[USERINFO.vrProfile - 1]" alt="" v-if="USERINFO.sex === 1" />
          <img :src="avatar0[USERINFO.vrProfile - 1]" alt="" v-else />
        </div>
        <span @mouseenter="show = true">{{ USERINFO.nickname }}</span>
      </div>
    </div>
    <div class="dropdown flex-col" v-show="show" @mouseleave="show = false">
      <div class="cursor item flex-row" @click="SHOW_USER = true">
        <img src="@/assets/images/meet/setting.png" alt="" />
        <span>个人设置</span>
      </div>
      <div class="cursor item flex-row" @click="logout">
        <img src="@/assets/images/meet/logout.png" alt="" />
        <span>退出登录</span>
      </div>
    </div>

    <!-- 我的消息 -->
    <!-- <Layout v-model="SHOW_MESSAGE" :zIndex="1002">
      <Mymessage :show="SHOW_MESSAGE" @updateMessage="() => SHOW_MESSAGE = false"></Mymessage>
    </Layout> -->

    <!-- 在线好友 -->
    <!-- <Layout v-model="SHOW_FRIEND" :zIndex="1002">
      <OnlineFriend v-model="SHOW_FRIEND" />
    </Layout> -->

    <!-- 修改个人资料 -->
    <Layout v-model="SHOW_USER" :z-index="1002">
      <conversion-profile v-model="SHOW_USER"></conversion-profile>
    </Layout>

    <!-- 创建会议 -->
    <Layout v-model="CREATE_MEET_STATUS" :z-index="1002">
      <create-conference v-model="CREATE_MEET_STATUS"
        @After_successful_creation="After_successful_creation"></create-conference>
    </Layout>

    <!-- 邀请成员 -->
    <Layout v-model="INVITE_STATUS" :z-index="1002">
      <inviteParticipants v-model="INVITE_STATUS" :meetId="meetId" :Registration_Set="Registration_Set"
        @updateRegistration_Set="(data) => (Registration_Set = data)"></inviteParticipants>
    </Layout>
  </div>
</template>

<script>
import { signout } from "@/apis/auth";
import { mapState } from "vuex";
import { getPendingCount } from "@/apis/message";
import { storage } from "@/assets/js/index";
import "@/assets/css/header.css";
export default {
  name: "HeaderPage",

  data() {
    return {
      count: 0,
      show: false,
      hide: false,
      SHOW_USER: false,
      SHOW_FRIEND: false,
      SHOW_MESSAGE: false,
      CREATE_MEET_STATUS: false,
      INVITE_STATUS: false,
      Registration_Set: true,
      meetId: "",
      avatar1: [
        require("@/assets/images/home/male-1.png"),
        require("@/assets/images/home/male-2.png"),
        require("@/assets/images/home/male-3.png"),
        require("@/assets/images/home/male-4.png"),
        require("@/assets/images/home/male-5.png"),
      ],
      avatar0: [
        require("@/assets/images/home/female-1-bg.png"),
        require("@/assets/images/home/female-2-bg.png"),
        require("@/assets/images/home/female-3-bg.png"),
        require("@/assets/images/home/female-4-bg.png"),
      ],
    };
  },

  mounted() {
    this.$nextTick(async () => {
      const res = await getPendingCount();
      this.count = res.data.count;
    });
  },

  computed: {
    ...mapState("home", ["USERINFO"]),
  },

  methods: {
    async logout() {
      const token = storage.get("token");
      await signout({ account: token.account });
      window.sessionStorage.clear();
      this.$router.push("/");
      window.location.reload();
    },

    After_successful_creation(meetId) {
      this.meetId = meetId;
      this.INVITE_STATUS = true;
      this.CREATE_MEET_STATUS = false;
    },
  },
};
</script>
