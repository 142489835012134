<script>
import "@/assets/css/enroll-setting.css";
import {
  getSignSettingInfo,
  signSettingSave,
  signSettingUpdate,
} from "@/apis/meet";
import { objToStr } from "@/assets/js/util";
export default {
  name: "EnrollSetting",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    /* 会议设置ID */
    signSettingId: {
      type: String,
      required: true,
    },
    /* 会议ID */
    meetingId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signData: {
        maxLimit: "",
        noticeType: false,
        auditType: "AUTO",
        isSurvey: "FALSE",
        question: false,
        survey: false,
        registDeadline: "0",
        isSign: false,
      },

      requireData: {
        mobile: { need: false, require: false },
        code: { need: false, require: false },
        name: { need: false, require: false },
        mail: { need: false, require: false },
        organization: { need: false, require: false },
        other: { need: false, require: false },
      },
    };
  },
  methods: {
    /* 获取当前会议报名的设置 */
    async getSignSettingInfo() {
      try {
        const res = await getSignSettingInfo(this.signSettingId);
        this.signData.id = res.data.id;
        this.signData.maxLimit = res.data.maxLimit - 0;
        this.signData.noticeType = res.data.noticeType === 0 ? true : false;
        this.signData.auditType = res.data.auditType === 0 ? "AUTO" : "MANUAL";
        this.signData.isSurvey = "FALSE";
        this.signData.isSign = res.data.isSign === 1 ? true : false;
        this.signData.registDeadline =
          res.data.registDeadline === 0 ? "0" : "1";

        /* 处理左侧表单回显 */
        if (res.data.survey) {
          let obj = {};
          let array = res.data.survey.split("&");
          array.forEach((v) => {
            let item = v.split("-");
            obj[item[0]] = {};
            obj[item[0]] = {
              need: item[1] === "true" ? true : false,
              require: item[2] === "true" ? true : false,
            };
          });
          this.requireData = { ...obj };
          for (let k in this.requireData) {
            if (this.requireData[k]?.need && this.requireData[k].require) {
              this.signData.question = true;
              break;
            }
          }
        } else {
          this.signData.question = false;
          this.requireData = {
            mobile: { need: false, require: false },
            code: { need: false, require: false },
            name: { need: false, require: false },
            mail: { need: false, require: false },
            organization: { need: false, require: false },
            other: { need: false, require: false },
          };
        }
      } catch (e) {
        return false;
      }
    },

    /* 修改是否必填 */
    changeRequire(string) {
      if (this.requireData[string].require) {
        this.requireData[string].need = true;
      }
    },

    /* 修改左侧项 */
    changeNeed(string) {
      if (!this.requireData[string].need) {
        this.requireData[string].require = false;
      }
    },

    /* 修改是否开启报名问卷 */
    changeSignStatus() {
      if (!this.signData.question) {
        for (let k in this.requireData) {
          this.requireData[k].need = false;
          this.requireData[k].require = false;
        }
      }
    },

    /* 设置 or 修改 */
    async allSave() {
      let flag = false;
      if (this.signData.question) {
        for (let k in this.requireData) {
          if (this.requireData[k].need && this.requireData[k].require) {
            flag = true;
            break;
          }
        }
      }
      let reqParams = {
        id: this.signData.id ? this.signData.id : "",
        auditType: this.signData.auditType,
        isSign: this.signData.isSign ? "TRUE" : "FALSE",
        isSurvey: "FALSE",
        maxLimit: this.signData.maxLimit - 0,
        meetingId: this.meetingId,
        registDeadline: this.signData.registDeadline,
        noticeType: this.signData.noticeType ? 0 : 4,
      };

      if (this.signData.question) {
        if (flag) {
          let survey = objToStr(this.requireData);
          this.signData.id
            ? await signSettingUpdate({ ...reqParams, survey })
            : await signSettingSave({ ...reqParams, survey });
          this.$message.success("设置成功");
          this.$emit("input", false);
        } else {
          this.$message.error("右侧表单必须至少勾选一项");
        }
      } else {
        this.signData.id
          ? await signSettingUpdate({ ...reqParams, survey: "" })
          : await signSettingSave({ ...reqParams, survey: "" });
        this.$message.success("设置成功");
        this.$emit("input", false);
      }
    },
  },

  mounted() {
    this.getSignSettingInfo();
  },
};
</script>

<template>
  <div class="enroll-setting" v-if="value">
    <div class="title flex-row">
      <span>报名设置</span>
      <img
        @click="$emit('input', false)"
        src="../assets/images/home/dialog-close.png"
        alt=""
      />
    </div>
    <div class="enroll-setting-form">
      <div class="enroll-form">
        <div class="form-item">
          <span class="form-label">人数限制：</span>
          <input type="number" v-model="signData.maxLimit" />
        </div>
        <div class="form-item" style="margin-top: 38px">
          <span class="form-label">截止时间：</span>
          <input
            type="radio"
            name="endtime"
            id="endtime"
            v-model="signData.registDeadline"
            value="0"
          />
          <label for="endtime">会议开始即截止</label>
        </div>
        <div class="form-item">
          <span class="form-label"></span>
          <input
            type="radio"
            name="endtime"
            id="custom"
            v-model="signData.registDeadline"
            value="1"
          />
          <label for="custom">会议结束即截止</label>
        </div>
        <div class="form-item" style="margin-top: 20px">
          <span class="form-label">报名审批：</span>
          <input
            type="radio"
            name="approve"
            id="autoApprove"
            v-model="signData.auditType"
            value="AUTO"
          />
          <label for="autoApprove">自动批准</label>
          <input
            type="radio"
            name="approve"
            id="manualApprove"
            v-model="signData.auditType"
            value="MANUAL"
            style="margin-left: 10px"
          />
          <label for="manualApprove">手动审批</label>
        </div>
        <div class="form-item" style="margin-top: 20px">
          <span class="form-label">报名页：</span>
          <input
            type="checkbox"
            name="show"
            id="show"
            v-model="signData.isSign"
          />
          <label for="show">显示已报名/预约人数</label>
        </div>
        <div class="form-item" style="margin-top: 20px">
          <span class="form-label">报名通知：</span>
          <input
            type="checkbox"
            name="notice"
            id="notice"
            v-model="signData.noticeType"
          />
          <label for="notice">短信</label>
        </div>
      </div>
      <div class="question">
        <div class="main-header">
          <span>报名问卷</span>
          <input
            v-model="signData.question"
            @change="changeSignStatus"
            type="checkbox"
            name=""
            id="open"
          />
          <label for="open">{{ signData.question ? "开启" : "关闭" }}</label>
        </div>
        <div class="question-table">
          <div class="question-table-header">
            <div class="left">项目</div>
            <div class="right">是否必填</div>
          </div>
          <div class="question-item">
            <div class="left">
              <input
                type="checkbox"
                @change="changeNeed('mobile')"
                name=""
                id="mobile"
                v-model="requireData.mobile.need"
                :disabled="!signData.question"
              />
              <label for="mobile">手机号</label>
            </div>
            <div class="require">
              <input
                type="checkbox"
                name=""
                id=""
                :disabled="!signData.question"
                @change="changeRequire('mobile')"
                v-model="requireData.mobile.require"
              />
            </div>
          </div>
          <div class="question-item">
            <div class="left">
              <input
                type="checkbox"
                @change="changeNeed('code')"
                name=""
                id="code"
                :disabled="!signData.question"
                v-model="requireData.code.need"
              />
              <label for="code">发送验证码</label>
            </div>
            <div class="require">
              <input
                type="checkbox"
                name=""
                id=""
                :disabled="!signData.question"
                @change="changeRequire('code')"
                v-model="requireData.code.require"
              />
            </div>
          </div>
          <div class="question-item">
            <div class="left">
              <input
                type="checkbox"
                @change="changeNeed('name')"
                name=""
                id="name"
                :disabled="!signData.question"
                v-model="requireData.name.need"
              />
              <label for="name">姓名</label>
            </div>
            <div class="require">
              <input
                type="checkbox"
                name=""
                id=""
                :disabled="!signData.question"
                @change="changeRequire('name')"
                v-model="requireData.name.require"
              />
            </div>
          </div>
          <div class="question-item">
            <div class="left">
              <input
                type="checkbox"
                @change="changeNeed('mail')"
                name=""
                id="email"
                :disabled="!signData.question"
                v-model="requireData.mail.need"
              />
              <label for="email">邮箱</label>
            </div>
            <div class="require">
              <input
                type="checkbox"
                name=""
                id=""
                :disabled="!signData.question"
                @change="changeRequire('mail')"
                v-model="requireData.mail.require"
              />
            </div>
          </div>
          <div class="question-item">
            <div class="left">
              <input
                type="checkbox"
                @change="changeNeed('organization')"
                name=""
                id="organization"
                :disabled="!signData.question"
                v-model="requireData.organization.need"
              />
              <label for="organization">组织名称</label>
            </div>
            <div class="require">
              <input
                type="checkbox"
                name=""
                id=""
                :disabled="!signData.question"
                @change="changeRequire('organization')"
                v-model="requireData.organization.require"
              />
            </div>
          </div>
          <div class="question-item">
            <div class="left">
              <input
                type="checkbox"
                name=""
                @change="changeNeed('other')"
                id="other"
                :disabled="!signData.question"
                v-model="requireData.other.need"
              />
              <label for="other">其他</label>
            </div>
            <div class="require">
              <input
                type="checkbox"
                name=""
                id=""
                :disabled="!signData.question"
                @change="changeRequire('other')"
                v-model="requireData.other.require"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btns flex-row">
      <div class="btn" style="margin-right: 20px" @click="allSave">
        全部保存
      </div>
      <div
        class="btn"
        style="color: #999; border-color: #999"
        @click="$emit('input', false)"
      >
        取消
      </div>
    </div>
  </div>
</template>
