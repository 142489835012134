import _axios from '../utils/https'

/**
 * 获取用户信息
 * @returns 
 */
export const getUserInfo = () => {
    return _axios({
        url: `/ml/user/info`,
    })
}


/**
 * 修改[用户昵称-头像]
 * @returns 
 */
export const changeAvatarOrNickName = (data) => {
    return _axios({
        url: `/ml/user/update`,
        method: "post",
        data
    })
}


/**
 * 修改密码
 * @param {*} data 
 * @returns 
 */
export const changePassword = (data) => {
    return _axios({
        url: `/ml/user/update/pwd`,
        method: "post",
        data
    })
}

/**
 * 提交商业合作
 * @param {*} data 
 */
export const createBusinessCollaboration = (data) => {
    return _axios({
        url: "/ml/user/demand/save",
        method: "post",
        data
    })
}
