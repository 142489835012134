<style lang="scss" scoped>
.enroll {
  background-image: linear-gradient(180deg, #deecff 0%, #ffffff 16%);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 1000px;
  height: 600px;
  padding: 20px 30px;
  position: relative;

  .title {
    font-size: 28px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
    justify-content: space-between;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .invite-title {
    margin-top: 23px;
    margin-bottom: 20px;
    font-size: 30px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }

  .label-item {
    align-items: baseline;

    span {
      width: 100px;
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      line-height: 40px;
      font-weight: 400;
      text-align: justify;
      text-align-last: justify;
    }

    .describeText {
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      line-height: 40px;
      font-weight: 400;
    }
  }

  .btn {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid #005ad5;
    border-radius: 30px;
    width: 140px;
    height: 40px;
    font-size: 20px;
    color: #005ad5;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }
  }
}

.question {
  background-image: linear-gradient(180deg, #deecff 0%, #ffffff 16%);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 1000px;
  height: 700px;
  padding: 20px 30px;
  position: relative;

  .title {
    font-size: 28px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
    justify-content: space-between;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .describeText {
    margin-top: 22px;
    margin-bottom: 46px;
    font-size: 30px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }

  .form {
    align-items: center;

    .form-item {
      align-items: center;
      margin-bottom: 30px;

      span {
        width: 80px;
        font-size: 20px;
        color: #666666;
        letter-spacing: 0;
        text-align: right;
        font-weight: 400;
        margin-right: 20px;
      }

      input {
        padding-left: 10px;
        border: 1px solid rgba(153, 153, 153, 1);
        border-radius: 4px;
        outline: none;
        width: 500px;
        height: 44px;
      }

      a {
        margin-left: 10px;
        width: 148px;
        height: 44px;
        line-height: 40px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        text-decoration: none;
        border: 1px solid rgba(16, 100, 215, 1);
        border-radius: 4px;
        font-size: 20px;
        color: #1366d8;
      }
    }
  }

  .btn {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid #005ad5;
    border-radius: 30px;
    width: 140px;
    height: 40px;
    font-size: 20px;
    color: #005ad5;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>


<template>
  <div class="enroll-box" v-if="show">
    <div class="enroll" v-if="WHETHER_TO_INVITE.status">
      <div class="title flex-row">
        <span>参加会议</span>
        <img src="../assets/images/home/dialog-close.png" alt="" @click="$emit('closeAllContent', false)" />
      </div>

      <div class="invite-title">
        {{ `${inviteInfo.creator} 邀请您参加会议` }}
      </div>
      <div class="label-item flex-row">
        <span>会议号：</span>
        <div class="describeText">{{ inviteInfo.no }}</div>
      </div>
      <div class="label-item flex-row">
        <span>会议主题：</span>
        <div class="describeText">{{ inviteInfo.topic }}</div>
      </div>
      <div class="label-item flex-row">
        <span>会议时间：</span>
        <div class="describeText">
          {{ inviteInfo.meetingDate }} &emsp; {{ inviteInfo.beginTime }}
        </div>
      </div>
      <div class="label-item flex-row">
        <span>报名人数：</span>
        <div class="describeText">{{ inviteInfo.showSignCount }}人已报名</div>
      </div>
      <div class="btn" @click="WHETHER_TO_INVITE.status = false">报名参会</div>
    </div>

    <div class="question" v-else>
      <div class="title flex-row">
        <span>入会问卷</span>
      </div>
      <div class="describeText">在完成报名前，主持人希望您能填写以下问题</div>
      <div class="form flex-col">
        <div class="form-item flex-row" v-if="constraint.mobile.need">
          <span>手机号</span>
          <input type="number" v-model="dataForm.phone" />
        </div>
        <div class="form-item flex-row" v-if="constraint.code.need">
          <span>验证码</span>
          <input type="number" style="width: 342px" v-model="dataForm.code" />
          <a href="javascript:void(0);" @click="getCode" v-if="showcode">获取验证码</a>
          <a href="javascript:void(0);" v-else>{{ count }}秒重发</a>
        </div>
        <div class="form-item flex-row" v-if="constraint.name.need">
          <span>姓名</span>
          <input type="text" v-model="dataForm.realName" />
        </div>
        <div class="form-item flex-row" v-if="constraint.mail.need">
          <span>邮箱</span>
          <input type="text" v-model="dataForm.email" />
        </div>
        <div class="form-item flex-row" v-if="constraint.organization.need">
          <span>组织名称</span>
          <input type="text" v-model="dataForm.orgName" />
        </div>
        <div class="form-item flex-row" v-if="constraint.other.need">
          <span>其他</span>
          <input type="text" v-model="dataForm.remark" />
        </div>
      </div>
      <div class="btn" @click="submitDataForm">提交</div>
    </div>
  </div>
</template>

<script>
import { signMeeting } from "@/apis/meet";
import { sendSms, validateSms } from "@/apis/auth";
import { isMobile, callUnity } from "@/assets/js/util";
import { getConstraint, getInviteInfo } from "@/apis/meet";
export default {
  name: "Enroll",
  inject: ["WHETHER_TO_INVITE"],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    meetNo: {
      type: String,
      required: true,
    },
    meetId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      count: 0,
      showcode: true,
      timer: null,
      dataForm: {
        phone: "",
        code: "",
        email: "",
        orgName: "",
        realName: "",
        remark: "",
      },
      inviteInfo: {},
      constraint: {
        mobile: { need: false, require: false },
        code: { need: false, require: false },
        name: { need: false, require: false },
        mail: { need: false, require: false },
        organization: { need: false, require: false },
        other: { need: false, require: false },
      },
    };
  },

  mounted() {
    this.getConstraint();
    this.getInviteInfo();
  },

  methods: {
    /* 获取邀请条件 */
    async getInviteInfo() {
      const res = await getInviteInfo(this.meetNo);
      this.inviteInfo = res.data;
    },

    /* 获取会议约束条件 */
    async getConstraint() {
      const res = await getConstraint({ meetNo: this.meetNo });
      let obj = {};
      let array = res.data.survey.split("&");
      array.forEach((v) => {
        let item = v.split("-");
        obj[item[0]] = {};
        obj[item[0]] = {
          need: item[1] === "true" ? true : false,
          require: item[2] === "true" ? true : false,
        };
      });
      this.constraint = { ...obj };
    },

    /* 获取验证码 */
    async getCode() {
      if (!this.dataForm.phone) return this.$message.error("请填写手机号");
      if (!isMobile(this.dataForm.phone))
        return this.$message.error("当前手机号不符合规则");
      const res = await sendSms({ account: this.dataForm.phone });
      this.$message.success("发送验证码成功");
      const timeCount = 60;
      if (!this.timer) {
        this.count = timeCount;
        this.showcode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= timeCount) {
            this.count--;
          } else {
            this.showcode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      this.dataForm.code = JSON.parse(res.data.msg).code;
    },

    /* 提交表单 */
    async submitDataForm() {
      if (this.constraint.mobile.require && !this.dataForm.phone)
        return this.$message.error("手机号为必填项");
      if (this.constraint.code.require && !this.dataForm.code)
        return this.$message.error("验证码为必填");
      if (this.constraint.mail.require && !this.dataForm.email)
        return this.$message.error("邮箱为必填项");
      if (this.constraint.name.require && !this.dataForm.realName)
        return this.$message.error("姓名为必填项");
      if (this.constraint.other.require && !this.dataForm.remark)
        return this.$message.error("其他为必填项");
      if (this.constraint.organization.require && !this.dataForm.orgName)
        return this.$message.error("组织名称为必填项");
      if (this.constraint.code.need) {
        try {
          if (!this.dataForm.code) return this.$message.error("请输入验证码");
          await validateSms({
            account: this.dataForm.phone,
            code: this.dataForm.code,
          });
          await signMeeting({ ...this.dataForm, meetNo: this.meetNo });
          this.$message.success("操作成功");
          callUnity("RefreshAllUI", {});
          this.$emit("closeAllContent", true);
          // this.$store.dispatch("meet/getMeetingPage");
        } catch (e) {
          this.$emit("closeAllContent", true);
        }
      } else {
        try {
          await signMeeting({ ...this.dataForm, meetNo: this.meetNo });
          this.$message.success("操作成功");
          this.$emit("closeAllContent", true);
          callUnity("RefreshAllUI", {});
          // this.$store.dispatch("meet/getMeetingPage");
        } catch (e) {
          this.$emit("closeAllContent", true);
        }
      }
    },
  },

  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

