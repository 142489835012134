<script>
import "@/assets/css/chain.css";

export default {
  name: "Chain",
  props: {
    value: {
      required: true,
      default: false,
    },

    /* 行业名字 */
    industryName: {
      type: String,
      required: true,
    },
    isChainUser: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      css: "",
      svg: "",
      currentIndustry: "",
      category: "优质企业",
      companyType: "",
      companyName: "",
      contactDialog: false,
      currPage: 1,
      show: false,
      showModal: false,
      info: {},
      unlockInfo: {},
      twoVersion: [
        "新能源汽车",
        "家电",
        "食品",
        "人工智能",
        "元宇宙",
        "汽车制造",
      ],
      technology: [],
      suppliers: [],
      companyList: [],
      totalPage: 0,
      technologyCount: 0,
      suppliersCount: 0,
      honorCount: 0,
      highQuality: 0,
    };
  },

  methods: {
    chainDetails(e) {
      console.log(e);
      if (e.target.classList.length > 3) {
        this.show = true;
        this.currentIndustry = e.target.id;
        this.getCompanyNumber();
        this.getCompanyList("优质企业", "highQuality");
        if (document.getElementById("tips")) {
          document
            .getElementById("tips")
            .parentNode.removeChild(document.getElementById("tips"));
        }
      }
    },

    // 选择公司展示详情
    selectCompany(data) {
      if(!this.isChainUser) return this.$message.error("暂无权限，请联系管理员")
      const companyCode = data.companyCode
      this.$emit('handleCompanyDetails', companyCode)
    },

    getCompanyNumber() {
      this.$axios
        .post(
          `https://zcjrys.chinaindustria.com/api/jrys/regional/chain/industry/count`,
          {
            tag: this.currentIndustry,
          }
        )
        .then((res) => {
          if (res.data && res.data.chains) {
            this.info = res.data;
            if (res.data.chains.length) {
              this.info.chainNameList = res.data.chains.filter((i) => {
                return i.status;
              });
            }
            (this.technologyCount = 0), (this.suppliersCount = 0);
            this.technology = res.data.technology;
            this.suppliers = res.data.suppliers;
            if (this.technology.length) {
              this.technologyCount = this.technology.find(
                (i) => i.name == "总数"
              ).quantity;
              this.technology.pop();
            }
            if (this.suppliers.length) {
              this.suppliersCount = this.suppliers.find(
                (i) => i.name == "总数"
              ).quantity;
              this.suppliers.pop();
            }
            this.honorCount = res.data.honorCount;
            this.highQuality = res.data.highQuality;
          }
        });
    },

    // 选择企业类型 num只针对资本属性的空数据
    chooseCategory(name, type, num) {
      if (num == 0) return;
      this.currPage = 1;
      this.category = name;
      this.getCompanyList(name, type);
    },

    getCompanyList(name, type) {
      let params = {};
      this.companyType = type;
      // 资本分类
      if (typeof type == "number") {
        params = {
          market: type,
          tag: this.currentIndustry,
        };
        // 资质分类
      } else if (type == "technology") {
        params = {
          technology: name == "科技型企业" ? "ALL" : name,
          tag: this.currentIndustry,
        };
      } else if (type == "supplier") {
        params = {
          supplier: name == "优质供应商" ? "ALL" : name,
          tag: this.currentIndustry,
        };
      } else if (type == "honor") {
        params = {
          honor: name == "榜单企业" ? "ALL" : name,
          tag: this.currentIndustry,
        };
      } else if (type == "highQuality") {
        params = {
          highQuality: name == "优质企业" ? "ALL" : name,
          tag: this.currentIndustry,
        };
      } else if (type == "highQuality") {
        params = {
          highQuality: name == "优质企业" ? "ALL" : name,
          industry2: this.currentIndustry,
        };
      }
      this.$axios
        .post(
          `https://zcjrys.chinaindustria.com/api/jrys/regional/chain/industry/page?currPage=${this.currPage}&pageSize=20`,
          params
        )
        .then((res) => {
          this.companyList = res.data.list;
          this.totalPage = res.data.totalPage;
        });
    },

    debounce(fn, delay) {
      var timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn.apply(this, args);
        }, delay);
      };
    },

    mouseLeave() {
      if (document.getElementById("tips")) {
        document
          .getElementById("tips")
          .parentNode.removeChild(document.getElementById("tips"));
      }
    },

    addSheetFile() {
      this.removeCss();
      var fileref = document.createElement("link");
      fileref.rel = "stylesheet";
      fileref.type = "text/css";
      fileref.href = this.css;
      fileref.media = "screen";
      var headobj = document.getElementsByTagName("head")[0];
      headobj.appendChild(fileref);
    },

    handleMainPage(value) {
      if (this.currPage == value) return;
      if (value > this.totalPage) return;
      this.currPage = value;
      this.getCompanyList(this.category, this.companyType);
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },

    // 删除样式
    removeCss() {
      var links = document.getElementsByTagName("link");
      for (var i = 0; i < links.length; i++) {
        if (
          links[i].href.indexOf("bigscreenvideo") > -1 ||
          links[i].href.indexOf("chinain-pic") > -1
        ) {
          links[i].parentNode.removeChild(links[i]);
        }
      }
    },

    /* 解锁联系方式 */
    unlockContact(companyName, companyCode) {
      this.companyName = companyName;
      this.unlockInformation(companyCode);
      this.$axios
        .get(
          `https://zcjrys.chinaindustria.com/api/jrys/uc/intention/company/person/count/${companyCode}`
        )
        .then((res) => {
          this.unlockInfo = res.data;
          this.contactDialog = true;
        });
    },

    // 解锁信息 2-解锁
    unlockInformation(companyCode) {
      let params = {
        companyNumber: companyCode,
        unlockStatus: 2,
      };
      this.$axios
        .post(
          `https://zcjrys.chinaindustria.com/api/jrys/uc/intention/company/save`,
          params
        )
        .then(() => { });
    },

    createWatermark() {
      const mobile =
        window.sessionStorage.getItem("token") &&
        JSON.parse(window.sessionStorage.getItem("token")).account;
      const waterMobile = mobile.substr(mobile.length - 4);
      const angle = -12;
      const txt = `M-Land ${waterMobile} ${this.$store.state.home.USERINFO.nickname}`;
      const canvas = document.createElement("canvas");
      canvas.width = 300;
      canvas.height = 250;

      const ctx = canvas.getContext("2d");
      // 清除默认内容
      ctx.clearRect(0, 0, 200, 100);
      ctx.fillStyle = "#000";
      ctx.font = "16px Microsoft YaHei";
      ctx.globalAlpha = 0.1;
      ctx.rotate((Math.PI / 180) * angle);
      // 设置内容需要放到最后，否则会失效
      ctx.fillText(txt, 0, 50);
      return canvas.toDataURL();
    },
  },

  beforeUpdate() {
    if (document.getElementById("tips")) {
      document
        .getElementById("tips")
        .parentNode.removeChild(document.getElementById("tips"));
    }
  },

  beforeDestroy() {
    this.removeCss();
  },

  mounted() {
    this.createWatermark();

    const watermark = document.createElement("div");
    const chainDom = document.querySelector(".chain");
    watermark.className = "watermark";
    watermark.style.backgroundImage = `url(${this.createWatermark()})`;
    chainDom.appendChild(watermark);
    // 设置需要观察的元素
    const targetNode = chainDom;
    // 设置观察器的回调函数
    const callback = function (mutationList) {
      for (let mutation of mutationList) {
        // removedNodes被删除的dom元素列表
        mutation.removedNodes.forEach((item) => {
          // watermark为水印元素
          if (item === watermark) {
            // 如果水印元素被删除，就再添加回被观察元素中
            targetNode.appendChild(item);
          }
        });
      }
    };
    // 设置观察器的配置
    const config = {
      attrubutes: true, // 属性变动是否会触发
      subtree: true, // 后代元素变动是否会触发
      childList: true, // 子元素变动是否会触发
    };
    // 创建一个 mutationObserver 观察器
    const observer = new MutationObserver(callback);
    // 给观察器添加配置
    observer.observe(targetNode, config);

    this.$nextTick(() => {
      let version = this.twoVersion.includes(this.industryName) ? "2" : "1";
      this.$axios
        .get(
          `https://zcjrys.chinaindustria.com/api/jrys/common/chain/getSvg/${this.industryName}?v=${version}`
        )
        .then((res) => {
          this.css = res.data.chainCss;
          this.svg = res.data.chainPic;
          this.addSheetFile();
          let test = document.getElementById("svg");
          test &&
            test.addEventListener(
              "mouseover",
              this.debounce(this.mouseOver, 200)
            );

          const el = document.querySelector("#svg");
          if (el) {
            setTimeout(() => {
              const circleX =
                document.querySelector("#椭圆形").cx.baseVal.value;
              const circleY =
                document.querySelector("#椭圆形").cy.baseVal.value;
              el.scrollTop = circleY - 400;
              el.scrollLeft = circleX - 800;
            }, 1000);
          }
        });
    });
  },
};
</script>

<template>
  <div class="chain">
    <div class="chain-header">
      <el-page-header v-if="show" @back="show = false" title="返回" />
      <img v-else style="width: 24px; height: 24px" src="@/assets/images/meet/close.png" alt=""
        @click="$emit('input', false)" />
    </div>

    <div v-if="!show" class="svg" id="svg" ref="svg" v-html="svg" @mouseleave="mouseLeave" @click="chainDetails"></div>
    <div v-else class="data">
      <div class="category">
        <div class="flex-row">
          <span class="category-left">按资本属性：&emsp;</span>
          <div :class="info.companyCount && info.companyCount.aaCount == 0
            ? 'category-no'
            : category == 'A股公司'
              ? 'category-active'
              : 'category-item'
            " @click="
    chooseCategory(
      'A股公司',
      1,
      info.companyCount && info.companyCount.aaCount
    )
    ">
            A股公司
            <span class="category-num">{{
              info.companyCount && info.companyCount.aaCount
            }}</span>
          </div>
          <span class="split">|</span>
          <div :class="info.companyCount && info.companyCount.threeCount == 0
                ? 'category-no'
                : category == '三板公司'
                  ? 'category-active'
                  : 'category-item'
              " @click="
      chooseCategory(
        '三板公司',
        2,
        info.companyCount && info.companyCount.threeCount
      )
      ">
            三板公司
            <span class="category-num">{{
              info.companyCount && info.companyCount.threeCount
            }}</span>
          </div>
          <span class="split">|</span>
          <div :class="info.companyCount && info.companyCount.fourCount == 0
                ? 'category-no'
                : category == '四板公司'
                  ? 'category-active'
                  : 'category-item'
              " @click="
      chooseCategory(
        '四板公司',
        3,
        info.companyCount && info.companyCount.fourCount
      )
      ">
            四板公司
            <span class="category-num">{{
              info.companyCount && info.companyCount.fourCount
            }}</span>
          </div>
          <span class="split">|</span>
          <div :class="info.companyCount && info.companyCount.draftCount == 0
                ? 'category-no'
                : category == '非挂牌上市公司'
                  ? 'category-active'
                  : 'category-item'
              " @click="
      chooseCategory(
        '非挂牌上市公司',
        4,
        info.companyCount && info.companyCount.draftCount
      )
      ">
            非挂牌上市公司
            <span class="category-num">{{
              info.companyCount && info.companyCount.draftCount
            }}</span>
          </div>
          <span class="split">|</span>
          <div :class="info.companyCount && info.companyCount.privateCount == 0
                ? 'category-no'
                : category == '已私募融资公司'
                  ? 'category-active'
                  : 'category-item'
              " @click="
      chooseCategory(
        '已私募融资公司',
        5,
        info.companyCount && info.companyCount.privateCount
      )
      ">
            已私募融资公司
            <span class="category-num">{{
              info.companyCount && info.companyCount.privateCount
            }}</span>
          </div>
        </div>
        <hr style="margin: 10px auto" />
        <div class="flex-row">
          <span class="category-left">按企业资质：&emsp;</span>
          <div class="flex-row category-right">
            <div>
              <div :class="category == '优质企业'
                    ? 'category-active first-category'
                    : 'first-category'
                  " @click="chooseCategory('优质企业', 'highQuality')">
                优质企业
                <span class="category-num">{{ highQuality }}</span>
              </div>
              <div :class="category == '科技型企业'
                ? 'category-active first-category'
                : 'first-category'
                " @click="chooseCategory('科技型企业', 'technology')">
                科技型企业
                <span class="category-num">{{ technologyCount }}</span>
              </div>
              <span class="second-category" :class="category == i.name ? 'category-active' : 'category-item'
                " @click.stop="chooseCategory(i.name, 'technology')" v-for="i in technology" :key="i.name">{{ i.name }}
                <span class="category-num">{{ i.quantity }}</span></span>&emsp;
              <div :class="category == '优质供应商'
                ? 'category-active first-category'
                : 'first-category'
                " @click="chooseCategory('优质供应商', 'supplier')">
                优质供应商
                <span class="category-num">{{ suppliersCount }}</span>
              </div>
              <span class="second-category" :class="i.quantity == 0
                ? 'category-no'
                : category == i.name
                  ? 'category-active'
                  : 'category-item'
                " @click.stop="chooseCategory(i.name, 'supplier')" v-for="i in suppliers" :key="i.name">{{ i.name }}
                <span class="category-num">{{ i.quantity }}</span></span>
              <div :class="category == '榜单企业'
                ? 'category-active first-category'
                : 'first-category'
                " @click="chooseCategory('榜单企业', 'honor')">
                榜单企业
                <span class="category-num">{{ honorCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 表格部分A股公司 -->
      <div class="A-shareCompanies" v-if="companyList && companyList.length">
        <div class="A-sharecompanies">
          <span class="sub-before"></span>
          <div class="A-sharecompaniestitle">{{ category }}</div>
        </div>
        <hr style="margin: 15px auto 19px" />
        <div class="from">
          <el-table :data="companyList" :header-cell-style="{
            color: '#333333',
            background: '#ebf6ff',
            height: '40px',
            'font-size': '16px',
            'padding-top': '0',
            'padding-bottom': '0',
          }" :row-class-name="tableRowClassName" style="width: 100%">
            <el-table-column type="index" width="80" label="序号">
            </el-table-column>
            <el-table-column prop="companyName" label="公司名称" width="400">
              <template slot-scope="scope">
                <div class="flex-row">
                  <img v-if="scope.row.companyLogo" slot="reference" :src="scope.row.companyLogo" style="
                      width: 50px;
                      height: 50px;
                      margin-right: 10px;
                      border-radius: 4px;
                      vertical-align: middle;
                      border: 1px solid #999;
                      object-fit: contain;
                    " />
                  <span v-else-if="scope.row.companyName" style="
                      font-size: 24px;
                      margin-right: 10px;
                      display: inline-block;
                      border-radius: 4px;
                      background: #7db3d9;
                      width: 50px;
                      height: 50px;
                      color: white;
                      text-align: center;
                      line-height: 50px;
                      border: 1px solid #999;
                    ">{{ scope.row.companyName.slice(0, 1) }}</span>
                  <div class="flex-col">
                    <el-button v-if="scope.row.status" style="
                        padding: 0;
                        text-align: left;
                        margin-bottom: 10px;
                        color: #333;
                        cursor: default;
                      " type="text" size="medium " @click="selectCompany(scope.row)">{{ scope.row.companyName
                      }}</el-button>
                    <span style="text-align: left" v-else>{{
                      scope.row.companyName
                    }}</span>
                    <p>
                      <span style="
                          display: inline-block;
                          background: #fef1df;
                          color: #ff6600;
                          padding: 0 10px;
                        ">{{ scope.row.regStatus }}</span>&nbsp;<span style="
                          display: inline-block;
                          background: #e4f4ff;
                          color: #15659e;
                          padding: 0 10px;
                        ">{{ scope.row.marketing.split(",")[0]
                        }}{{
  scope.row.secuAbbr ? "| " + scope.row.secuAbbr : ""
}}{{ scope.row.stockCode }}</span>
                    </p>
                    <p style="margin-top: 4px" v-if="scope.row.technology || scope.row.otherTags">
                      <span v-if="scope.row.technology" style="
                          display: inline-block;
                          background: #e8f7e3;
                          color: #2aa800;
                          padding: 0 10px;
                        ">{{ scope.row.technology }}</span><span style="
                          display: inline-block;
                          background: #fcedd2;
                          color: #996402;
                          padding: 0 10px;
                        ">{{ scope.row.otherTags.split(",")[0] }}</span>
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="legalPerson" label="法定代表人" width="120">
            </el-table-column>
            <el-table-column width="220" prop="regCapital" label="注册资本(万人民币)" sortable>
            </el-table-column>
            <el-table-column prop="establishDate" label="成立日期" sortable width="180">
            </el-table-column>
            <el-table-column width="120" prop="productTag" label="产品标签" show-overflow-tooltip><template
                slot-scope="scope"><span v-for="i in scope.row.productTag.slice(0, 3)">{{ i
                }}<br /></span></template></el-table-column>
            <el-table-column prop="level2" align="center" label="外迁评分"><template slot-scope="scope"><span
                  style="color: #ff6600">{{ scope.row.level2 }}分</span></template></el-table-column>
            <!-- <el-table-column width="180" align="center" label="解锁联系方式"><template slot-scope="scope"><span @click="
              unlockContact(scope.row.companyName, scope.row.companyCode)
              " style="
                    display: inline-block;
                    cursor: pointer;
                    font-size: 14px;
                    background: #15659e;
                    border-radius: 2px;
                    color: #fff;
                    width: 100px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                  ">{{ scope.row.status ? "立即查看" : "立即解锁" }}</span>
                <p style="font-size: 14px">
                  联系方式<span style="color: #ff6600">{{
                    scope.row.contractCount
                  }}</span>条
                </p>
              </template></el-table-column> -->
          </el-table>
          <div class="bottom-page">
            <span :style="currPage > 1 ? 'color:#15659E;cursor:pointer' : 'color:#999'
                " @click="handleMainPage(1)">首页</span>&emsp;<span :style="currPage > 1 ? 'color:#15659E;cursor:pointer' : 'color:#999'
      " @click="handleMainPage(currPage == 1 ? 1 : currPage - 1)">上一页</span>&emsp;<span :style="currPage < totalPage
    ? 'color:#15659E;cursor:pointer'
    : 'color:#999'
    " @click="handleMainPage(currPage + 1)">下一页</span>
          </div>
        </div>
      </div>

      <div class="contact-bg flex-row" v-show="contactDialog">
        <div class="contact-dialog flex-col">
          <img @click="contactDialog = false" class="close" src="@/assets/images/chain/close.png" alt="" />
          <p class="title"><span class="sub-before"></span>联系方式</p>
          <hr style="margin: 15px auto" />
          <div v-if="unlockInfo.fixedPhone -
            0 +
            (unlockInfo.mobile - 0) +
            (unlockInfo.email - 0) >
            0
            ">
            <p class="contact-company">{{ companyName }}</p>
            <p class="contact-way">
              联系方式共{{
                unlockInfo.fixedPhone -
                0 +
                (unlockInfo.mobile - 0) +
                (unlockInfo.email - 0)
              }}条
            </p>
            <div class="flex-row contact-type">
              <div class="flex-col">
                <img src="@/assets/images/chain/contact-phone.png" alt="" />
                <p>电话{{ unlockInfo.fixedPhone }}</p>
              </div>
              <div class="flex-col">
                <img src="@/assets/images/chain/contact-mobile.png" alt="" />
                <p>手机{{ unlockInfo.mobile }}</p>
              </div>
              <div class="flex-col">
                <img src="@/assets/images/chain/contact-email.png" alt="" />
                <p>邮箱{{ unlockInfo.email }}</p>
              </div>
            </div>
            <div class="qrcode">
              <img src="@/assets/images/chain/yuanshang.jpg" alt="" />
            </div>
            <p class="scan">微信扫一扫查看联系方式</p>
            <div class="flex-row contact-bottom">
              <span style="visibility: hidden">今日剩余可领企业数：6/10家</span>
              <span class="toList" style="visibility: hidden">您也可以前往<span
                  style="color: #2c94dd; cursor: pointer">【解锁列表】</span>中查看</span>
            </div>
          </div>
          <div v-else class="flex-col no-way">
            <img width="250" src="@/assets/images/chain/no-way.png" alt="" />
            <br />
            <p>暂无相关联系方式</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
