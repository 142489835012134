import _axios from '../utils/https'

/**
 * 获取用户所有消息
 * @param {*} data 
 * @returns 
 */
export const getMessageAll = (data) => {
    return _axios({
        method: "post",
        url: `/ml/notice/all-notice-page`,
        data
    })
}

/**
 * 获取用户未读消息
 * @param {*} data 
 * @returns 
 */
export const getMessageUnread = (data) => {
    return _axios({
        method: "post",
        url: `/ml/notice/pending-notice-page`,
        data
    })
}

/**
 * 获取用户已读消息
 * @param {*} data 
 * @returns 
 */
export const getMessageRead = (data) => {
    return _axios({
        method: "post",
        url: `/ml/notice/received-notice-page`,
        data
    })
}

/**
 * 获取用户未读消息总数
 * @param {*} data 
 * @returns 
 */
export const getPendingCount = () => {
    return _axios({
        url: `/ml/notice/pending-count`
    })
}

/**
 * 更新消息状态
 * @param {*} data 
 * @returns 
 */
export const updateMessageStatus = (data) => {
    return _axios({
        url: `/ml/notice/update/status`,
        method: "post",
        data
    })
}

/**
 * 根据消息Id,获取消息详情
 * @param {*} id 
 * @returns 
 */
export const getMessageById = (id) => {
    return _axios({
        url: `/ml/notice/get/msg/${id}`
    })
}

/**
 * 根据消息记录Id,获取消息详情(自动标记为已读)
 * @param {*} recordId 
 * @returns 
 */
export const getMessageByRecordId = (recordId) => {
    return _axios({
        url: `/ml/notice/get/msg?recordId=${recordId}`
    })
}
