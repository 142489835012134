import axios from "axios"
import _axios from '@/utils/https'

/**
 * 上传文件-不走拦截器
 * @param {*} params 
 * @returns 
 */
export const policy = ({ baseUrl = 'http://192.168.120.92:8107', fileName, fileSize, fileSha1 }) => {
    return axios({
        url: baseUrl + `/sys/file/policy`,
        method: "get",
        params: {
            folder: "4",
            fileName,
            fileSize,
            fileSha1
        }
    })
}

/**
 * 走请求拦截器
 * @param {*} param0 
 * @returns 
 */
export const policy1 = ({ fileName, fileSize, fileSha1, fileSuffix }) => {
    return _axios({
        url: `/mgt/sys/file/policy`,
        method: "get",
        params: {
            folder: "4",
            fileName,
            fileSize,
            fileSha1,
            fileSuffix
        }
    })
}

/**
 * 获取文件上传签名
 * @param {*} data 
 * @returns 
 */
export const policy2 = (data) => {
    return _axios({
        url: `/ml/meet/file/record/upload/policy`,
        method: "post",
        data
    })
}
