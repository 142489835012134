<script>
import "@/assets/css/mymessage.css";
import { getMessageAll, getMessageRead, getMessageUnread, getMessageByRecordId, } from "@/apis/message";
import { changeApplyStatus } from '@/apis/friend'
export default {
  name: "Mymessage",

  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      current: {},
      messages: [],
      active: "all",
      enrollStatus: false,
      showCardChange: false,
      img: require("@/assets/images/home/item-active.png")
    };
  },

  created() {
    this.getMessageAll();
  },

  methods: {
    /* 切换选项卡 */
    changeActive(active) {
      if (active === this.active) return;
      this.active = active;
      this.current = {};
      this.messages = [];
      if (active === "all") {
        this.getMessageAll();
      } else if (active === "noread") {
        this.getNoreads();
      } else if (active === "readed") {
        this.getReadeds();
      }
    },

    /* 选中当前消息 */
    handleCurrent(item) {
      this.current = { ...item };
    },

    /* 获取所有消息 */
    async getMessageAll() {
      const res = await getMessageAll({ pageNo: 1, pageSize: 50 });
      this.current = res.data.list[0] || {};
      this.messages = res.data.list;
    },

    /* 获取所有未读消息 */
    async getNoreads() {
      const res = await getMessageUnread({ pageNo: 1, pageSize: 50 });
      this.current = res.data.list[0] || {};
      this.messages = res.data.list;
    },

    /* 获取所有已读消息 */
    async getReadeds() {
      const res = await getMessageRead({ pageNo: 1, pageSize: 50 });
      this.current = res.data.list[0] || {};
      this.messages = res.data.list;
    },

    /**
     * 操作信息 1系统通知 2系统公告 3好友申请 4名片投递 5邀请加入会议 6申请加入星球 7邀请加入星球 8好友聊天 99其它
     */
    async handleMessage() {
      this.enrollStatus = true

      if (this.current.topic === 1) {
        await getMessageByRecordId(this.current.recordId);
        this.$message.success("操作成功")
        this.getMessageReload();
      } else if (this.current.topic === 3) {
        await changeApplyStatus({ status: "PASS", recordId: this.current.recordId })
        this.$message.success("添加好友成功")
        this.getMessageReload();
      } else if (this.current.topic === 4) {
        this.showCardChange = true;
      }
    },

    /* 重新请求 */
    async getMessageReload() {
      const active = this.active;
      active === "all"
        ? await this.getMessageAll()
        : active === "noread"
          ? await this.getNoreads()
          : active === "readed"
            ? await this.getReadeds()
            : console.log("~");
    },
  },
};
</script>

<template>
  <div class="my-message" v-if="show">
    <!-- 交换名片弹窗组件 -->
    <Layout v-model="showCardChange">
      <cardsExchange v-model="showCardChange" v-if="current.recordId" :recordId="current.recordId" />
    </Layout>

    <!-- 报名 -->
    <Layout v-model="enrollStatus">
      <Enroll v-model="enrollStatus" />
    </Layout>

    <div class="content">
      <div class="title flex-row">
        <span @click="showCardChange = true">我的消息</span>
        <img @click="$emit('updateMessage')" src="../assets/images/home/dialog-close.png" alt="" />
      </div>
      <div class="content-list flex-row">
        <div class="left">
          <div class="item" @click="changeActive('all')" :style="{ background: active === 'all' ? `url(${img})` : `` }">
            全部消息
          </div>
          <div class="item" @click="changeActive('noread')"
            :style="{ background: active === 'noread' ? `url(${img})` : `` }">
            <span>未读消息</span>
            <span class="special">99+</span>
          </div>
          <div class="item" @click="changeActive('readed')"
            :style="{ background: active === 'readed' ? `url(${img})` : `` }">
            已读消息
          </div>
        </div>

        <div class="right">
          <div class="message-container" v-for="(item, index) in 9" :key="index" @click="current = index">
            <div class="message-item flex-row">
              <div class="message-icon">
                <img src="@/assets/images/home/我的消息-加入星球.png" alt="">
              </div>
              <div class="detail-desc">
                <div class="main-title flex-row">
                  <div class="left-title"><span>恭喜你获得500元优惠券</span>
                    <img src="@/assets/images/home/new.png" alt="" style="transform: translate(5px, -5px);">
                  </div>
                  <img class="arrow" src="@/assets/images/home/message-arrow-bottom.png" alt="" v-if="current === index">
                  <img class="arrow" src="@/assets/images/home/message-arrow-right.png" alt="" v-else>
                </div>
                <div class="message-time">2022.12.01 13:00:02</div>
              </div>
            </div>
            <div class="decribletext" v-show="current === index">
              <div class="sub-title">恭喜你获得500元优惠券</div>
              消费券使用时间：消费券有效期自领取之日起至8月7日23:59:59，逾期失效。活动期间将分多轮次发放消费券，消费券有效期为自领券之日起7天内有效。消费券先到先得，领完为止，政府
              消费券与美团平台优惠券可叠加使用。
              <div class="btn" style="margin-top: 10px;" @click="handleMessage">
                操作
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



