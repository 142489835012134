
<script>
import "@/assets/css/exchangecard.css";
import { selfCards, cardReceive } from "@/apis/card";
export default {
  name: "cardsExchange",

  props: {
    value: {
      required: true,
      type: Boolean,
    },
    recordId: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      currentIndex: 0,
      cards: [],
    };
  },

  watch: {
    value(n) {
      if (n) {
        this.getCards();
        this.currentIndex = 0
      }
    },
  },

  methods: {
    /* 获取当前用户名片 */
    async getCards() {
      const res = await selfCards({ pageNo: 1, pageSize: 100 });
      this.cards = res.data.list;
    },

    /* 选择确认交换名片 */
    async confirmChange() {
      let cardId = this.cards[this.currentIndex]['id']
      await cardReceive({
        status: "PASS",
        cardId,
        recordId: this.recordId
      })
      this.$emit('input', false)
      this.$parent.getMessageReload()
    }
  },
};
</script>

<template>
  <div class="exchange-card" v-if="value">
    <div class="content">
      <div class="title">
        <span>选择进行交换的名片</span>
        <img src="@/assets/images/home/dialog-close.png" @click="$emit('input', false)" alt="" />
      </div>
      <div class="card-list flex-row">
        <div @click="currentIndex = index" class="card-box" v-for="(item, index) in cards" :key="index">
          <img src="@/assets/images/home/card-active.png" v-if="index === currentIndex"
            style="position: absolute; left: 0; top: 0" alt="" />
          <div class="nickname">
            <span>{{ item.name }}</span>
            <span class="subtitle">{{ item.position }}</span>
          </div>
          <div class="company">{{ item.companyName }}</div>
          <div class="mobile-address flex-row">
            <img src="@/assets/images/home/mobile.png" alt="" />
            <span>{{ item.mobile }}</span>
          </div>
          <div class="mobile-address flex-row">
            <img src="@/assets/images/home/address.png" alt="" />
            <span>
              {{ item.address }}
            </span>
          </div>
        </div>
      </div>
      <div class="btn" @click="confirmChange">确定</div>
    </div>
  </div>
</template>
