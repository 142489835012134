import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
import home from './module/home'
import meet from './module/meet'
import unity from './module/unity'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    home,
    meet,
    unity
  },

  plugins: [
    persistedState({
      key: "M-Land",
      storage: window.sessionStorage,
      paths: ['home']
    })
  ]
})
