import _axios from '../utils/https'

/**
 * 分页查询[用户的名片和接收到的名片]
 * @param {*} data 
 * @returns 
 */
export const allCards = (data) => {
    return _axios({
        url: `/ml/hall/card/all-card-page`,
        method: "post",
        data
    })
}

/**
 * 接收名片
 * @param {*} data 
 * @returns 
 */
export const cardReceive = (data) => {
    return _axios({
        url: `/ml/hall/card/receive`,
        method: "post",
        data
    })
}

/**
 * 接收名片
 * @param {*} data 
 * @returns 
 */
export const cardRemove = (data) => {
    return _axios({
        url: `/ml/hall/card/remove`,
        method: "post",
        data
    })
}

/**
 * 新增名片
 * @param {*} data 
 * @returns 
 */
export const cardSave = (data) => {
    return _axios({
        url: `/ml/hall/card/save`,
        method: "post",
        data
    })
}

/**
 * 分页查询[用户名片列表]
 * @param {*} data 
 * @returns 
 */
export const selfCards = (data) => {
    return _axios({
        url: `/ml/hall/card/self-card-page`,
        method: "post",
        data
    })
}

/**
 * 投递名片
 * @param {*} data 
 * @returns 
 */
export const cardSend = (data) => {
    return _axios({
        url: `/ml/hall/card/send`,
        method: "post",
        data
    })
}

/**
 * 修改名片
 * @param {*} data 
 * @returns 
 */
export const cardUpdate = (data) => {
    return _axios({
        url: `/ml/hall/card/update`,
        method: "post",
        data
    })
}
