
<script>
export default {
  name: "Layout",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {

    }
  }
}
</script>
<template>
  <div class="layout" v-if="value" :style="{ 'z-index': zIndex }">
    <slot></slot>
  </div>
</template>


<style lang="scss" scoped>
.layout {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: rgba($color: #000000, $alpha: 0.3);
}
</style>
