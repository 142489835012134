<style lang="scss" scoped>
.management-meet {
  position: relative;
  width: 1300px;
  height: 865px;
  background-image: linear-gradient(180deg, #deecff 0%, #ffffff 13%);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 18px;

  .main-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    span {
      font-size: 28px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  ::v-deep {
    .el-pagination {
      ul li {
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 20px;
        color: #005ad5;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 0;
      }

      .active {
        background: #999999 !important;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }

      button {
        width: 45px;
        height: 45px;
        line-height: 45px;

        .el-icon::before {
          font-size: 20px;
        }
      }

      .el-pagination__total {
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        color: #999999;
        letter-spacing: 0;
        text-align: right;
        font-weight: 400;
      }
    }
  }
}

/* 会议列表 */
@import url("@/assets/css/meet-table.css");
</style>

<template>
  <div class="management-meet" v-if="value">
    <!-- 会议详情 -->
    <Layout v-model="viewDetailStatus">
      <meetDetails v-model="viewDetailStatus" :id="id" :isLeader="isLeader" />
    </Layout>

    <!-- 报名审批 -->
    <Layout v-model="approvalStatus">
      <registrationApproval v-model="approvalStatus" :meetingId="meetingId" />
    </Layout>

    <!-- 进入会议 -->
    <Layout v-model="JOIN_STATUS">
      <join-meeting
        v-model="JOIN_STATUS"
        :meetNo="meetNo"
        :meetId="meetingId"
      />
    </Layout>

    <!-- 头部 -->
    <div class="main-header">
      <span>会议管理</span>
      <img
        class="cursor"
        src="@/assets/images/meet/close.png"
        alt=""
        @click="$emit('input', false)"
      />
    </div>

    <!-- 会议列表 -->
    <div class="table">
      <div class="search flex-row">
        <div class="search-input flex-row">
          <input v-model="meetTopic" type="text" placeholder="搜索会议主题" />
          <img
            src="../assets/images/home/search.png"
            alt=""
            @click="queryHandle"
          />
        </div>
      </div>
      <div class="tabs flex-row">
        <div
          @click="switchTabs('待开会议')"
          :class="['tab-item', { active: tabActive == '待开会议' }]"
        >
          待开会议
        </div>
        <div
          @click="switchTabs('历史会议')"
          :class="['tab-item', { active: tabActive == '历史会议' }]"
          style="margin-left: 20px"
        >
          历史会议
        </div>
        <div
          @click="switchTabs('虚拟人会议')"
          :class="['tab-item', { active: tabActive == '虚拟人会议' }]"
          style="margin-left: 20px"
        >
          虚拟人会议
        </div>
      </div>
      <div class="table-body">
        <div class="table-title flex-row">
          <div class="code">会议号</div>
          <div class="title">会议主题</div>
          <div class="meet-room">会议室</div>
          <div class="date">会议日期</div>
          <div class="start">开始时间</div>
          <div class="status">会议状态</div>
          <div class="status">报名状态</div>
          <div class="creater">创建人</div>
          <div class="handle">操作</div>
        </div>
        <div
          class="table-col flex-row"
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="code">{{ item.no }}</div>
          <div class="title" @click="viewDetails(item)">{{ item.topic }}</div>
          <div class="meet-room">一号会议室</div>
          <div class="date">{{ item.meetingDate }}</div>
          <div class="start">{{ item.beginTime }}</div>
          <div class="status">
            {{
              item.state === 10
                ? "未开始"
                : item.state === 20
                ? "进行中"
                : item.state === 30
                ? "已结束"
                : item.state === 40
                ? "已取消"
                : "默认"
            }}
          </div>
          <div class="status">
            {{
              item.auditStatus === 0
                ? "待处理"
                : item.auditStatus === 1
                ? "审核已通过"
                : item.auditStatus === 2
                ? "审核未通过"
                : "-"
            }}
          </div>
          <div class="creater">{{ item.creator }}</div>
          <div class="handle">
            <span
              :class="{ histroy: tabActive === '历史会议' }"
              @click="enterTheConferenceRoom(item)"
              >进入</span
            >
            <span
              :class="{ histroy: tabActive === '历史会议' }"
              @click="viewDetails(item)"
              >详情</span
            >
            <span
              :class="{ histroy: tabActive === '历史会议' }"
              @click="registerApproval(item)"
              v-if="item.leader"
              >报名审批</span
            >
            <span @click="summary" v-if="tabActive === '历史会议'">纪要</span>
          </div>
        </div>
      </div>
      <div style="text-align: center" v-if="data.length < 1">
        <img
          style="margin: 80px auto"
          src="@/assets/images/meet/qs.png"
          alt=""
        />
      </div>
    </div>
    <el-pagination
      style="text-align: right; position: absolute; bottom: 20px; right: 18px"
      @current-change="handlePage"
      :current-page.sync="current"
      :page-size="5"
      background
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getMeetingPage,
  getHistoryMeetList,
  getVrMeetingPage,
} from "@/apis/meet";
import { callUnity } from "@/assets/js/util";
export default {
  name: "managementMeeting",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      bg: require("@/assets/images/meet/bg.png"),
      show: false,
      JOIN_STATUS: false,
      viewDetailStatus: false,
      approvalStatus: false,
      tabActive: "待开会议",
      meetTopic: "",
      data: [],
      id: "",
      isLeader: false,
      current: 1,
      total: 20,
      meetingId: "",
      meetNo: "",
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getMeetList();
    });
  },

  methods: {
    /* 切换tabs */
    switchTabs(string) {
      this.current = 1;
      this.tabActive = string;
      string === "待开会议"
        ? this.getMeetList()
        : string === "虚拟人会议"
        ? this.getVrMeetingPage()
        : this.getHistoryMeetList();
    },

    /* 获取会议列表 */
    async getMeetList() {
      const res = await getMeetingPage({
        meetTopic: this.meetTopic,
        pageNo: this.current,
        pageSize: 5,
      });
      this.data = res.data.list;
      this.total = res.data.total;
    },

    /* 获取历史会议列表 */
    async getHistoryMeetList() {
      const res = await getHistoryMeetList({
        meetTopic: this.meetTopic,
        pageNo: this.current,
        pageSize: 5,
      });
      this.data = res.data.list;
      this.total = res.data.total;
    },

    /* 获取虚拟会议列表 */
    async getVrMeetingPage() {
      const res = await getVrMeetingPage({
        meetTopic: this.meetTopic,
        pageNo: this.current,
        pageSize: 5,
      });
      this.data = res.data.list;
      this.total = res.data.total;
    },

    /* 搜索查询 */
    queryHandle() {
      this.current = 1;
      this.tabActive === "待开会议"
        ? this.getMeetList()
        : this.tabActive === "虚拟人会议"
        ? this.getVrMeetingPage()
        : this.getHistoryMeetList();
    },

    /* 切换分页 */
    handlePage(current) {
      this.current = current;
      this.tabActive === "待开会议"
        ? this.getMeetList()
        : this.tabActive === "虚拟人会议"
        ? this.getVrMeetingPage()
        : this.getHistoryMeetList();
    },

    /* 进入会议 */
    enterTheConferenceRoom(data) {
      if (this.tabActive === "历史会议")
        return this.$message.error("历史会议禁止操作");
      callUnity("setMeetingInfo", {
        app_id: process.env.VUE_APP_APP_ID,
        channel: data.no,
        meetId: data.id,
      });
      // getMediaState()
      //   .then(async () => {
      //     const response = await getConstraint({ meetNo: data.no });
      //     if (response.data.self) {
      //       /* 如果是自己创建的会议 */
      //       const res = await getMeetDetail(data.id);
      //       await enterTheMeeting({
      //         meetNo: res.data.no,
      //         passwd: res.data.passwd,
      //         userName: this.$store.state.home.USERINFO.nickname,
      //       });
      //       this.$store.commit("meet/globalLoading", true);
      //       this.$router.push(`/meet/${res.data.id}/${res.data.no}`);
      //       callUnity("switchSpace", {
      //         scene: "ToMeeting",
      //         stype: data.roomNo === "Big" ? "Big" : "",
      //       });
      //     } else {
      //       /**
      //        * 如果不是自己创建的会议 判断是否之前已经进入过
      //        */
      //       if (response.data.passwd) {
      //         // 1、 需要密码
      //         if (response.data.inputPwd) {
      //           /* 输入过密码 */
      //           await enterTheMeeting({
      //             passwd: "",
      //             meetNo: data.no,
      //             userName: this.$store.state.home.USERINFO.nickname,
      //           });
      //           this.$store.commit("meet/globalLoading", true);
      //           this.$router.push(`/meet/${data.id}/${data.no}`);
      //           callUnity("switchSpace", {
      //             scene: "ToMeeting",
      //             stype: data.roomNo === "Big" ? "Big" : "",
      //           });
      //         } else {
      //           /* 没输过密码 */
      //           this.JOIN_STATUS = true;
      //           this.meetingId = data.id;
      //           this.meetNo = data.no;
      //         }
      //       } else {
      //         // 2、 不需要密码
      //         await enterTheMeeting({
      //           passwd: "",
      //           meetNo: data.no,
      //           userName: this.$store.state.home.USERINFO.nickname,
      //         });
      //         this.$store.commit("meet/globalLoading", true);
      //         this.$router.push(`/meet/${data.id}/${data.no}`);
      //         callUnity("switchSpace", {
      //           scene: "ToMeeting",
      //           stype: data.roomNo === "Big" ? "Big" : "",
      //         });
      //         this.$message.success("进入会议室成功");
      //       }
      //     }
      //   })
      //   .catch(() => {
      //     return false;
      //   });
    },

    /* 查看详情 */
    viewDetails(data) {
      if (data.leader || !data.auditStatus || data.auditStatus === 1) {
        this.viewDetailStatus = true;
        this.id = data.id;
        this.isLeader = data.leader;
      } else {
        this.$message.error("审核未通过");
      }
    },

    /* 报名审批 */
    registerApproval(data) {
      if (this.tabActive === "历史会议")
        return this.$message.error("历史会议禁止操作");
      this.meetingId = data.id;
      this.approvalStatus = true;
    },

    // 纪要

    summary() {
      window.open('https://meetingcontent.chinaindustria.com/')
    },
  },
};
</script>
