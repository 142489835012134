<template>
  <div class="member">
    <!-- <ExchangeCard /> -->
    <div class="cotainer">
      <div class="top flex-row">
        <img src="../assets/images/login/login-logo.png" alt="" />
        <img style="margin: 0 10px 0 20px" src="../assets/images/home/arrow-back.png" alt="" />
        <span @click="$router.push({ name: 'HomeView' })">返回</span>
      </div>
      <div class="content">
        <div class="title flex-row">
          <span>展厅成员管理</span>
        </div>
        <div class="content-list flex-row">
          <div class="left">
            <div class="item" @click="changeActive('manage')"
              :style="{ background: active === 'manage' ? `url(${img})` : `` }">
              成员管理
            </div>
            <div class="item" @click="changeActive('add')" :style="{ background: active === 'add' ? `url(${img})` : `` }">
              添加成员
            </div>
          </div>
          <div class="middle" v-if="active === 'manage'">
            <div class="search flex-row">
              <input v-model="nickName" type="text" placeholder="搜索好友" />
              <img src="../assets/images/home/search.png" alt="" />
            </div>
            <div class="line"></div>
            <div class="middle-container">
              <div class="middle-item" @click="handleCurrnet(item)" :class="{ active: item.id === currentInfo.id }"
                v-for="(item, index) in friends" :key="index">
                <div class="sub-container flex-row" :style="{
                  'border-bottom':
                    item.id !== currentInfo.id
                      ? '1px dashed rgba(153,153,153,1)'
                      : '',
                }">
                  <img src="../assets/images/home/avatar.png" alt="" />
                  <span>
                    {{ item.name }}
                  </span>
                </div>
              </div>
              <div class="total">共有{{ friends.length }}条记录</div>
            </div>
          </div>
          <div class="right flex-row" v-if="active === 'manage'">
            <div class="describle">
              <div class="name">{{ currentInfo.name }}</div>
              <div class="real-name flex-row">
                <span>姓名：</span>
                <div>{{ currentInfo.name }}</div>
              </div>
              <div class="real-name flex-row">
                <span>职位：</span>
                <div>常州天宁经济技术开发区管委会招商中心主任</div>
              </div>
            </div>
            <div class="vr-box flex-col">
              <div class="vr">
                <img src="../assets/images/home/member-vr.png" alt="" />
              </div>
              <div class="handle-name flex-row">
                <div class="handle">设为负责人</div>
                <div class="handle">删除成员</div>
              </div>
            </div>
          </div>
          <div class="member-form" v-if="active === 'add'">
            <div class="form-item flex-row">
              <span class="label">账号</span>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="form-item flex-row">
              <span class="label">姓名</span>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="form-item flex-row">
              <span class="label">职位</span>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="agree flex-row">
              <input id="agree" type="checkbox" />
              <label for="agree">通过短信发送邀请信息</label>
            </div>
            <div class="btn">保存</div>
          </div>
          <div class="member-code flex-col" v-if="active === 'add'">
            <div class="share">分享二维码通知加入</div>
            <div class="subtitle">成员扫码后快速加入展厅</div>
            <div class="code">
              <img src="../assets/images/home/code.png" alt="" />
            </div>
            <div class="url">复制邀请链接</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "@/assets/css/member.css";
export default {
  name: "Member",
  data() {
    return {
      total: 0,
      current: 1,
      currentIndex: 0,
      nickName: "",
      active: "manage",
      img: require("../assets/images/home/item-active.png"),
      currentInfo: {},
      friends: [
        { id: 1, name: "张三" },
        { id: 2, name: "李四" },
        { id: 3, name: "王五" },
      ],
      form: {
        nickName: "周杰伦",
        company: "上海xxx网络科技有限公司",
        job: "歌手",
        mobile: "130xxxx5678",
        address: "上海市黄浦区人民大道xxx号x座xxx室",
      },
    };
  },

  methods: {
    /* 切换tab */
    changeActive(active) {
      this.current = 1;
      this.total = 0;
      this.active = active;
      // if (this.active === "manage") {

      // } else if (this.active === "add") {

      // }
    },

    /* 切换分页 */
    handleCurrnet(current) {
      this.currentInfo = current;
    },

    /* 保存 */
    preservation() {
      this.cancelHandle();
    },

    /* 取消 */
    cancelHandle() {
      this.form = {
        nickName: "周杰伦",
        company: "上海xxx网络科技有限公司",
        job: "歌手",
        mobile: "130xxxx5678",
        address: "上海市黄浦区人民大道xxx号x座xxx室",
      };
    },
  },
};
</script>
