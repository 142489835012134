import axios from "axios";
import store from "@/store";
import ElementUI from "element-ui";

const http = axios.create({
  // baseURL: "http://192.168.120.92:30100/api", 
  baseURL: process.env.VUE_APP_SERVER_URL,
  timeout: 10000
})

http.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem("token") && JSON.parse(window.sessionStorage.getItem("token")).token
  const JRYS_TOKEN = store.state.home.JRYS_TOKEN
  if (token) {
    if (config.url.indexOf('/api/jrys') != -1 && JRYS_TOKEN) {
      config.headers["Authorization"] = JRYS_TOKEN
    } else if (config.url.indexOf('ig507.com') != -1) {
      delete config.headers["Authorization"]
    } else {
      config.headers["Authorization"] = token
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

http.interceptors.response.use(function (response) {
  return new Promise((resolve) => {
    console.log(response, '#####')
    if (response.data.code === 1000002) {
      resolve(response.data)
    } else if (!response.data.code) {
      resolve(response)
    } else if (response.data.code !== 200) {
      ElementUI.Message.error(response.data.msg)
    } else {
      resolve(response.data ? response.data : response)
    }
  })
}, function (error) {
  if (error.response && error.response.status == 401) {
    ElementUI.Message.error(error.response.data.msg)
    window.sessionStorage.clear()
    setTimeout(() => {
      window.location = "/";
    }, 1000);
  } else {
    ElementUI.Message.error(error.response.data.msg || "系统繁忙")
  }
  return Promise.reject(error);
});

export default http
