import _axios from '../utils/https'

/**
 * 发送短信验证码
 * @param {*} data 
 * @returns 
 */
export const sendSms = (data) => {
    return _axios({
        url: `/oauth/send/sms`,
        method: "post",
        data
    })
}

/**
 * 验证短信验证码
 * @param {*} data 
 * @returns 
 */
export const validateSms = (data) => {
    return _axios({
        url: `/oauth/validate/code`,
        method: "post",
        data
    })
}

/**
 * 账号注册 1.0
 * @param {*} data 
 * @returns 
 */
export const register = (data) => {
    return _axios({
        url: `/oauth/register`,
        method: "post",
        data
    })
}

/**
 * 验证账号
 * @param {*} data 
 * @returns 
 */
export const validatePhone = (data) => {
    return _axios({
        url: `/oauth/validate/phone`,
        method: "post",
        data
    })
}

/**
 * 账号登录 1.0
 * @param {*} data 
 * @returns 
 */
export const signin = (data) => {
    return _axios({
        url: `/oauth/signin`,
        method: "post",
        data
    })
}

/**
 * 账号登出 1.0
 * @param {*} data 
 * @returns 
 */
export const signout = (data) => {
    return _axios({
        url: `/oauth/signout`,
        method: "post",
        data
    })
}
