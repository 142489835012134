import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import HomeView from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home/:id?/:no?',
    name: 'HomeView',
    props: true,
    component: HomeView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    sessionStorage.clear()
    next();
    return
  }
  const token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem('token')).token;
  if (!token) {
    sessionStorage.clear()
    Vue.prototype.$message.error("身份校验已过期,请重新登陆")
    if (to.params.id || to.params.no) {
      next(`/?redirect=${(to.fullPath)}`)
    } else {
      next('/')
    }
  } else {
    next()
  }

  // if (to.path === '/') return next();
  // const tokenStr = window.sessionStorage.getItem('token') && JSON.parse(window.sessionStorage.getItem('token')).token
  // if (!tokenStr) {
  //     window.sessionStorage.clear()
  //     Vue.prototype.$message.error("身份校验已过期,请重新登陆")
  //     if (to.params.id || to.params.no) {
  //         console.log(to)
  //         next(`/?redirect=${(to.fullPath)}`)
  //     } else {
  //         next('/')
  //     }
  // } else {
  //     next()
  // }
})

export default router
