<script>
export default {
  name: "MyEnrollMent",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      enrollData: {
        code: 201134672,
        topic: "高端LED背光源扩产项目沟通",
        name: "周杰伦",
        status: "已批准",
        enrollTime: '13:00',
        reviewer: "王书记"
      }
    }
  }
}
</script>

<template>
  <div class="my-enrollment" v-if="value">
    <div class="title flex-row">
      <span>我的报名</span>
      <img @click="$emit('input', false)" src="../assets/images/home/dialog-close.png" alt="" />
    </div>
    <div class="content">
      <div class="label">
        会议号：<span>{{ enrollData.code }}</span>
      </div>
      <div class="label">
        会议主题：<span>{{ enrollData.topic }}</span>
      </div>
      <div class="label">
        成员姓名：<span style="font-weight: 600;">{{ enrollData.name }}</span>
      </div>
      <div class="label">
        当前状态：<span style="font-weight: 600; color: #13BD45">{{ enrollData.status }}</span>
      </div>
      <div class="label">
        报名时间：<span style="font-weight: 600;">{{ enrollData.enrollTime }}</span>
      </div>
      <div class="label">
        审核人：<span style="font-weight: 600;">{{ enrollData.reviewer }}</span>
      </div>
    </div>
    <div class="handles">
      <div class="btn">取消报名</div>
      <div class="btn" style="margin-left: 20px;">确定</div>
    </div>
  </div>
</template>



<style lang="scss" scoped>
.my-enrollment {
  background-image: linear-gradient(180deg, #DEECFF 0%, #FFFFFF 13%);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 600px;
  height: 500px;
  padding: 15px 20px;
  position: relative;

  .title {
    font-size: 24px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 46px;
    justify-content: space-between;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    padding-left: 32px;

    .label {
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      line-height: 36px;
      font-weight: 400;
    }
  }

  .handles {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    .btn {
      border: 2px solid #005AD5;
      border-radius: 30px;
      width: 160px;
      height: 40px;
      font-size: 18px;
      color: #005AD5;
      letter-spacing: 0;
      text-align: center;
      line-height: 36px;
      font-weight: 400;

      &:hover {
        cursor: pointer;
      }

    }
  }
}
</style>
