import { getMeetingPage } from "@/apis/meet";

export default {
  namespaced: true,
  state: {
    globalLoading: true
  },

  mutations: {
    /* 修改全局遮罩状态 */
    globalLoading(state, bool) {
      state.globalLoading = bool
    },
  },

  actions: {
    /* 获取会议室列表 */
    getMeetingPage({ commit }) {
      return new Promise((resolve) => {
        getMeetingPage({ pageNo: 1, pageSize: 50 }).then(res => {
          commit("SET_MEET_LIST", res.data.list);
          resolve(res.data.list)
        })
      })
    },
  },
};
