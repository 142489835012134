<template>
  <div class="join-meeting" v-if="value">
    <div class="content">
      <div class="title flex-row">
        <span>加入会议</span>
        <img @click="$emit('input', false)" src="../assets/images/home/dialog-close.png" alt=""
          v-if="WHETHER_TO_INVITE.haveCloseIcon" />
      </div>
      <div class="form-item flex-row" style="margin-top: 56px">
        <span class="form-label">会议号</span>
        <input class="same-input" type="text" v-model="dataForm.meetNo" placeholder="请输入会议号" />
      </div>
      <p style="width: 560px;text-align: center;margin: 0 auto;" v-if="!WHETHER_TO_INVITE.haveCloseIcon">
        小提示：无会议号时，可联系活动主办方获取会议号或邀请链接。</p>

      <p style="width: 560px;text-align: center;margin: 20px auto;" v-if="!WHETHER_TO_INVITE.haveCloseIcon">联系会议经理获取体验邀请码
        陈经理 18019411500 </p>

      <div class="form-item flex-row" v-if="constraintForm.passwd && NEED_PWD_INPUT">
        <span class="form-label">入会密码</span>
        <input type="password" v-model="dataForm.passwd" placeholder="请输入入会密码" />
      </div>
      <div class="form-item flex-row" v-if="NEED_PWD_INPUT">
        <span class="form-label">您的名称</span>
        <el-select class="form-date" v-model="dataForm.userName" placeholder="请选择">
          <el-option v-for="(item, index) in names" :key="index" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="btn" @click="joinMeetingList" v-if="!NEED_PWD_INPUT">
        进入会议
      </div>
      <div class="btn" @click="enterTheMeetingFnc" v-if="NEED_PWD_INPUT">
        进入会议
      </div>
    </div>

    <!-- 入会问卷 -->
    <Layout v-model="needSignStatus">
      <enroll @closeAllContent="closeAllContent" :show="needSignStatus" :meetNo="dataForm.meetNo" :meetId="meetId" />
    </Layout>
  </div>
</template>

<script>
import { getConstraint, joginRecordMeet, getMeetInfo } from "@/apis/meet";
import { callUnity } from "@/assets/js/util";
import "@/assets/css/join-meeting.css";

export default {
  name: "JoinMeeting",
  props: {
    NEED_PWD_INPUT: {
      default: true,
      type: Boolean,
    },
    value: {
      default: false,
      type: Boolean,
    },
    meetId: {
      default: "",
      type: String,
    },
    meetNo: {
      default: "",
      type: String,
    },
  },

  inject: ['WHETHER_TO_INVITE'],

  data() {
    return {
      needSignStatus: false,
      constraintForm: {},
      dataForm: {
        meetNo: "",
        passwd: "",
        userName: "",
      },
    };
  },

  watch: {
    /* 显示 */
    value: {
      handler(n) {
        if (n) {
          this.$router.replace("/home");
        }
      },
      immediate: true,
    },

    /* 如果传入meetNo 自动获取条件约束 */
    meetNo: {
      handler(n) {
        if (n) {
          this.$set(this.dataForm, "meetNo", n);
          this.handleConstraint(n);
        }
      },
      immediate: true,
    },
  },

  computed: {
    /* 可选的用户的名称 */
    names() {
      const array = [];
      array.push({
        label: this.$store.state.home.USERINFO.nickname + " (昵称)",
        value: this.$store.state.home.USERINFO.nickname,
      });
      return array;
    },
  },

  methods: {
    /* 获取条件约束 */
    handleConstraint(meetNo) {
      return new Promise((resolve) => {
        getConstraint({ meetNo }).then(res => {
          this.constraintForm = res.data;
          resolve();
        });
      });
    },

    /* 加入会议列表 */
    joinMeetingList() {
      if (!this.dataForm.meetNo) return this.$message.error("请输入会议号");
      /* 查看条件约束 */
      this.handleConstraint(this.dataForm.meetNo).then(async () => {

        if (this.constraintForm.state === 30) {
          this.WHETHER_TO_INVITE.haveCloseIcon = false
          this.$message.error("当前会议已结束，请联系会议主办方获取会议号或邀请链接")
          return
        }

        if (this.constraintForm.joined)
          return this.$message.error("此会议已存在会议列表，请点击会议管理进入");
        if (this.constraintForm.sign) {
          /* 需要报名 */
          if (this.constraintForm.survey) {
            this.needSignStatus = true;
          } else {
            await joginRecordMeet({ meetNo: this.dataForm.meetNo });
            this.$message.success("加入会议列表成功");
            this.$emit("input", false);
            if (this.WHETHER_TO_INVITE.status) {
              callUnity("setMeetingInfo", {
                app_id: process.env.VUE_APP_APP_ID,
                channel: this.meetNo,
                meetId: this.meetId
              })
              callUnity("RefreshAllUI", {});
            } else {
              getMeetInfo(this.dataForm.meetNo).then((res) => {
                console.log(res)
                callUnity("setMeetingInfo", {
                  app_id: process.env.VUE_APP_APP_ID,
                  channel: res.data.no,
                  meetId: res.data.id
                })
                callUnity("RefreshAllUI", {});
              })
            }
          }
        } else {
          /* 不需要报名 */
          await joginRecordMeet({ meetNo: this.dataForm.meetNo });
          this.$message.success("加入会议列表成功");
          this.$emit("input", false);
          if (this.WHETHER_TO_INVITE.status) {
            callUnity("setMeetingInfo", {
              app_id: process.env.VUE_APP_APP_ID,
              channel: this.meetNo,
              meetId: this.meetId
            })
            callUnity("RefreshAllUI", {});
          } else {
            getMeetInfo(this.dataForm.meetNo).then((res) => {
              console.log(res)
              callUnity("setMeetingInfo", {
                app_id: process.env.VUE_APP_APP_ID,
                channel: res.data.no,
                meetId: res.data.id
              })
              callUnity("RefreshAllUI", {});
            })
          }
        }
      });
    },

    /* 需要输入密码时的操作 进入会议 */
    async enterTheMeetingFnc() {
      // await enterTheMeeting({
      //   meetNo: this.meetNo,
      //   passwd: this.dataForm.passwd || "",
      //   userName: this.dataForm.userName,
      // });
      // this.$message.success("进入成功");
      // this.$router.push(`/meet/${this.meetId}/${this.meetNo}`);
      // callUnity("switchSpace", { scene: "Meeting", stype: "" });
    },

    /* 关闭整个加入会议弹窗 */
    closeAllContent(data) {
      if (!data) return (this.needSignStatus = false);
      this.needSignStatus = false;
      this.$emit("input", false);
    },
  },
};
</script>
