import _axios from '../utils/https'

/**
 * 预定会议
 * @param {*} data 
 * @returns 
 */
export const bookMeet = (data) => {
    return _axios({
        url: `/ml/meet/booking`,
        method: "post",
        data
    })
}

/**
 * 立即会议
 * @param {*} data 
 * @returns 
 */
export const createMeet = (data) => {
    return _axios({
        url: `/ml/meet/save`,
        method: "post",
        data
    })
}

/**
 * 结束会议
 * @param {*} data 
 * @returns 
 */
export const finishMeet = (data) => {
    return _axios({
        url: `/ml/meet/finish`,
        method: "post",
        data
    })
}

/**
 * 离开会议
 * @param {*} data 
 * @returns 
 */
export const leaveMeet = (data) => {
    return _axios({
        url: `/ml/meet/join/record/level`,
        method: "post",
        data
    })
}

/**
 * 获取会议详情
 * @param {*} data 
 * @returns 
 */
export const getMeetDetail = (id) => {
    return _axios({
        url: `/ml/meet/get-detail?id=${id}`,
    })
}

/**
 * 分页查询[会议列表（待开，正在进行中）]
 * @param {*} data 
 * @returns 
 */
export const getMeetingPage = (data) => {
    return _axios({
        url: `/ml/meet/meeting-page`,
        method: "post",
        data
    })
}

/**
 * 删除会议
 * @param {*} data 
 * @returns 
 */
export const removeMeet = (id) => {
    return _axios({
        url: `/ml/meet/remove/${id}`,
        method: "post",
    })
}

/**
 * 开始会议
 * @param {*} data 
 * @returns 
 */
export const startMeet = (data) => {
    return _axios({
        url: `/ml/meet/start`,
        method: "post",
        data
    })
}

/**
 * 修改会议信息
 * @param {*} data 
 * @returns 
 */
export const updateMeetDetail = (data) => {
    return _axios({
        url: `/ml/meet/update`,
        method: "post",
        data
    })
}

/**
 * 进入会议
 * @param {*} data 
 * @returns 
 */
export const enterTheMeeting = (data) => {
    return _axios({
        url: `/ml/meet/join/record/join`,
        method: "post",
        data
    })
}

/**
 * 获取参会人员列表
 * @param {*} data 
 * @returns 
 */
export const getMemberList = (data) => {
    return _axios({
        url: `/ml/meet/join/record/user-page`,
        method: "post",
        data
    })
}

/**
 * 获取历史会议列表
 * @param {*} data 
 * @returns 
 */
export const getHistoryMeetList = (data) => {
    return _axios({
        url: `/ml/meet/history-meeting-page`,
        method: "post",
        data
    })
}

/**
 * 虚拟会议列表
 * @param {*} data 
 * @returns 
 */
export const getVrMeetingPage = (data) => {
    return _axios({
        url: `/ml/meet/vr-meeting-page`,
        method: "post",
        data
    })
}

/* 获取token */
export function getCallToken(params) {
    return _axios({
        url: `/agora/token/${params.no}?uid=${params.uid}&role=ROLE_PUBLISHER&refresh=true`,
        method: 'get',
    })
}


/**
 * 通过userAccount获取令牌
 * @param {*} data 
 * @returns 
 */
export const getCallTokenByAccount = (params) => {
    return _axios({
        url: `/agora/token/${params.no}/${params.userAccount}?uid=${params.uid}&role=ROLE_PUBLISHER&refresh=true`,
    })
}

/**
 * 新增[会议邀请记录]
 * @param {*} data 
 * @returns 
 */
export const inviteRecordSave = (data) => {
    return _axios({
        url: `/ml/meet/invite/record/save`,
        method: "post",
        data
    })
}

/**
 * 查询[当前用户 会议室列表]
 * @returns 
 */
export const getRoomList = () => {
    return _axios({
        url: `/ml/meet/room/list`,
        method: "post",
        data: {}
    })
}

/**
 * 加入会议约束条件
 * @returns 
 */
export const getConstraint = (data) => {
    return _axios({
        url: `/ml/meet/join/record/constraint`,
        method: "post",
        data
    })
}

/**
 * 获取[会议报名设置]详情
 * @returns 
 */
export const getSignSettingInfo = (id) => {
    return _axios({
        url: `/ml/meet/sign/setting/info/${id}`,
    })
}

/**
 * 新增[会议报名设置]
 * @returns 
 */
export const signSettingSave = (data) => {
    return _axios({
        url: `/ml/meet/sign/setting/save`,
        method: 'post',
        data
    })
}

/**
 * 修改[会议报名设置]
 * @param {*} data 
 * @returns 
 */
export const signSettingUpdate = (data) => {
    return _axios({
        url: `/ml/meet/sign/setting/update`,
        method: 'post',
        data
    })
}

/**
 * 添加到[我的会议]
 * @param {*} data 
 * @returns 
 */
export const joginRecordMeet = (data) => {
    return _axios({
        url: `/ml/meet/join/record/save`,
        method: "post",
        data
    })
}

/**
 * 报名会议
 * @param {*} data 
 * @returns 
 */
export const signMeeting = (data) => {
    return _axios({
        url: `/ml/meet/sign/record/sign`,
        method: "post",
        data
    })
}

/**
 * 分页查询[会议报名记录列表]
 * @param {*} data 
 * @returns 
 */
export const signPage = (data) => {
    return _axios({
        url: `/ml/meet/sign/record/page`,
        method: "post",
        data
    })
}

/**
 * 报名审核通过
 * @param {*} data 
 * @returns 
 */
export const signPass = (data) => {
    return _axios({
        url: `/ml/meet/sign/record/update/pass`,
        method: "post",
        data
    })
}

/**
 * 报名审核拒绝
 * @param {*} data 
 * @returns 
 */
export const signReject = (data) => {
    return _axios({
        url: `/ml/meet/sign/record/update/reject`,
        method: "post",
        data
    })
}

/**
 * 获取所有参会人员列表
 */
export const getAllUserPage = (data) => {
    return _axios({
        url: `/ml/meet/join/record/all-user-page`,
        method: "post",
        data
    })
}

/**
 * 分页查询会议文件列表
 * @param {*} data 
 * @returns 
 */
export const getMeetFileList = (data) => {
    return _axios({
        url: `/ml/meet/file/record/meet-file-page`,
        method: "post",
        data
    })
}

/**
 * 上传文件
 * @param {*} data 
 * @returns 
 */
export const uploadFiles = (data) => {
    return _axios({
        url: `/ml/meet/file/record/save`,
        method: "post",
        data
    })
}

/**
 * 会议邀请信息
 * @param {*} params 
 * @returns 
 */
export const getInviteInfo = (params) => {
    return _axios({
        url: `/ml/meet/get-info?no=${params}`
    })
}

/**
 * 删除附件
 * @param {*} data 
 * @returns 
 */
export const deleteFile = (data) => {
    return _axios({
        url: `/ml/meet/file/record/delete/${data.fileId}/${data.meetId}`,
        method: "post",
    })
}

/**
 * 获取[会议报名统计]
 * @param {*} meetId 
 * @returns 
 */
export const getSignCount = (meetId) => {
    return _axios({
        url: `/ml/meet/sign/record/sign-count/${meetId}`
    })
}

/**
 * 转换声音
 * @param {*} meetId 
 * @param {*} fileId 
 * @returns 
 */
export const convertVoice = (meetId, fileId) => {
    return _axios({
        method: "post",
        url: `ml/meet/speech/convert/${meetId}/${fileId}`
    })
}

/**
 * 获取备注
 * @param {*} fileId 
 * @returns 
 */
export const getPdfNoteList = (data) => {
    return _axios({
        method: "post",
        url: `ml/file/ppt-note/list`,
        data
    })
}

/**
 * 修改会议文件
 * @param {*} data 
 * @returns 
 */
export const updateMeetFile = (data) => {
    return _axios({
        method: "post",
        url: `/ml/meet/file/record/update`,
        data
    })
}

/**
 * 会议邀请信息
 * @param {*} data 
 * @returns 
 */
export const getMeetInfo = (no) => {
    return _axios({
        url: `/ml/meet/get-info?no=${no}`,
    })
}
