<script>
import { allCards, cardRemove, cardSave, cardSend, cardUpdate } from "@/apis/card";
import { delFriend, getMyfriends, addFriend } from '@/apis/friend'
import "@/assets/css/friend.css";
export default {
  name: "Friend",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: 1,
      currentIndex: 0,
      nickName: "",
      active: "friend",
      isADD: false,
      img: require("@/assets/images/home/item-active.png"),
      currentInfo: {},
      friends: [],
      cards: [],
      form: {
        name: "",
        companyName: "",
        position: "",
        mobile: "",
        address: ""
      },
    };
  },

  created() {
    this.getFrinds();
  },

  methods: {
    /* 获取所有好友 */
    async getFrinds() {
      await getMyfriends({ pageNo: this.current, pageSize: 100, nickname: this.nickName })
      // this.friends = res.data.list
      this.friends = [
        { id: 1, nickname: "张三" },
        { id: 2, nickname: "李四" },
        { id: 3, nickname: "王五" },
        { id: 4, nickname: "赵六" },
        { id: 5, nickname: "田七" },
      ]
      this.currentInfo = this.friends[0]
    },

    /* 获取所有名片 */
    async getAllcards() {
      const res = await allCards({
        pageNo: this.current, pageSize: 100,
        // name: "123",
        // address: "周",
        // companyName: "周",
        // email: "周",
        // mobile: "周",
        // position: "周"
      });
      this.cards = res.data.list;
    },

    /* 查询名片或我的好友 */
    async queryHandle(currentStatus) {
      this.current = 1
      currentStatus === 'card' ? await this.getAllcards() : await this.getFrinds()
    },

    /* 删除好友 */
    async delFriend() {
      if (!this.currentInfo.friendId) return this.$message.error("请选择要操作的好友")
      await delFriend({ friendId: this.currentInfo.friendId })
      this.getFrinds()
      this.$message.success("操作成功")
    },

    /* 切换tab */
    changeActive(active) {
      this.current = 1;
      this.active = active;
      this.nickName = ""
      this.active === "friend" ? this.getFrinds() : this.getAllcards();
    },

    /* 选中当前 */
    handleCurrnet(current) {
      this.currentInfo = current;
    },

    /* 更新名片 */
    cardUpdate(item) {
      this.form = { ...item };
      this.isADD = true;
    },

    /* 移除名片 */
    async cardRemove(id) {
      await cardRemove({ id });
      this.$message.success("删除名片成功");
      this.getAllcards();
    },

    /* 保存 */
    async preservation() {
      if (!this.form.name) return this.$message.error("请填写姓名")
      if (this.form.id) {
        await cardUpdate({ ...this.form, isUse: "FALSE" })
      } else {
        await cardSave({ ...this.form, isUse: "FALSE" });
      }
      this.getAllcards();
      this.cancelHandle();
    },

    /* 添加好友 */
    async addFriend() {
      // 100000028 100000007
      await addFriend({ friendId: '100000028', message: "交个朋友叭~" })
      this.$message.success("操作成功")
    },

    /* 投递名片 */
    async sendcard() {
      await cardSend({ cardId: "1629009683429257218", targetId: "100000028" })
      this.$message.success("投递名片成功")
    },

    /* 取消 */
    cancelHandle() {
      this.form = {
        name: "",
        companyName: "",
        position: "",
        mobile: "",
        address: "",
      };
      this.isADD = false;
    }
  },
};
</script>

<template>
  <div class="my-friend" v-if="value">
    <div class="cotainer">
      <div class="friend-content">
        <!-- 头部 -->
        <div class="title flex-row">
          <span v-if="isADD && form.id">修改我的名片</span>
          <span v-else>{{ isADD ? "新增我的名片" : "管理通讯录" }}</span>
          <img @click="$emit('input', false)" src="../assets/images/home/dialog-close.png" alt="" />
        </div>
        <!-- 内容列表 -->
        <div class="content-list flex-row" v-if="!isADD">
          <!-- 左侧sidebar -->
          <div class="left">
            <div class="item" @click="changeActive('friend')"
              :style="{ background: active === 'friend' ? `url(${img})` : `` }">
              好友管理
            </div>
            <div class="item" @click="changeActive('card')"
              :style="{ background: active === 'card' ? `url(${img})` : `` }">
              名片管理
            </div>
          </div>
          <!-- 好友列表 -->
          <div class="middle" v-if="active === 'friend'">
            <div class="search flex-row">
              <input v-model="nickName" type="text" placeholder="搜索好友" />
              <img src="../assets/images/home/search.png" alt="" @click="queryHandle('friend')" />
            </div>
            <div class="line"></div>
            <div class="middle-container">
              <div class="middle-item" @click="handleCurrnet(item)" :class="{ active: item.id === currentInfo.id }"
                v-for="(item, index) in friends" :key="index">
                <div class="sub-container flex-row" :style="{
                  'border-bottom':
                    item.id !== currentInfo.id
                      ? '1px dashed rgba(153,153,153,1)'
                      : '',
                }">
                  <img src="../assets/images/home/avatar.png" alt="" />
                  <span>
                    {{ item.nickname }}
                  </span>
                </div>
              </div>
              <div class="total">共有{{ friends.length }}条记录</div>
            </div>
          </div>
          <!-- 好友形象展示 -->
          <div class="right" v-if="active === 'friend'">
            <div class="vr">
              <img src="../assets/images/home/friend-vr.png" alt="" />
            </div>
            <div class="handle-name flex-row">
              <div class="name" v-if="currentInfo">
                {{ currentInfo.nickname ? currentInfo.nickname : '暂无' }}
              </div>
              <div class="handle" v-if="currentInfo" @click="delFriend">删除好友</div>
            </div>
          </div>
          <!-- 名片管理 -->
          <div class="card" v-if="active === 'card'">
            <div class="search flex-row">
              <div class="search-input flex-row">
                <input v-model="nickName" type="text" placeholder="搜索好友名片" />
                <img src="../assets/images/home/search.png" alt="" @click='queryHandle("card")' />
              </div>
              <div class="mail-list flex-row">
                <img @click="isADD = true" src="../assets/images/home/increment-card.png" alt="" />
                <span @click="isADD = true">新增我的名片</span>
                <!-- <span style="margin-left: 20px;" @click="sendcard">测试交换名片按钮</span> -->
              </div>
            </div>
            <div class="line"></div>
            <div class="card-manage-list flex-row">
              <!-- start -->
              <div class="card-handle flex-col" v-for="(item, index) in cards" :key="index">
                <div @click="currentIndex = index" class="card-box" :class="{ active: currentIndex === index }">
                  <div class="nickname">
                    <span>{{ item.name }}</span>
                    <span class="subtitle">{{ item.position }}</span>
                    <span class="my-self" v-if="item.type"> 我自己 </span>
                  </div>
                  <div class="company">{{ item.companyName }}</div>
                  <div class="mobile-address flex-row">
                    <img src="../assets/images/home/mobile.png" alt="" />
                    <span>{{ item.mobile }}</span>
                  </div>
                  <div class="mobile-address flex-row">
                    <img src="../assets/images/home/address.png" alt="" />
                    <span>
                      {{ item.address }}
                    </span>
                  </div>
                </div>
                <div class="handle-group flex-row">
                  <span v-if="!item.exchangeTime">于{{ item.createTime }}创建</span>
                  <span v-else>于{{ item.exchangeTime }}交换</span>
                  <div @click="cardUpdate(item)" class="modify" v-if="item.type && currentIndex === index">
                    修改
                  </div>
                  <div class="del" @click="cardRemove(item.id)" v-if="currentIndex === index">
                    删除
                  </div>
                </div>
              </div>
              <!-- end -->
              <!-- <div class="card-total" style="width: 100%;">共有{{ cards.length }}记录</div> -->
            </div>
          </div>
        </div>
        <!-- 名片添加表单 -->
        <div class="increment-card flex-row" v-if="isADD">
          <div class="left-form flex-col">
            <div class="form-item flex-row">
              <span>姓名</span>
              <input type="text" v-model="form.name" />
            </div>
            <div class="form-item flex-row">
              <span>单位名称</span>
              <input type="text" v-model="form.companyName" />
            </div>
            <div class="form-item flex-row">
              <span>职务</span>
              <input type="text" v-model="form.position" />
            </div>
            <div class="form-item flex-row">
              <span>联系电话</span>
              <input type="text" v-model="form.mobile" />
            </div>
            <div class="form-item flex-row">
              <span>联系地址</span>
              <input type="text" v-model="form.address" />
            </div>
            <div class="form-item flex-row">
              <span></span>
              <div class="save-cancle flex-row">
                <div class="handle" @click="preservation">保存</div>
                <div class="cancel" @click="cancelHandle">取消</div>
              </div>
            </div>
          </div>
          <div class="preview">
            <span class="title">预览我的名片</span>
            <div class="card-box">
              <div class="nickname">
                <span>{{ form.name }}</span>
                <span class="subtitle">{{ form.position }}</span>
              </div>
              <div class="company">{{ form.companyName }}</div>
              <div class="mobile-address flex-row">
                <img src="../assets/images/home/mobile.png" alt="" />
                <span>{{ form.mobile }}</span>
              </div>
              <div class="mobile-address flex-row">
                <img src="../assets/images/home/address.png" alt="" />
                <span>
                  {{ form.address }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


